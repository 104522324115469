import React, { useState, useContext, memo } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import StyledRadioButton from "../../../share/StyledRadioButton";
import StyledSelect from "../../../share/StyledSelect";
import { EventContext } from "../../../../routes/Management/Event/EventContainer";
import { dateFormat } from "../../../../utils/commons";
import { useMutation } from "@apollo/client";
import { EDIT_EVENT_STATUS } from "../../../../graphql/Management/Event/mutation";
import { toast } from "react-toastify";
import DetailModal from "./DetailModal";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
  &:hover {
    background-color: ${colors.lightGrayBorderColorr};
  }
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  color: ${({ gray }) =>
    gray ? `${colors.grayFontColor}` : `${colors.blackColor}`};
  > a {
    color: ${colors.blueColor};
  }
`;

const StatusClick = styled.div``;

const Image = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 4px;
`;

const ClickArea = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RowTable = () => {
  const { eventList, refetch, clicked, handleRadio } = useContext(EventContext);
  const [editEventStatus] = useMutation(EDIT_EVENT_STATUS);
  const [index, setIndex] = useState(null);

  const [detailModal, setDetailModal] = useState(false);
  const [detailInfo, setDetailInfo] = useState();

  const handleStatus = async (name, option) => {
    try {
      const { data } = await editEventStatus({
        variables: {
          evnt_id: eventList[index].evnt_id,
          evnt_status: option === "진행중" ? true : false,
        },
      });
      if (data.editEventStatus.result) {
        toast.success("상태를 변경하였습니다.");
        await refetch();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      <Container>
        {eventList.length !== 0 &&
          eventList.map((data, index) => {
            return (
              <RowBox key={index}>
                <StyledRadioButton
                  clicked={clicked === index}
                  onClick={() => handleRadio(index)}
                />
                <ClickArea
                  onClick={() => {
                    setDetailModal(true);
                    setDetailInfo(data);
                  }}
                >
                  <Text width={130}>
                    {dateFormat(
                      new Date(Number(data.evnt_createdAt)),
                      "yy.MM.dd"
                    )}
                  </Text>
                  <Text width={204}>
                    {dateFormat(new Date(Number(data.evnt_start)), "yy.MM.dd")}{" "}
                    ~{dateFormat(new Date(Number(data.evnt_end)), "yy.MM.dd")}
                  </Text>
                </ClickArea>
                <Text width={152}>
                  <StatusClick onClick={() => setIndex(index)}>
                    <StyledSelect
                      ARROW
                      NOBORDER
                      width={90}
                      padding="0"
                      value={data.evnt_status ? "진행중" : "종료"}
                      onChange={handleStatus}
                      options={["진행중", "종료"]}
                    />
                  </StatusClick>
                </Text>
                <ClickArea
                  onClick={() => {
                    setDetailModal(true);
                    setDetailInfo(data);
                  }}
                >
                  <Text width={128}>
                    <Image src={data.evnt_img} />
                  </Text>
                  <Text width={950}>{data.evnt_title}</Text>
                </ClickArea>
              </RowBox>
            );
          })}
        {detailModal && (
          <DetailModal
            data={detailInfo}
            handleDetailModal={() => setDetailModal(!detailModal)}
          />
        )}
      </Container>
    </>
  );
};

export default memo(RowTable);
