import React, { useContext } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import { autoHyphen, dateFormat } from "../../../../utils/commons";
import StyledRadioButton from "../../../share/StyledRadioButton";
import { ProductListContext } from "../../../../routes/Product/ProductList/ProductListContainer";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
  color: ${({ gray }) =>
    gray ? `${colors.grayFontColor}` : `${colors.blackColor}`};
  > a {
    color: ${colors.blueColor};
  }
`;

const Image = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 4px;
`;

const RowTable = () => {
  const { productList, clicked, handleRadio } = useContext(ProductListContext);

  return (
    <Container>
      {productList.length !== 0 &&
        productList.map((data, index) => (
          <RowBox key={index}>
            <StyledRadioButton
              clicked={clicked === index}
              onClick={() => handleRadio(index)}
            />
            <Text width={130}>
              {dateFormat(new Date(Number(data.prd_createdAt)), "yy.MM.dd")}
            </Text>
            <Text width={88}>
              <Image
                src={
                  data.product_image.length !== 0
                    ? data.product_image[0].prd_img_url
                    : ""
                }
              />
            </Text>
            <Text width={202}>{data.prd_name}</Text>
            <Text width={146}>{data.prd_last_click.toLocaleString()} 건</Text>
            <Text width={142}>{data.prd_total_click.toLocaleString()} 건</Text>
            <Text width={580}>
              <a href={data.prd_link} target="_blank">
                {data.prd_link}
              </a>
            </Text>
            <Text gray width={148}>
              {data.prd_price.toLocaleString()} 원
            </Text>
            <Text gray width={118}>
              {data.prd_review_count.toLocaleString()} 건
            </Text>
          </RowBox>
        ))}
    </Container>
  );
};

export default RowTable;
