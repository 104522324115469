import React, { memo } from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { colors } from "../../styles/colors";

const Wrapper = styled.div`
  width: 292px;
  height: 100%;
  padding: 18px;
`;
const Header = styled.div`
  color: ${colors.darkGrayFontColor};
  padding-bottom: 9px;
`;
const ChartBox = styled.div`
  border-top: 2px solid ${colors.lightGrayBorderColor};
`;
const StatsBox = styled.div`
  display: flex;
  justify-content: center;
`;
const Stats = styled.div`
  text-align: center;
  margin: 0 15px;
`;
const Text = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 10)}px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  color: ${({ fontColor }) =>
    fontColor ? fontColor : colors.circleChartFontColor};
  & + & {
    margin-top: 6px;
  }
`;

const CircleChart = ({
  title = "",
  data,
  margin = "0 30px",
  padding = "18px",
  border,
}) => {
  const COLORS = [
    colors.circleChartGrayColor,
    colors.circleChartRedColor,
    colors.circleChartYellowColor,
  ];

  return (
    <Wrapper margin={margin} padding={padding}>
      <Header>{title}</Header>
      <ChartBox border={border}>
        <PieChart width={256} height={256}>
          <Pie
            data={data}
            cx={122}
            cy={122}
            innerRadius={83}
            outerRadius={100}
            paddingAngle={5}
            dataKey="value"
            startAngle={90}
            endAngle={-360}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
          </Pie>
        </PieChart>
      </ChartBox>
      <StatsBox>
        {data.map((stats, index) => (
          <Stats key={`${stats.name}-${stats.value}`}>
            <Text>{stats.name}</Text>
            <Text fontSize={16} fontWeight={600} fontColor={COLORS[index]}>
              <CountUp end={stats.value} duration={1}>
                {stats.value}
              </CountUp>
              %
            </Text>
          </Stats>
        ))}
      </StatsBox>
    </Wrapper>
  );
};

export default memo(CircleChart);
