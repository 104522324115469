import React, { memo } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import { colors } from "../../../styles/colors";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/System/Qr/RowTable";
import AddModal from "../../../components/feature/System/Qr/AddModal";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const QrPresenter = ({
  qrList,
  inputs,
  setInputs,
  handleReset,
  onClickPage,
  page,
  totalLength,
  qrAddModal,
  handleAddModal,
  refetch,
}) => {
  return (
    <>
      <GuideLine title={["시스템관리", "QR생성 및 관리"]} />
      <Container>
        <SortingForm
          RESET
          QR
          PLUS
          inputs={inputs}
          setInputs={setInputs}
          handleReset={handleReset}
          handlePlus={handleAddModal}
        />
        <StyledRowTable
          label={[
            { text: "생성일", width: 161 },
            { text: "분류", width: 128 },
            { text: "서비스유형", width: 190 },
            { text: "수량", width: 148 },
            { text: "사용 수량", width: 179 },
            { text: "미사용 수량", width: 193 },
            { text: "사용률", width: 116 },
          ]}
        />
        <RowTable qrList={qrList} />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </Container>
      {qrAddModal && (
        <AddModal refetch={refetch} handleAddModal={handleAddModal} />
      )}
    </>
  );
};

export default memo(QrPresenter);
