import React, { useEffect, useState, useCallback, useContext } from "react";
import UnRegisterPresenter from "./UnRegisterPresenter";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_WITHDRAWAL_USER } from "../../../graphql/User/UnRegister/mutation";
import { SEE_WITHDRAWAL_LIST } from "../../../graphql/User/UnRegister/query";
import { sortChanger } from "../../../lib/userUtils";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

const UnRegisterContainer = () => {
  const { userLogout } = useContext(AuthContext);

  const [page, setPage] = useState(1);
  const [cursor, setCursor] = useState(0);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "내림차순",
  });

  const [withdrawalList, setWithdrawalList] = useState([]);
  const [totalLength, setTotalLength] = useState(0);

  const [deleteWithdrawalUser] = useMutation(DELETE_WITHDRAWAL_USER);

  const {
    data: withdrawalData,
    loading,
    refetch,
  } = useQuery(SEE_WITHDRAWAL_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      paymentDateSort: sortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  useEffect(() => {
    console.log(withdrawalData);
    if (withdrawalData) {
      if (!withdrawalData.seeWithdrawalList.tokenExpired) {
        userLogout();
      }
      setWithdrawalList(withdrawalData.seeWithdrawalList.withdrawalList);
      setTotalLength(withdrawalData.seeWithdrawalList.totalLength);
    }
  }, [withdrawalData]);

  const handleReset = () => {
    setInputs({
      searchTerm: "",
      sort: "내림차순",
    });
  };

  const handleDeleteUser = async (id) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteWithdrawalUser({
          variables: {
            user_id: id,
          },
        });
        console.log(data);
        if (data.deleteWithdrawalUser.result) {
          toast.success("탈퇴한 회원을 삭제하였습니다.");
          await refetch();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <UnRegisterPresenter
      inputs={inputs}
      setInputs={setInputs}
      handleReset={handleReset}
      handleDeleteUser={handleDeleteUser}
      withdrawalList={withdrawalList}
      totalLength={totalLength}
      onClickPage={onClickPage}
      page={page}
    />
  );
};

export default UnRegisterContainer;
