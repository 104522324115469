import { gql } from "@apollo/client";

export const CREATE_TOS = gql`
  mutation createTOS($tos_title: String, $tos_text: String) {
    createTOS(tos_title: $tos_title, tos_text: $tos_text) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_TOS = gql`
  mutation deleteTOS($tos_id: Int) {
    deleteTOS(tos_id: $tos_id) {
      tokenExpired
      result
    }
  }
`;
