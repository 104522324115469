import { gql } from "@apollo/client";

export const CREATE_ADMIN_TO_USER_MEMO = gql`
  mutation craeteAdminToUserMemo($user_id: Int!, $memo: String) {
    craeteAdminToUserMemo(user_id: $user_id, memo: $memo) {
      tokenExpired
      result
    }
  }
`;

export const CREATE_USER_ADVICE_DAILY_LOG = gql`
  mutation createUserAdviceDailyLog(
    $user_id: Int!
    $title: String
    $text: String
  ) {
    createUserAdviceDailyLog(user_id: $user_id, title: $title, text: $text) {
      tokenExpired
      result
    }
  }
`;
