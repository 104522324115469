import { gql } from "@apollo/client";

export const SEE_TOS_LIST = gql`
  query seeTOSList(
    $searchTerm: String
    $tosSort: tosSort
    $take: Int
    $cursor: Int
  ) {
    seeTOSList(
      searchTerm: $searchTerm
      tosSort: $tosSort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      tosList {
        tos_id
        tos_createdAt
        tos_title
        tos_text
        tos_admin_name
      }
    }
  }
`;
