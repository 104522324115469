import { useState, useEffect } from "react";
import AppRouter from "./Router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { colors } from "./styles/colors";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const previousLoading = () => {
    try {
      const token = localStorage.getItem("qr_admin_token");
      if (token) {
        setIsLoggedIn(true);
        navigate(pathname);
      } else {
        setIsLoggedIn(false);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => previousLoading(), []);

  console.log = function no_console() {};

  return (
    <ThemeProvider theme={colors}>
      <GlobalStyles />
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      <AppRouter isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
    </ThemeProvider>
  );
}

export default App;
