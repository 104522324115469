import React, { memo, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../../styles/colors";
import StyledModal from "../../../../share/StyledModal";
import StyledTextarea from "../../../../share/StyledTextarea";
import { useMutation } from "@apollo/client";
import { SEND_MESSAGE } from "../../../../../graphql/User/State/UserList/mutation";
import { toast } from "react-toastify";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RadioButton = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: ${colors.radioBackground};
  border: 1px solid ${colors.radioBorder};
  cursor: pointer;
  ${({ select }) =>
    select &&
    css`
      background-color: ${colors.whiteColor};
      border: 5px solid ${colors.radioBlueBorder};
    `}
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  cursor: pointer;
`;

const Text = styled.div`
  margin: ${({ margin }) => margin};
  color: ${({ gray }) => gray && `${colors.labelGrayFontColor}`};
`;

const MessageModal = ({ id, setMessageModal }) => {
  const [contents, setContents] = useState("");
  const [sendMessage] = useMutation(SEND_MESSAGE);

  const handleSave = useCallback(async () => {
    if (contents.length === 0) {
      console.log("내용 없음");
      return;
    }
    try {
      const { data } = await sendMessage({
        variables: {
          userIds: [id],
          msg: contents,
        },
      });
      if (data.sendMessage.result) toast.success("문자 전송 완료");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setMessageModal(false);
    }
  }, [id, contents]);

  const onChangeMessage = (e) => {
    setContents(e.target.value);
  };

  return (
    <StyledModal
      BOTTOMBTN
      width={790}
      height={560}
      title="문자보내기"
      onClickClose={() => setMessageModal(false)}
      onClickSave={handleSave}
    >
      <Container>
        <StyledTextarea
          width={710}
          height={320}
          value={contents}
          label="내용"
          onChange={onChangeMessage}
          maxLength={250}
        />
      </Container>
    </StyledModal>
  );
};

export default memo(MessageModal);
