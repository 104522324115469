import { gql } from "@apollo/client";

export const SEE_USER_LIST = gql`
  query seeUserList(
    $searchTerm: String
    $user_subs_available: subs_available
    $signInDateSort: descAscsort
    $take: Int!
    $cursor: Int!
  ) {
    seeUserList(
      searchTerm: $searchTerm
      user_subs_available: $user_subs_available
      signInDateSort: $signInDateSort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      userList {
        user_id
        user_createdAt
        user_name
        user_birthday
        user_gender
        user_cellphone
        user_card_count
        user_share_count
        user_subs_available
      }
    }
  }
`;
