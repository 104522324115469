import React, { memo } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import PageNation from "../../../components/layout/PageNation";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import RowTable from "../../../components/feature/User/Mail/RowTable";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const MailPresenter = ({
  inputs,
  setInputs,
  handleReset,
  onChangeDate,
  messageList,
  onClickPage,
  page,
  totalLength,
}) => {
  return (
    <>
      <GuideLine title={["유지관리", "보낸문자함"]} />
      <Container>
        <SortingForm
          SEARCH
          RESET
          SORT
          SELECTDATE
          placeholder="관리자명으로 검색"
          label="일자 정렬"
          inputs={inputs}
          setInputs={setInputs}
          handleReset={handleReset}
          onChangeDate={onChangeDate}
        />
        <StyledRowTable
          label={[
            { text: "발송일", width: 130 },
            { text: "시간", width: 108 },
            { text: "발신자", width: 118 },
            { text: "수신자", width: 250 },
            { text: "내용", width: 908 },
            { text: "남은보관기간", width: 100 },
          ]}
        />
        <RowTable messageList={messageList} />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </Container>
    </>
  );
};

export default memo(MailPresenter);
