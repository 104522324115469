import { gql } from "@apollo/client";

export const SEE_FAQ_LIST = gql`
  query seeFAQList(
    $searchTerm: String
    $sort: faqSort
    $take: Int
    $cursor: Int
  ) {
    seeFAQList(
      searchTerm: $searchTerm
      sort: $sort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      faqList {
        faq_id
        faq_createdAt
        faq_admin_name
        faq_question
        faq_answer
      }
    }
  }
`;
