import React, { useState, useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledInput from "../../../share/StyledInput";
import StyledTextarea from "../../../share/StyledTextarea";
import { useMutation } from "@apollo/client";
import {
  CREATE_NOTICE,
  EDIT_NOTICE,
} from "../../../../graphql/Management/Notice/mutation";
import { NoticeContext } from "../../../../routes/Management/Notice/NoticeContainer";
import { toast } from "react-toastify";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  height: 25px;
`;

const AddModal = ({ EDIT }) => {
  const [inputs, setInputs] = useState({
    name: "",
    contents: "",
  });

  const onChangeInput = (name, value) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const { handleAddModal, handleEditModal, refetch, clicked, noticeList } =
    useContext(NoticeContext);

  const [createNotice] = useMutation(CREATE_NOTICE);
  const [editNotice] = useMutation(EDIT_NOTICE);

  const onClickSave = async () => {
    if (!EDIT) {
      // 공지 생성
      try {
        const { data } = await createNotice({
          variables: {
            ntc_title: inputs.name,
            ntc_text: inputs.contents,
          },
        });
        if (data.createNotice.result) {
          toast.success("공지를 등록하였습니다.");
          await refetch();
          handleAddModal();
        }
      } catch (e) {
        toast.error(e.message);
      }
    } else {
      //공지 수정
      try {
        const { data } = await editNotice({
          variables: {
            ntc_id: noticeList[clicked].ntc_id,
            ntc_title: inputs.name,
            ntc_text: inputs.contents,
          },
        });
        if (data.editNotice.result) {
          toast.success("공지를 수정하였습니다.");
          await refetch();
          handleEditModal();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    if (EDIT) {
      setInputs({
        name: noticeList[clicked].ntc_title,
        contents: noticeList[clicked].ntc_text,
      });
    }
  }, []);

  return (
    <StyledModal
      BOTTOMBTN
      width={619}
      height={752}
      title={EDIT ? "공지 수정" : "공지 등록"}
      onClickClose={EDIT ? handleEditModal : handleAddModal}
      onClickSave={onClickSave}
    >
      <Container>
        <StyledInput
          label="공지명*"
          width={535}
          placeholder="공지명을 입력해주세요"
          maxLength={30}
          value={inputs.name}
          onChange={(e) => onChangeInput("name", e.target.value)}
        />
        <Margin />
        <StyledTextarea
          label="공지 내용"
          width={535}
          height={396}
          placeholder="표기 내용을 입력해주세요"
          value={inputs.contents}
          onChange={(e) => onChangeInput("contents", e.target.value)}
        />
      </Container>
    </StyledModal>
  );
};

export default AddModal;
