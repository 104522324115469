import { gql } from "@apollo/client";

export const CREATE_USER_EXCEL = gql`
  mutation craeteUserExcel(
    $searchTerm: String
    $user_subs_available: subs_available
    $signInDateSort: descAscsort
  ) {
    craeteUserExcel(
      searchTerm: $searchTerm
      user_subs_available: $user_subs_available
      signInDateSort: $signInDateSort
    ) {
      tokenExpired
      excel
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMessage($userIds: [Int]!, $msg: String) {
    sendMessage(userIds: $userIds, msg: $msg) {
      result
      tokenExpired
    }
  }
`;
