import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";

const Button = styled.button`
  width: ${({ width }) => width}px;
  height: 46px;
  font-size: 16px;
  font-weight: 500;
  margin: ${({ margin }) => margin};
  margin-top: ${({ RESET }) => RESET && 28}px;
  border-radius: 8px;
  border: 1px solid ${colors.lightGrayBorderColorr};
  color: ${({ BLACK }) => (BLACK ? `${colors.whiteColor}` : `${colors.lightGrayBorderColor}`)};
  background-color: ${({ BLACK }) => (BLACK ? `${colors.blackColor}` : `${colors.whiteColor}`)};
  cursor: pointer;
  &:hover {
    color: ${({ BLACK }) => !BLACK && `${colors.lightGrayFontColor}`};
    background-color: ${({ BLACK }) => BLACK && `${colors.blackColor}`};
    border: 1px solid ${({ BLACK }) => !BLACK && `${colors.lightGrayFontColor}`};
  }
`;

const StyledButton = ({ BLACK, RESET, width = 74, margin, label, onClick = () => {} }) => {
  return (
    <Button BLACK={BLACK} RESET={RESET} width={width} margin={margin} onClick={onClick}>
      {label}
    </Button>
  );
};

export default StyledButton;
