import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../styles/colors";

const Container = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 20px;
  border-radius: 50%;
  border: 1px solid ${colors.labelGrayFontColor};
  background-color: ${colors.whiteColor};
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  ${({ clicked }) =>
    clicked &&
    css`
      border: 6px solid ${colors.blackColor};
    `}
`;

const StyledRadioButton = ({ clicked, onClick = () => {} }) => {
  return <Container clicked={clicked} onClick={onClick} />;
};

export default StyledRadioButton;
