import React from "react";
import styled from "styled-components";
import mailIcon from "../../assets/mailIcon.png";
import { colors } from "../../styles/colors";
import minusIcon from "../../assets/minusIcon.png";
import plusIcon from "../../assets/plusIcon.png";
import editIcon from "../../assets/editIcon.png";
import excelIcon from "../../assets/excelIcon.svg";

const IconButton = styled.img`
  width: ${({ width }) => (width ? width : 45)}px;
  height: ${({ height }) => (height ? height : 45)}px;
  cursor: pointer;
`;

const ExcelButton = styled.div`
  width: 118px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: lighter;
  border: 1px solid ${colors.black};
  border-radius: 8px;
  cursor: pointer;
`;

const StyledIconButton = ({ MAIL, PLUS, MINUS, EDIT, EXCELICON, EXCEL, onClick = () => {} }) => {
  return (
    <>
      {MAIL && <IconButton src={mailIcon} onClick={onClick} />}
      {PLUS && <IconButton src={plusIcon} onClick={onClick} />}
      {MINUS && <IconButton src={minusIcon} onClick={onClick} />}
      {EDIT && <IconButton src={editIcon} onClick={onClick} />}
      {EXCELICON && <IconButton src={excelIcon} onClick={onClick} />}
      {EXCEL && <ExcelButton onClick={onClick}>엑셀 다운로드</ExcelButton>}
    </>
  );
};

export default StyledIconButton;
