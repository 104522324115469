import { gql } from "@apollo/client";

export const CREATE_PAYMENT_STATISTICS_EXCEL = gql`
  mutation createPaymentStatisticsExcel($statisticsFilter: statistics) {
    createPaymentStatisticsExcel(statisticsFilter: $statisticsFilter) {
      excel
      tokenExpired
    }
  }
`;
