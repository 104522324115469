import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import { colors } from "../../../styles/colors";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/System/User/RowTable";
import AddModal from "../../../components/feature/System/User/AddModal";
import { SystemContext } from "./UserContainer";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const UserPresenter = () => {
  const {
    inputs,
    setInputs,
    handleReset,
    page,
    onClickPage,
    totalLength,
    userAddModal,
    handleAddModal,
    handleMinus,
    setClicked,
  } = useContext(SystemContext);

  return (
    <>
      <GuideLine title={["시스템관리", "사용자관리"]} />
      <Container>
        <SortingForm
          SEARCH
          RESET
          PLUS
          MINUS
          width={190}
          placeholder="사용자명으로 검색"
          inputs={inputs}
          setInputs={setInputs}
          handleMinus={handleMinus}
          handlePlus={handleAddModal}
          handleReset={handleReset}
        />
        <StyledRowTable
          RADIO
          handleRadio={() => setClicked(null)}
          label={[
            { text: "팀", width: 100 },
            { text: "사원명", width: 122 },
            { text: "직급", width: 148 },
            { text: "아이디", width: 168 },
            { text: "권한", width: 802 },
            { text: "권한수정", width: 116 },
          ]}
        />
        <RowTable />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </Container>
      {userAddModal && <AddModal />}
    </>
  );
};

export default UserPresenter;
