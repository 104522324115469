import React from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";

const Container = styled.div`
  width: 535px;
  height: 46px;
  flex-shrink: 0;
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid ${colors.lightGrayBorderColorr};
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: ${colors.grayFontColor};
  overflow: hidden;
`;

const Text = styled.div`
  color: ${colors.labelGrayFontColor};
  margin-bottom: 12px;
`;

const InputFiles = ({ filename = "", onChange }) => {
  return (
    <>
      <Text>이벤트 이미지 파일</Text>
      <Container>
        <Label htmlFor="input-file">
          {filename === "" ? "파일 없음" : filename}
        </Label>
        <Input
          id="input-file"
          type="file"
          accept=".pdf, .jpeg, .jpg, .png"
          onChange={onChange}
        />
      </Container>
    </>
  );
};

export default InputFiles;
