import React, { useState, useCallback, useEffect, useContext } from "react";
import styled from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledInput from "../../../share/StyledInput";
import StyledTextarea from "../../../share/StyledTextarea";
import { colors } from "../../../../styles/colors";
import { useMutation } from "@apollo/client";
import { CREATE_ADMIN } from "../../../../graphql/System/User/mutation";
import { toast } from "react-toastify";
import { SystemContext } from "../../../../routes/System/User/UserContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
`;
const Text = styled.div`
  margin-top: 10px;
  color: ${colors.redColor};
`;

const AddModal = () => {
  const { handleAddModal, refetch } = useContext(SystemContext);

  const [createAdmin] = useMutation(CREATE_ADMIN);

  const [inputs, setInputs] = useState({
    name: "",
    position: "",
    team: "",
    id: "",
    pw: "",
    pwcheck: "",
  });
  const [pwCheck, setPwCheck] = useState(true);
  const onChangeInput = useCallback(
    (name, value) => {
      setInputs({
        ...inputs,
        [name]: value,
      });
    },
    [inputs]
  );

  useEffect(() => {
    if (inputs.pw === inputs.pwcheck) setPwCheck(true);
    else setPwCheck(false);
  }, [inputs.pwcheck]);

  const onClickSave = async () => {
    if (inputs.pw !== inputs.pwcheck) {
      toast.error("비밀번호를 확인해주세요.");
      return;
    }
    if (
      inputs.name === "" ||
      inputs.position === "" ||
      inputs.team === "" ||
      inputs.id === "" ||
      inputs.pw === ""
    ) {
      toast.error("모두 입력해주세요.");
      return;
    }
    try {
      const { data } = await createAdmin({
        variables: {
          team: inputs.team,
          name: inputs.name,
          rank: inputs.position,
          accountId: inputs.id,
          password: inputs.pw,
        },
      });
      if (data.createAdmin.result) {
        toast.success("사용자를 추가하였습니다.");
        handleAddModal();
        await refetch();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <StyledModal
      BOTTOMBTN
      width={489}
      height={708}
      title="사용자추가"
      onClickClose={handleAddModal}
      onClickSave={onClickSave}
    >
      <Container>
        <Margin>
          <StyledInput
            label="이름"
            width={196}
            value={inputs.name}
            onChange={(e) => onChangeInput("name", e.target.value)}
          />
          <StyledInput
            label="직급"
            width={196}
            value={inputs.position}
            onChange={(e) => onChangeInput("position", e.target.value)}
          />
        </Margin>
        <Margin>
          <StyledInput
            label="팀"
            width={196}
            value={inputs.team}
            onChange={(e) => onChangeInput("team", e.target.value)}
          />
        </Margin>
        <Margin>
          <StyledInput
            label="아이디"
            width={409}
            value={inputs.id}
            onChange={(e) => onChangeInput("id", e.target.value)}
          />
        </Margin>
        <Margin>
          <StyledInput
            label="비밀번호"
            width={409}
            value={inputs.pw}
            onChange={(e) => onChangeInput("pw", e.target.value)}
          />
        </Margin>

        <StyledInput
          label="비밀번호확인"
          width={409}
          value={inputs.pwcheck}
          onChange={(e) => onChangeInput("pwcheck", e.target.value)}
        />
        {!pwCheck && <Text>비밀번호가 다릅니다</Text>}
      </Container>
    </StyledModal>
  );
};

export default AddModal;
