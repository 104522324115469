import React, { memo } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  color: ${colors.blackColor};
`;

const RowTable = ({ messageList }) => {
  return (
    <Container>
      {messageList.map((data, index) => (
        <RowBox key={index}>
          {console.log(data)}
          <Text width={130}>{data.a2umh_sendDate}</Text>
          <Text width={108}>{data.a2umh_sendTime}</Text>
          <Text width={118}>{data.a2umh_sender}</Text>
          <Text width={250}>{data.a2umh_receiver}</Text>
          <Text width={908}>{data.a2umh_text}</Text>
          <Text width={100}>D-{data.a2umh_storagePeriod}일</Text>
        </RowBox>
      ))}
    </Container>
  );
};

export default memo(RowTable);
