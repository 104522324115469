import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import { colors } from "../../../styles/colors";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/Management/Faq/RowTable";
import AddModal from "../../../components/feature/Management/Faq/AddModal";
import { FaqContext } from "./FaqContainer";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const FaqPresenter = () => {
  const {
    inputs,
    setInputs,
    handleReset,
    addFaqModal,
    editFaqModal,
    handleAddModal,
    handleEditModal,
    handleMinus,
    setClicked,
    onClickPage,
    page,
    totalLength,
  } = useContext(FaqContext);
  return (
    <>
      <GuideLine title={["운영", "자주묻는질문"]} />
      <Container>
        <SortingForm
          SEARCH
          RESET
          FAQ
          PLUS
          MINUS
          EDIT
          placeholder="질문명, 관리자명으로 검색"
          inputs={inputs}
          setInputs={setInputs}
          handlePlus={handleAddModal}
          handleEdit={handleEditModal}
          handleReset={handleReset}
          handleMinus={handleMinus}
        />
        <StyledRowTable
          RADIO
          handleRadio={() => setClicked(null)}
          label={[
            { text: "작성일", width: 112 },
            { text: "작성자", width: 120 },
            { text: "질문명", width: 230 },
            { text: "답변 내용", width: 1100 },
          ]}
        />
        <RowTable />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </Container>
      {editFaqModal && <AddModal EDIT />}
      {addFaqModal && <AddModal />}
    </>
  );
};

export default FaqPresenter;
