import React, { useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../styles/colors";
import calendarIcon from "../../assets/calendarIcon.png";
import Calendar from "./Calendar";
import { dateFormat } from "../../utils/commons";

const Container = styled.div`
  width: 360px;
  margin: ${({ margin }) => margin};
`;

const RowBox = styled.div`
  display: flex;
  align-items: center;
`;

const RelativeBox = styled.div`
  position: relative;
  width: 100%;
`;
const InputBox = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${colors.lightGrayBorderColorr};
  font-size: 16px;
  color: ${({ spaceholder }) => (spaceholder ? `${colors.lightGrayBorderColor}` : `${colors.blackColor}`)};
  position: relative;
  cursor: pointer;
`;

const Label = styled.div`
  margin-bottom: 12px;
  color: ${colors.labelGrayFontColor};
  white-space: nowrap;
  ${({ text }) =>
    text &&
    css`
      margin: 0 12px;
      color: ${colors.lightGrayBorderColor};
    `}
`;

const Icon = styled.img`
  width: 18px;
`;

const StyledSelectDate = ({ label = "기간", startDate, endDate, onChangeDate, margin = "" }) => {
  const [modal, setModal] = useState({
    start: false,
    end: false,
  });
  const today = new Date();
  const [selectDate, setSelectDate] = useState(new Date(today.getFullYear(), today.getMonth(), today.getDate()));

  return (
    <Container margin={margin}>
      <Label>{label}</Label>
      <RowBox>
        <RelativeBox>
          <InputBox spaceholder={startDate === ""} onClick={() => setModal({ start: !modal.start, end: false })}>
            {startDate === "" ? "시작일" : dateFormat(startDate, "yy.MM.dd")}
            <Icon src={calendarIcon} />
          </InputBox>
          {modal.start && <Calendar name="startDate" today={today} selectDate={selectDate} onChange={onChangeDate} setModal={setModal} />}
        </RelativeBox>
        <Label text>부터</Label>
        <RelativeBox>
          <InputBox spaceholder={endDate === ""} onClick={() => setModal({ start: false, end: !modal.end })}>
            {endDate === "" ? "종료일" : dateFormat(endDate, "yy.MM.dd")}
            <Icon src={calendarIcon} />
          </InputBox>
          {modal.end && <Calendar name="endDate" today={today} selectDate={selectDate} onChange={onChangeDate} setModal={setModal} />}
        </RelativeBox>
        <Label text>까지</Label>
      </RowBox>
    </Container>
  );
};

export default StyledSelectDate;
