import { gql } from "@apollo/client";

export const SEE_PRODUCT_LIST = gql`
  query SeeProductList(
    $take: Int!
    $cursor: Int!
    $searchTerm: String
    $sort: descAscsort
  ) {
    seeProductList(
      take: $take
      cursor: $cursor
      searchTerm: $searchTerm
      sort: $sort
    ) {
      tokenExpired
      totalLength
      productList {
        prd_id
        prd_createdAt
        prd_name
        prd_link
        prd_last_click
        prd_total_click
        prd_price
        prd_review_count
        product_image {
          prd_img_url
        }
      }
    }
  }
`;
