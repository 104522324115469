import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import { colors } from "../../../styles/colors";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/Management/Event/RowTable";
import AddModal from "../../../components/feature/Management/Event/AddModal";
import { EventContext } from "./EventContainer";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const EventPresenter = () => {
  const {
    inputs,
    setInputs,
    clicked,
    handleReset,
    handleRadio,
    addModal,
    handleAddModal,
    handleDetailModal,
    totalLength,
    handleMinus,
    editModal,
    setClicked,
    handleEditModal,
    onClickPage,
    page,
  } = useContext(EventContext);

  return (
    <>
      <GuideLine title={["운영", "이벤트"]} />
      <Container>
        <SortingForm
          SEARCH
          RESET
          EVENT
          PLUS
          MINUS
          EDIT
          placeholder="제목, 링크로 검색"
          inputs={inputs}
          setInputs={setInputs}
          handlePlus={handleAddModal}
          handleEdit={handleEditModal}
          handleReset={handleReset}
          handleMinus={handleMinus}
        />
        <StyledRowTable
          RADIO
          handleRadio={() => setClicked(null)}
          label={[
            { text: "등록일", width: 130 },
            { text: "진행기간", width: 204 },
            { text: "상태", width: 152 },
            { text: "썸네일", width: 128 },
            { text: "이벤트명", width: 800 },
          ]}
        />
        <RowTable
          clicked={clicked}
          handleRadio={handleRadio}
          handleDetailModal={handleDetailModal}
        />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </Container>
      {editModal && <AddModal EDIT />}
      {addModal && <AddModal />}
    </>
  );
};

export default EventPresenter;
