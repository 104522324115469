import React, { memo } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import { colors } from "../../../styles/colors";
import SortingForm from "../../../components/layout/SortingForm";
import Graph from "../../../components/feature/Sales/SalesStats/Graph";
import StyledIconButton from "../../../components/share/StyledIconButton";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  font-weight: 500;
  margin: 36px 0 30px 0;
`;

const RowBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const SalesStatsPresenter = ({
  inputs,
  setInputs,
  handleReset,
  handleExcelButton,
  monthlyList,
  average,
}) => {
  return (
    <>
      <GuideLine title={["매출", "통계"]} />
      <Container>
        <SortingForm
          STATS
          RESET
          inputs={inputs}
          setInputs={setInputs}
          handleReset={handleReset}
        />
        <RowBox>
          <Text>매출 통계</Text>
          <StyledIconButton EXCELICON onClick={handleExcelButton} />
        </RowBox>
        <Graph monthlyList={monthlyList} average={average} />
      </Container>
    </>
  );
};

export default memo(SalesStatsPresenter);
