import React, { memo } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import TextField from "../../components/feature/Login/TextField";
import LoginLogo from "../../assets/LoginLogo.svg";
import loading from "../../assets/loading.svg";

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.whiteColor};
`;

const Header = styled.div`
  position: fixed;
  top: 32px;
  left: 32px;
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-top: 3px;
`;

const Logo = styled.img`
  width: 95px;
  margin-right: 15px;
`;

const LoginBox = styled.div`
  width: 372px;
  height: 554px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 40px;
  border: 1px solid ${colors.blackColor};
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
`;

const LoginText = styled.div`
  width: 100%;
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 36px;
`;

const LoginButton = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  cursor: pointer;
  color: ${colors.whiteColor};
  background-color: ${colors.blackColor};
`;

const LoadingImage = styled.img`
  height: 100%;
`;

const PwEditButton = styled.div`
  width: calc(100% - 80px);
  font-size: 14px;
  text-align: end;
  position: absolute;
  bottom: ${({ bottom }) => bottom}px;
  cursor: pointer;
  &:hover {
    color: ${colors.darkGrayFontColor};
  }
`;

const PwCheckText = styled.div`
  width: 100%;
  color: ${({ pwCheck }) =>
    pwCheck ? `${colors.whiteColor}` : `${colors.redColor}`};
  font-size: 14px;
  margin-bottom: -20px;
`;

const LoginPresenter = ({
  inputs,
  onChange,
  LoginHaldle,
  isLoading,
  editPw,
  setEditPw,
  pwCheck,
  handleEditPw,
}) => {
  return (
    <Container>
      <Header>
        <Logo src={LoginLogo} />
        <Text>관리자</Text>
      </Header>
      {!editPw ? ( // 로그인
        <LoginBox>
          <LoginText>로그인</LoginText>
          <TextField
            name="id"
            title="아이디"
            value={inputs.id}
            onChange={onChange}
          />
          <TextField
            name="pw"
            title="비밀번호"
            value={inputs.pw}
            onChange={onChange}
            onKeyPress={(e) => e.key === "Enter" && LoginHaldle()}
          />
          <LoginButton onClick={LoginHaldle}>
            {!isLoading ? "로그인" : <LoadingImage src={loading} />}
          </LoginButton>
          <PwEditButton bottom={100} onClick={() => setEditPw(true)}>
            비밀번호 변경
          </PwEditButton>
        </LoginBox>
      ) : (
        // 비밀번호 번경
        <LoginBox>
          <LoginText>비밀번호 변경</LoginText>
          <TextField
            name="id"
            title="아이디"
            value={inputs.id}
            onChange={onChange}
          />
          <TextField
            name="pw"
            title="비밀번호"
            value={inputs.pw}
            onChange={onChange}
          />
          <TextField
            name="pwCheck"
            title="비밀번호 확인"
            value={inputs.pwCheck}
            onChange={onChange}
          />
          <PwCheckText pwCheck={pwCheck}>비밀번호가 다릅니다.</PwCheckText>
          <LoginButton onClick={handleEditPw}>완료</LoginButton>
          <PwEditButton bottom={65} onClick={() => setEditPw(false)}>
            비밀번호 변경 취소
          </PwEditButton>
        </LoginBox>
      )}
    </Container>
  );
};

export default memo(LoginPresenter);
