import React, { memo, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../styles/colors";
import selectArrow from "../../assets/selectArrow.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: ${({ margin }) => (margin ? margin : "0 18px 0 0")};
`;
const Label = styled.div`
  font-size: 16px;
  color: ${colors.labelGrayFontColor};
  margin-bottom: 12px;
  white-space: nowrap;
  ${({ MONTH }) =>
    MONTH &&
    css`
      height: 100%;
      display: flex;
      align-items: center;
      margin-left: 12px;
    `}
`;
const Input = styled.input`
  width: ${({ width }) => (width ? width : 190)}px;
  height: 46px;
  font-size: 1rem;
  color: ${colors.blackColor};
  padding: 12px 10px;
  border: 1px solid ${colors.lightGrayBorderColorr};
  border-radius: 8px;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:focus {
    border: 1px solid ${colors.grayBorderColor};
  }

  &::placeholder {
    color: ${colors.lightGrayBorderColor};
    font-weight: 400;
    letter-spacing: 0.8px;
  }
`;
const ArrowBox = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  bottom: 11px;
`;

const InputBox = styled.div`
  position: relative;
  /* border: 1px solid blue; */
`;
const Image = styled.img`
  width: 10px;
  height: 12px;
  cursor: pointer;

  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate});
    `};
`;

const RowBox = styled.div`
  display: flex;
`;

const StyledNumberInput = ({ MONTH, width, margin, label, value = 0, name, min = 0, onChange, setState }) => {
  const onIncrease = useCallback(() => {
    setState((number) => number + 1);
  }, [value]);

  const onDecrease = useCallback(() => {
    if (value < 1) return;
    setState((number) => number - 1);
  }, [value]);

  return (
    <Wrapper margin={margin}>
      <Label>{label}</Label>
      <RowBox>
        <InputBox>
          <Input width={width} type="number" value={value} name={name} onChange={onChange} min={min} />
          <ArrowBox>
            <Image onClick={onIncrease} src={selectArrow} rotate="180deg" />
            <Image onClick={onDecrease} src={selectArrow} />
          </ArrowBox>
        </InputBox>
        {MONTH && <Label MONTH>개월</Label>}
      </RowBox>
    </Wrapper>
  );
};

export default memo(StyledNumberInput);
