import { gql } from "@apollo/client";

export const SEE_SIGNUP_STATISTICS = gql`
  query seeSingUpStatistics {
    seeSingUpStatistics {
      tokenExpired
      singInStatistics {
        month
        signInUserCount
      }
    }
  }
`;

export const SEE_USER_SUBS_TYPE = gql`
  query seeUserSubsType {
    seeUserSubsType {
      tokenExpired
      annual
      general
      monthly
    }
  }
`;

export const SEE_MONTHLY_PAYMENT_STATISTICS = gql`
  query seeMonthlyPaymentStatistics {
    seeMonthlyPaymentStatistics {
      tokenExpired
      monthlyStatistics {
        month
        payment
      }
    }
  }
`;

export const SEE_LINK_CLICK_STATISTICS = gql`
  query seeLinkClickStatistics {
    seeLinkClickStatistics {
      tokenExpired
      clickStatistics {
        month
        clickCount
      }
    }
  }
`;

export const SEE_NOTICE_HOME = gql`
  query seeNoticeHome {
    seeNoticeHome {
      tokenExpired
      homeNoticeInfo {
        ntc_text
        ntc_createdAt
      }
    }
  }
`;

export const SEE_QR_GEN_INFO = gql`
  query seeQRGenInfo {
    seeQRGenInfo {
      tokenExpired
      QRGenInfo {
        qr_type
        amount
        createdAt
      }
    }
  }
`;

export const PLATFORM_USED_INFO = gql`
  query platformUsedInfo {
    platformUsedInfo {
      tokenExpired
      totalUserCount
      premiumUser
      monthlySubs
      annualSubs
      monthlyIncome
      annualIncome
    }
  }
`;

export const SEE_TOTAL_CALL_TIME = gql`
  query seeTotalCallTime {
    seeTotalCallTime {
      totalCallTime
    }
  }
`;
