import React, {memo} from "react";
import styled, {css} from "styled-components";
import {colors} from "../../styles/colors";
import searchIcon from "../../assets/searchIcon.png";

const Container = styled.div`
  width: ${({width}) => width}px;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 46px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${colors.lightGrayBorderColorr};
  font-size: 16px;

  &::placeholder {
    color: ${colors.lightGrayBorderColor};
  }

  &:focus {
    border: 1px solid ${colors.grayBorderColor};
  }

  ${({readOnly}) => readOnly && css`
    &:focus {
      border: 1px solid ${colors.lightGrayBorderColorr};
    }
  `};
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  margin-bottom: 12px;
  color: ${colors.labelGrayFontColor};
`;

const Icon = styled.img`
  width: 22px;
  position: absolute;
  right: 15px;
  bottom: calc(23px - 11px);
  cursor: pointer;
`;

const StyledInput = ({
                         SEARCH, READONLY,
                         name = "",
                         label = "",
                         placeholder = "",
                         value = "",
                         onChange = () => {},
                         width = 280,
                         maxLength
                     }) => {
    return (
        <Container width={width}>
            {maxLength ? (
                <RowBox>
                    <Label>{label}</Label>
                    <Label>
                        ({value.length} / {maxLength})
                    </Label>
                </RowBox>
            ) : (
                <Label>{label}</Label>
            )}
            <Input name={name} value={value} onChange={onChange} placeholder={placeholder}
                   maxLength={maxLength && maxLength} readOnly={READONLY && true} />
            {SEARCH && <Icon src={searchIcon}/>}
        </Container>
    );
};

export default memo(StyledInput);
