import { gql } from "@apollo/client";

export const CREATE_FAQ = gql`
  mutation createFAQ($faq_question: String, $faq_answer: String) {
    createFAQ(faq_question: $faq_question, faq_answer: $faq_answer) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_FAQ = gql`
  mutation deleteFAQ($faq_id: Int!) {
    deleteFAQ(faq_id: $faq_id) {
      tokenExpired
      result
    }
  }
`;

export const EDIT_FAQ = gql`
  mutation editFAQ(
    $faq_id: Int!
    $faq_question: String!
    $faq_answer: String!
  ) {
    editFAQ(
      faq_id: $faq_id
      faq_question: $faq_question
      faq_answer: $faq_answer
    ) {
      tokenExpired
      result
    }
  }
`;
