import React from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  width: 100%;
  min-width: 1693px;
  height: 100%;
  min-height: 1041px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const fontAni = keyframes`
20% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(-6deg);
  }

  60% {
    transform: rotate(3deg);
  }

  80% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(0deg);
  }
 `;

const Text = styled.div`
  font-size: 22px;
  font-weight: 500;
  animation: 0.7s ${fontAni} ease;
`;
const EmptyPage = () => {
  return (
    <Container>
      <Text>현재 준비중인 페이지 입니다.</Text>
    </Container>
  );
};

export default EmptyPage;
