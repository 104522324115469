import React, {useContext} from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import {colors} from "../../../styles/colors";
import SortingForm from "../../../components/layout/SortingForm";
import PageNation from "../../../components/layout/PageNation";
import StyledRowTable from "../../../components/share/StyledRowTable";
import RowTable from "../../../components/feature/Management/Qna/RowTable";
import {QnaContext} from "./QnaContainer";
import AddModal from '../../../components/feature/Management/Qna/AddModal';

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const QnaPresenter = () => {
    const {inputs, setInputs, handleReset, onClickPage, page, totalLength} =
        useContext(QnaContext);

    return (
        <>
            <GuideLine title={["운영", "일대일문의"]}/>
            <Container>
                <SortingForm
                    SEARCH
                    RESET
                    QNA
                    placeholder="질문명, 관리자명으로 검색"
                    inputs={inputs}
                    setInputs={setInputs}
                    handleReset={handleReset}
                />
                <StyledRowTable
                    label={[
                        {text: "답변상태", width: 116},
                        {text: "작성일", width: 130},
                        {text: "유저명", width: 102},
                        {text: "연락처", width: 177},
                        {text: "문의 내용", width: 400},
                        {text: "답변 내용", width: 500},
                        {text: "답변자", width: 120},
                        {text: "답변일", width: 96},
                    ]}
                />
                <RowTable/>
                <PageNation
                    page={page}
                    onClickPage={onClickPage}
                    totalLength={totalLength}
                />
            </Container>
        </>
    );
};

export default QnaPresenter;
