import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../../styles/colors";
import { autoHyphen } from "../../../../../utils/commons";
import { DetailContext } from "../../../../../routes/User/State/Detail/DetailContainer";
import { gendarChanger, subsChanger } from "../../../../../lib/userUtils";
import { useMutation } from "@apollo/client";
import { CREATE_ADMIN_TO_USER_MEMO } from "../../../../../graphql/User/State/Detail/mutation";
import { toast } from "react-toastify";

const UserInfo = styled.div`
  width: 1149px;
  height: 475px;
  padding: 22px;
  margin-left: 16px;
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  color: ${({ gray }) => gray && `${colors.labelGrayFontColor}`};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const ProfileImage = styled.img`
  width: 136px;
  height: 136px;
  grid-row: 1/3;
  grid-column: 1/2;
  border-radius: 12px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 176px repeat(5, 168px);
  grid-template-rows: 68px 68px;
`;

const ColumnBox = styled.div`
  width: 168px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const MemoContainer = styled.div`
  width: 1149px;
  height: 115px;
  display: flex;
  flex-direction: column;
  position: relative;
  line-height: 18px;
  padding: 24px 22px;
  margin-left: 16px;
  background-color: ${colors.whiteColor};
`;

const SaveButton = styled.div`
  width: 60px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 22px;
  top: 34px;
  border-radius: 8px;
  border: 1px solid ${colors.lightGrayBorderColor};
  color: ${colors.lightGrayBorderColor};
  cursor: pointer;
  &:hover {
    border: 1px solid ${colors.grayBorderColor};
    color: ${colors.grayBorderColor};
  }
`;

const TextArea = styled.textarea`
  width: 1000px;
  height: 50px;
  border: 1px solid transparent;
  border-radius: 3px;
  margin-left: -2px;
  overflow-y: auto;
  &:focus {
    border: 1px solid ${colors.lightGrayBorderColor};
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: ${colors.blackColor};
    border-radius: 5px;
  }
`;

const UserInfoSection = () => {
  const { userDetail, userDetailRefetch } = useContext(DetailContext);

  const [craeteAdminToUserMemo] = useMutation(CREATE_ADMIN_TO_USER_MEMO);

  const [memo, setMemo] = useState(userDetail.user_admin_memo);

  const saveMemo = async () => {
    try {
      const { data } = await craeteAdminToUserMemo({
        variables: {
          user_id: userDetail.user_id,
          memo: memo,
        },
      });
      if (data.craeteAdminToUserMemo.result) {
        toast.success("메모가 저장되었습니다.");
        await userDetailRefetch();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    setMemo(userDetail.user_admin_memo);
  }, [userDetail.user_admin_memo]);

  return (
    <>
      <Text margin="38px 0 12px 16px">개인 정보</Text>
      <UserInfo>
        <InfoGrid>
          <ProfileImage src={userDetail.user_img} />
          <ColumnBox>
            <Text gray>가입일</Text>
            <Text>{userDetail.user_createdAt}</Text>
          </ColumnBox>
          <ColumnBox>
            <Text gray>이름</Text>
            <Text>{userDetail.user_name}</Text>
          </ColumnBox>
          <ColumnBox>
            <Text gray>생년월일</Text>
            <Text>{userDetail.user_birthday}</Text>
          </ColumnBox>
          <ColumnBox>
            <Text gray>성별</Text>
            <Text>{gendarChanger(userDetail.user_gender)}</Text>
          </ColumnBox>
          <ColumnBox>
            <Text gray>휴대폰번호</Text>
            <Text>{autoHyphen(userDetail.user_cellphone)} </Text>
          </ColumnBox>
          <ColumnBox>
            <Text gray>보유카드</Text>
            <Text>{userDetail.user_card_count} 개</Text>
          </ColumnBox>
          <ColumnBox>
            <Text gray>공유카드</Text>
            <Text>{userDetail.user_share_count} 개</Text>
          </ColumnBox>
          <ColumnBox>
            <Text gray>구독여/부</Text>
            <Text>{subsChanger(userDetail.user_subs_available)}</Text>
          </ColumnBox>
          <ColumnBox>
            <Text gray>총 구독 누적 금액</Text>
            <Text>{userDetail.user_total_subsPayment.toLocaleString()} 원</Text>
          </ColumnBox>
        </InfoGrid>
        <Text margin="50px 0  20px 0" fontWeight={400}>
          주소
        </Text>
        <Text fontWeight={300} gray>
          최근 배송된 주소
        </Text>
        <Text margin="7px 0  45px 0" fontWeight={400} gray>
          {/* //TODO ::: 이겨 데이터 빈 문자열이라 나중에 다시*/}
          {userDetail.user_address_detail === ""
            ? "-"
            : userDetail.user_address_detail}
        </Text>

        <Text fontWeight={300} gray>
          우편번호
        </Text>
        <Text margin="7px 0  0 0" fontWeight={400} gray>
          -
        </Text>
      </UserInfo>
      <Text margin="48px 0 12px 16px">관리자 메모</Text>
      <MemoContainer>
        <Text margin=" 0  0 7px 0" fontWeight={300} gray>
          자유 메모
        </Text>
        <TextArea value={memo} onChange={(e) => setMemo(e.target.value)} />
        <SaveButton onClick={saveMemo}>저장</SaveButton>
      </MemoContainer>
    </>
  );
};

export default UserInfoSection;
