import { gql } from "@apollo/client";

export const SEE_WITHDRAWAL_LIST = gql`
  query seeWithdrawalList(
    $searchTerm: String
    $paymentDateSort: descAscsort
    $take: Int!
    $cursor: Int!
  ) {
    seeWithdrawalList(
      searchTerm: $searchTerm
      paymentDateSort: $paymentDateSort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      withdrawalList {
        user_id
        user_withdrawal_Date
        user_name
        user_birthday
        user_gender
        user_cellphone
        user_info_endDate
        user_info_endDate_dateNumber
      }
    }
  }
`;
