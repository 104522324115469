import React from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";

const Container = styled.div`
  width: 122px;
  height: 122px;
  flex-shrink: 0;
  margin-right: 10px;
  :last-child {
    margin-right: 0;
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid ${colors.lightGrayBorderColor};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${colors.grayFontColor};
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const InputFiles = ({ thumbnailImg, thumbnail, onChange }) => {
  return (
    <Container>
      <Label htmlFor="input-file">
        {!thumbnail && "122 x 122 권장"}
        {thumbnail && <Image src={thumbnailImg} />}
      </Label>
      <Input
        id="input-file"
        multiple
        type="file"
        accept=".pdf, .jpeg, .jpg, .png"
        onChange={onChange}
      />
    </Container>
  );
};

export default InputFiles;
