import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../../../styles/colors";
import SortingForm from "../../../../layout/SortingForm";
import PageNation from "../../../../layout/PageNation";
import StyledRowTable from "../../../../share/StyledRowTable";
import RowTable from "./RowTable";
import { useQuery } from "@apollo/client";
import { SEE_USER_PAYMENT_LIST } from "../../../../../graphql/User/State/Detail/query";
import { sortChanger } from "../../../../../lib/userUtils";

const Container = styled.div`
  width: 1149px;
  height: 731px;
  padding: 32px 0 24px 0;
  margin-left: 16px;
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  font-weight: 400;
  margin: 38px 0 12px 16px;
`;
const MarginRow = styled.div`
  width: 100%;
  padding: 0 22px;
`;

const PayInfoSection = () => {
  const user_id = localStorage.getItem("user_id");
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [paymentList, setPaymentList] = useState([]);
  const [inputs, setInputs] = useState({
    sort: "내림차순",
  });

  const handleReset = () => {
    setInputs({
      sort: "내림차순",
    });
  };

  const { data, loading, refetch } = useQuery(SEE_USER_PAYMENT_LIST, {
    variables: {
      user_id: Number(user_id),
      paymentDateSort: sortChanger(inputs.sort),
      take: 9,
      cursor: cursor,
    },
  });

  useEffect(() => {
    console.log(data);
    if (data) {
      setPaymentList(data.seeUserPaymentList.paymentList);
      setTotalLength(data.seeUserPaymentList.totalLength);
    }
  }, [data]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 9);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <>
      <Text>활용 보고서</Text>
      <Container>
        <MarginRow>
          <SortingForm
            SORT
            RESET
            inputs={inputs}
            setInputs={setInputs}
            handleReset={handleReset}
          />
        </MarginRow>
        <StyledRowTable
          paddingLeft={22}
          width={1104}
          label={[
            { text: "날짜", width: 130 },
            { text: "항목", width: 210 },
            { text: "구분", width: 108 },
            { text: "금액", width: 148 },
            { text: "카드", width: 198 },
            { text: "결제 일시 데이터", width: 280 },
          ]}
        />
        <RowTable paymentList={paymentList} />
        <MarginRow>
          <PageNation
            take={9}
            page={page}
            totalLength={totalLength}
            onClickPage={onClickPage}
          />
        </MarginRow>
      </Container>
    </>
  );
};

export default PayInfoSection;
