import React from "react";
import styled from "styled-components";
import {colors} from "../../styles/colors";
import StyledButton from "./StyledButton";
import closeIcon from "../../assets/closeIcon.svg";

const DarkBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 10;
`;

const ModalContainer = styled.div`
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  display: flex;
  flex-direction: column;
  padding: 44px 40px 44px 40px;
  border-radius: 19px;
  background-color: ${colors.whiteColor};
  position: relative;
  z-index: 20;
`;

const Title = styled.div`
  font-weight: 400;
`;

const CloseIcon = styled.img`
  width: 18px;
  cursor: pointer;
`;

const RowBox = styled.div`
  display: flex;
  justify-content: ${({justifyContent}) => justifyContent};
  align-items: center;
  margin: ${({margin}) => margin};
`;

const StyledModal = ({
                         BOTTOMBTN,
                         width,
                         height,
                         title = "",
                         children,
                         onClickClose = () => {
                         },
                         onClickSave = () => {
                         },
                         label = "저장",
                     }) => {
    return (
        <>
            <DarkBackground>
                <ModalContainer width={width} height={height}>
                    <RowBox justifyContent="space-between" margin="0 0 25px 0">
                        <Title>{title}</Title>
                        <CloseIcon src={closeIcon} onClick={onClickClose}/>
                    </RowBox>
                    {children}
                    {BOTTOMBTN && (
                        <RowBox justifyContent="flex-end" margin="auto 0 0 0">
                            <StyledButton
                                label="취소"
                                margin="0 12px 0 0"
                                onClick={onClickClose}
                            />
                            <StyledButton BLACK label={label} onClick={onClickSave}/>
                        </RowBox>
                    )}
                </ModalContainer>
            </DarkBackground>
        </>
    );
};

export default StyledModal;
