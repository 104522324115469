import { gql } from "@apollo/client";

export const SEE_USER_DETAIL = gql`
  query seeUserDetail($user_id: Int!) {
    seeUserDetail(user_id: $user_id) {
      tokenExpired
      userDetail {
        user_id
        user_img
        user_createdAt
        user_name
        user_birthday
        user_gender
        user_cellphone
        user_card_count
        user_share_count
        user_subs_available
        user_total_subsPayment
        user_sido
        user_sigungu
        user_address_detail
        user_admin_memo
      }
    }
  }
`;

export const SEE_USER_ADVICE_DAILY_LOG = gql`
  query seeUserAdviceDailyLog($user_id: Int) {
    seeUserAdviceDailyLog(user_id: $user_id) {
      tokenExpired
      dailyLogList {
        adl_createdAt
        adl_title
        adl_text
        adl_admin_name
      }
    }
  }
`;

export const SEE_USER_PAYMENT_LIST = gql`
  query seeUserPaymentList(
    $user_id: Int
    $paymentDateSort: descAscsort
    $take: Int!
    $cursor: Int!
  ) {
    seeUserPaymentList(
      user_id: $user_id
      paymentDateSort: $paymentDateSort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      paymentList {
        upay_date
        upay_product_name
        upay_product_category
        upay_price
        upay_cardname
        upay_pay_success
        upay_period
      }
    }
  }
`;
