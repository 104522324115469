import React, {
    useEffect,
    useState,
    createContext,
    useCallback,
    useContext,
} from "react";
import TermPresenter from "./TermPresenter";
import {useQuery, useMutation} from "@apollo/client";
import {SEE_TOS_LIST} from "../../../graphql/Management/Term/query";
import {DELETE_TOS} from "../../../graphql/Management/Term/mutation";
import {termSortChanger} from "../../../lib/managementUtils";
import {toast} from "react-toastify";
import {AuthContext} from "../../../context/AuthProvider";

export const TermContext = createContext();

const TermContainer = () => {
    const {userLogout} = useContext(AuthContext);

    const [termAddModal, setTermAddModal] = useState(false);
    const [inputs, setInputs] = useState({
        searchTerm: "",
        sort: "등록일",
    });
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);
    const [totalLength, setTotalLength] = useState(0);
    const [termList, setTermList] = useState([]);
    const [clicked, setClicked] = useState(null);
    const [termDetail, setTermDetail] = useState(null);

    const [deleteTOS] = useMutation(DELETE_TOS);

    const {
        data: termData,
        refetch,
        loading,
    } = useQuery(SEE_TOS_LIST, {
        variables: {
            searchTerm: inputs.searchTerm,
            tosSort: termSortChanger(inputs.sort),
            take: 16,
            cursor: cursor,
        },
    });

    useEffect(() => {
        if (termData) {
            if (!termData.seeTOSList.tokenExpired) {
                userLogout();
            }
            setTermList(termData.seeTOSList.tosList);
            setTotalLength(termData.seeTOSList.totalLength);
        }
    }, [termData]);

    const handleReset = () => {
        setInputs({
            searchTerm: "",
            sort: "최신순",
        });
    };

    const handleRadio = (index) => {
        if (clicked === index) setClicked(null);
        else setClicked(index);
    };

    const handleAddModal = () => {
        setTermAddModal(!termAddModal);
    };

    const handleMinus = async () => {
        if (clicked === null) {
            toast.error("삭제할 약관을 선택해주세요.");
            return;
        }
        if (window.confirm("약관을 삭제하시겠습니까?")) {
            try {
                const {data} = await deleteTOS({
                    variables: {
                        tos_id: termList[clicked].tos_id,
                    },
                });
                console.log(data);
                if (data.deleteTOS.result) {
                    setClicked(null);
                    toast.success("약관을 삭제하였습니다.");
                    await refetch();
                }
            } catch (e) {
                toast.error(e.message);
            }
        }
    };

    const onClickDetail = useCallback(id => {
        const findItem = termList.find(list => list.tos_id === id);

        if (findItem) {
            setTermDetail(findItem);
            setTermAddModal(true);
        }
    }, [termList]);

    const onClickPage = useCallback((page) => {
        setPage(page);
        if (page !== 1) {
            setCursor((page - 1) * 16);
        }
        if (page === 1) {
            setCursor(0);
        }
    }, []);

    return (
        <TermContext.Provider
            value={{
                inputs,
                setInputs,
                clicked,
                handleReset,
                handleRadio,
                termAddModal,
                handleAddModal,
                totalLength,
                termList,
                refetch,
                handleMinus,
                setClicked,
                page,
                onClickPage,
                onClickDetail,
                termDetail,
              setTermDetail
            }}
        >
            <TermPresenter/>
        </TermContext.Provider>
    );
};

export default TermContainer;
