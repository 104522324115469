import { gql } from "@apollo/client";

export const CREATE_ADMIN = gql`
  mutation createAdmin(
    $team: String
    $name: String
    $rank: String
    $accountId: String
    $password: String
  ) {
    createAdmin(
      team: $team
      name: $name
      rank: $rank
      accountId: $accountId
      password: $password
    ) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation deleteAdmin($admin_id: Int) {
    deleteAdmin(admin_id: $admin_id) {
      tokenExpired
      result
    }
  }
`;

export const EDIT_PERMISSION = gql`
  mutation editPermission(
    $admin_id: Int!
    $per_home: Boolean
    $per_user: Boolean
    $per_product: Boolean
    $per_coupon: Boolean
    $per_salesInfo: Boolean
    $per_management: Boolean
    $per_system: Boolean
  ) {
    editPermission(
      admin_id: $admin_id
      per_home: $per_home
      per_user: $per_user
      per_product: $per_product
      per_coupon: $per_coupon
      per_salesInfo: $per_salesInfo
      per_management: $per_management
      per_system: $per_system
    ) {
      tokenExpired
      result
    }
  }
`;

export const INIT_ADMIN_PW = gql`
  mutation initAdminPW($admin_id: Int) {
    initAdminPW(admin_id: $admin_id) {
      result
      tokenExpired
    }
  }
`;
