import React, { useContext } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import { autoHyphen, dateFormat } from "../../../../utils/commons";
import StyledRadioButton from "../../../share/StyledRadioButton";
import { CouponContext } from "../../../../routes/Coupon/CouponList/CouponListContainer";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 20px;
`;

const RowTable = ({ ADMIN }) => {
  const { couponList, clicked, handleRadio } = useContext(CouponContext);

  return (
    <Container>
      {couponList.length !== 0 &&
        couponList.map((data, index) => (
          <RowBox key={index}>
            <StyledRadioButton
              clicked={clicked === index}
              onClick={() => handleRadio(index)}
            />
            <Text width={130}>
              {dateFormat(new Date(Number(data.cp_publicDate)), "yy.MM.dd")}
            </Text>
            <Text width={250}>{data.cp_name}</Text>
            <Text width={266}>{data.cp_serial}</Text>
            <Text width={140}>{data.cp_period} 개월 </Text>
            <Text width={ADMIN ? 140 : 239}>
              {data.cp_used ? "사용" : "미사용"}
            </Text>
            {ADMIN ? (
              <>
                <Text width={334}>
                  {data.cp_user_name === ""
                    ? "-"
                    : `${data.cp_user_name} (${data.cp_user_cellphone})`}
                </Text>
                <Text width={203}>
                  {data.cp_createType === "admin"
                    ? "관리자"
                    : "홈페이지 쿠폰 구매"}
                </Text>
                <Text width={168}>{data.cp_admin_name}</Text>
              </>
            ) : (
              <>
                <Text width={218}>
                  {data.cp_user_cellphone === ""
                    ? "-"
                    : autoHyphen(data.cp_user_cellphone)}
                </Text>
              </>
            )}
          </RowBox>
        ))}
    </Container>
  );
};

export default RowTable;
