import React, { memo, useContext } from "react";
import styled from "styled-components";
import MessageModal from "../../../../components/feature/User/State/UserList/MessageModal";
import RowTable from "../../../../components/feature/User/State/UserList/RowTable";
import PageNation from "../../../../components/layout/PageNation";
import GuideLine from "../../../../components/layout/SideBar/GuideLine";
import SortingForm from "../../../../components/layout/SortingForm";
import StyledRowTable from "../../../../components/share/StyledRowTable";
import { colors } from "../../../../styles/colors";
import { UserListContext } from "./UserListContainer";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  overflow-x: hidden;
  background-color: ${colors.whiteColor};
`;

const UserListPresenter = () => {
  const {
    inputs,
    setInputs,
    handleReset,
    handleMessageModal,
    handleExcel,
    totalLength,
    handleCheckbox,
    userList,
    page,
    onClickPage,
    sendMessageModal,
    clicked,
  } = useContext(UserListContext);

  return (
    <>
      <GuideLine title={["유지관리", "회원별 현황"]} />
      <Container>
        <SortingForm
          SEARCH
          SUBSCRIPTION
          SORT
          RESET
          MAIL
          EXCEL
          label="가입일자"
          inputs={inputs}
          setInputs={setInputs}
          handleReset={handleReset}
          handleSendMail={handleMessageModal}
          handleExcel={handleExcel}
        />
        <StyledRowTable
          checkboxClicked={
            totalLength !== 0 && userList.length === clicked.length
          }
          CHECKBOX
          handleCheckbox={handleCheckbox}
          label={[
            { text: "가입일", width: 130 },
            { text: "이름", width: 180 },
            { text: "생년월일", width: 120 },
            { text: "성별", width: 90 },
            { text: "휴대폰번호", width: 172 },
            { text: "보유카드", width: 118 },
            { text: "공유카드", width: 118 },
            { text: "구독여/부", width: 118 },
          ]}
        />

        <RowTable />
        <PageNation
          page={page}
          totalLength={totalLength}
          onClickPage={onClickPage}
        />
      </Container>
      {sendMessageModal && <MessageModal />}
    </>
  );
};

export default memo(UserListPresenter);
