import { gql } from "@apollo/client";

export const QR_CODE_DOWN = gql`
  mutation QRCodeDown($createNumber: Int, $createdAt: String) {
    QRCodeDown(createNumber: $createNumber, createdAt: $createdAt) {
      tokenExpired
      result
      QRZipLink
    }
  }
`;

export const CREATE_QR_CODE = gql`
  mutation CreateQRCode(
    $qrPaymentType: paymentType
    $qrCreateNumber: Int
    $qrType: qr_type
  ) {
    createQRCode(
      qr_paymentType: $qrPaymentType
      qr_createNumber: $qrCreateNumber
      qr_type: $qrType
    ) {
      tokenExpired
      result
    }
  }
`;
