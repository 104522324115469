import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../../styles/colors";
import StyledModal from "../../../../share/StyledModal";
import StyledTextarea from "../../../../share/StyledTextarea";
import { useMutation } from "@apollo/client";
import { SEND_MESSAGE } from "../../../../../graphql/User/State/UserList/mutation";
import { toast } from "react-toastify";
import { UserListContext } from "../../../../../routes/User/State/UserList/UserListContainer";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const RadioButton = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: ${colors.radioBackground};
  border: 1px solid ${colors.radioBorder};
  cursor: pointer;
  ${({ select }) =>
    select &&
    css`
      background-color: ${colors.whiteColor};
      border: 5px solid ${colors.radioBlueBorder};
    `}
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  cursor: pointer;
`;

const Text = styled.div`
  margin: ${({ margin }) => margin};
  color: ${({ gray }) => gray && `${colors.labelGrayFontColor}`};
`;

const MessageModal = () => {
  const { userList, clicked, handleMessageModal, userIdList } =
    useContext(UserListContext);

  const [radio, setRadio] = useState("select");
  const [contents, setContents] = useState("");
  const [sendMessage] = useMutation(SEND_MESSAGE);

  const handleSave = useCallback(async () => {
    if (radio === "select" && clicked.length === 0) {
      toast.error("전송할 사용자를 선택해주세요");
      handleMessageModal();
      return;
    }
    if (contents.length === 0) {
      console.log("내용 없음");
      return;
    }
    try {
      const { data } = await sendMessage({
        variables: {
          userIds: radio === "all" ? userIdList : clicked,
          msg: contents,
        },
      });
      if (data.sendMessage.result) toast.success("문자 전송 완료");
    } catch (e) {
      toast.error(e.message);
    } finally {
      handleMessageModal();
    }
  }, [userList, radio, clicked, contents]);

  const onChangeMessage = (e) => {
    setContents(e.target.value);
  };

  return (
    <StyledModal
      BOTTOMBTN
      width={790}
      height={610}
      title="문자보내기"
      label="전송"
      onClickClose={handleMessageModal}
      onClickSave={handleSave}
    >
      <Container>
        <RadioWrapper onClick={() => setRadio("select")}>
          <RadioButton select={radio === "select"} />
          <Text margin="0 47px 0 0" gray={radio !== "select"}>
            선택한 사용자
          </Text>
        </RadioWrapper>
        <RadioWrapper onClick={() => setRadio("all")}>
          <RadioButton select={radio === "all"} />
          <Text gray={radio !== "all"}>전체 보내기</Text>
        </RadioWrapper>
        <StyledTextarea
          width={710}
          height={320}
          value={contents}
          label="내용"
          onChange={onChangeMessage}
          maxLength={250}
        />
      </Container>
    </StyledModal>
  );
};

export default memo(MessageModal);
