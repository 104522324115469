import React, { useState, useContext, memo } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import { dateFormat } from "../../../../utils/commons";
import { QR_CODE_DOWN } from "../../../../graphql/System/Qr/mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { qrTypeChanger } from "../../../../lib/systemUtils";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
`;

const Button = styled.button`
  width: 124px;
  height: 39px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  margin: 0 30px 0 auto;
  border: 1px solid ${colors.blackColor};
  background-color: ${colors.whiteColor};
`;

const RowTable = ({ qrList }) => {
  const [QRCodeDown] = useMutation(QR_CODE_DOWN);

  const handleQRDown = async (createNumber, createdAt) => {
    console.log(createdAt);
    try {
      const { data } = await QRCodeDown({
        variables: {
          createNumber: createNumber,
          createdAt: new Date(Number(createdAt)),
        },
      });
      console.log(">>>>>", data);
      if (data?.QRCodeDown?.result) {
        window.open(data.QRCodeDown.QRZipLink);
        toast.success("QR 코드를 다운로드 하였습니다.");
      }
    } catch (e) {
      toast.error(e.meesage);
    }
  };

  return (
    <>
      <Container>
        {qrList.length !== 0 &&
          qrList.map((data, index) => (
            <RowBox key={index}>
              {console.log(data)}
              <Text width={161}>
                {dateFormat(new Date(Number(data.createdAt)), "yyyy.MM.dd")}
              </Text>
              <Text width={128}>{qrTypeChanger(data.qr_type)}</Text>
              <Text width={190}>
                {data.qr_paymentType === "basic" ? "베이직" : "프리미엄"}
              </Text>
              <Text width={148}>{data.totalAmount.toLocaleString()} EA</Text>
              <Text width={179}>{data.usedAmount.toLocaleString()} EA</Text>
              <Text width={193}>{data.unUsedAmount.toLocaleString()} EA</Text>
              <Text width={116}>{data.usedRatio} %</Text>
              <Button
                onClick={() => handleQRDown(data.createNumber, data.createdAt)}
              >
                QR 다운로드
              </Button>
            </RowBox>
          ))}
      </Container>
    </>
  );
};

export default memo(RowTable);
