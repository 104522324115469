import { gql } from "@apollo/client";

export const CREATE_EVENT = gql`
  mutation createEvent(
    $evnt_title: String!
    $evnt_start: String!
    $evnt_end: String!
    $evnt_img: Upload!
  ) {
    createEvent(
      evnt_title: $evnt_title
      evnt_start: $evnt_start
      evnt_end: $evnt_end
      evnt_img: $evnt_img
    ) {
      tokenExpired
      result
    }
  }
`;

export const EDIT_EVENT = gql`
  mutation EditEvent(
    $evntId: Int!
    $evntTitle: String
    $evntStart: String
    $evntEnd: String
    $evntImg: Upload
    $evntImgEdit: Boolean
  ) {
    editEvent(
      evnt_id: $evntId
      evnt_title: $evntTitle
      evnt_start: $evntStart
      evnt_end: $evntEnd
      evnt_img: $evntImg
      evnt_img_edit: $evntImgEdit
    ) {
      result
      tokenExpired
    }
  }
`;

export const EDIT_EVENT_STATUS = gql`
  mutation editEventStatus($evnt_id: Int!, $evnt_status: Boolean!) {
    editEventStatus(evnt_id: $evnt_id, evnt_status: $evnt_status) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($evnt_id: Int!) {
    deleteEvent(evnt_id: $evnt_id) {
      tokenExpired
      result
    }
  }
`;
