import React from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import EmptyPage from "../../../components/share/EmptyPage";

const ServiceCenterPresenter = () => {
  return (
    <>
      <GuideLine title={["운영", "고객센터 정보 등록"]} />
      <EmptyPage />
    </>
  );
};

export default ServiceCenterPresenter;
