import React, {
  useState,
  createContext,
  useEffect,
  useCallback,
  useContext,
} from "react";
import EventPresenter from "./EventPresenter";
import { useQuery, useMutation } from "@apollo/client";
import { SEE_EVENT_LIST } from "../../../graphql/Management/Event/query";
import { noticeSortChanger } from "../../../lib/managementUtils";
import { toast } from "react-toastify";
import { DELETE_EVENT } from "../../../graphql/Management/Event/mutation";
import { AuthContext } from "../../../context/AuthProvider";

export const EventContext = createContext();

const EventContainer = () => {
  const { userLogout } = useContext(AuthContext);

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [eventList, setEventList] = useState([]);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "최신순",
  });

  const [clicked, setClicked] = useState(null);
  const [deleteEvent] = useMutation(DELETE_EVENT);

  const {
    data: eventData,
    loading,
    refetch,
  } = useQuery(SEE_EVENT_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      sort: noticeSortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  useEffect(() => {
    if (eventData) {
      if (!eventData.seeEventList.tokenExpired) {
        userLogout();
      }
      setEventList(eventData.seeEventList.eventList);
      setTotalLength(eventData.seeEventList.totalLength);
    }
  }, [eventData]);

  const handleReset = () => {
    setInputs({
      searchTerm: "",
      sort: "최신순",
    });
  };

  const handleRadio = (index) => {
    if (clicked === index) setClicked(null);
    else setClicked(index);
  };

  const handleAddModal = () => {
    setAddModal(!addModal);
  };

  const handleEditModal = () => {
    if (clicked === null) {
      toast.error("수정할 이벤트를 선택해주세요.");
      return;
    }
    setEditModal(!editModal);
  };

  const handleMinus = async () => {
    if (clicked === null) {
      toast.error("삭제할 이벤트를 선택해주세요.");
      return;
    }
    if (window.confirm("이벤트를 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteEvent({
          variables: {
            evnt_id: eventList[clicked].evnt_id,
          },
        });
        console.log("이벤트 삭제", data);
        if (data.deleteEvent.result) {
          toast.success("이벤트를 삭제하였습니다.");
          setClicked(null);
          await refetch();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <EventContext.Provider
      value={{
        inputs,
        setInputs,
        clicked,
        handleReset,
        handleRadio,
        addModal,
        handleAddModal,
        totalLength,
        eventList,
        refetch,
        handleMinus,
        handleEditModal,
        editModal,
        setClicked,
        onClickPage,
        page,
      }}
    >
      <EventPresenter />
    </EventContext.Provider>
  );
};

export default EventContainer;
