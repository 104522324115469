import { gql } from "@apollo/client";

export const DELETE_WITHDRAWAL_USER = gql`
  mutation deleteWithdrawalUser($user_id: Int!) {
    deleteWithdrawalUser(user_id: $user_id) {
      tokenExpired
      result
    }
  }
`;
