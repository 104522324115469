import { gql } from "@apollo/client";

export const SEE_QR_CODE_LIST = gql`
  query seeQRCodeList($QRSort: qr_sort, $take: Int, $cursor: Int) {
    seeQRCodeList(QRSort: $QRSort, take: $take, cursor: $cursor) {
      tokenExpired
      totalLength
      QRList {
        createdAt
        qr_type
        qr_paymentType
        totalAmount
        usedAmount
        unUsedAmount
        usedRatio
        createNumber
      }
    }
  }
`;
