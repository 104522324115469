import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import ProductListPresenter from "./ProductListPresenter";
import { useMutation, useQuery } from "@apollo/client";
import { SEE_PRODUCT_LIST } from "../../../graphql/Product/ProductList/query";
import { sortChanger } from "../../../lib/productUtils";
import { toast } from "react-toastify";
import { DELETE_PRODUCT } from "../../../graphql/Product/ProductList/mutation";
import { AuthContext } from "../../../context/AuthProvider";

export const ProductListContext = createContext();

const ProductListContainer = () => {
  const { userLogout } = useContext(AuthContext);

  const [productAddModal, setProductAddModal] = useState(false);
  const [productEditModal, setProductEditModal] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "최신순",
  });

  const [productList, setProductList] = useState([]);
  const [totalLength, setTotalLength] = useState(0);

  const [clicked, setClicked] = useState(null);

  const [deleteProduct] = useMutation(DELETE_PRODUCT);

  console.log(inputs.searchTerm, sortChanger(inputs.sort), 16, cursor);
  const {
    data: productListData,
    loading,
    refetch,
  } = useQuery(SEE_PRODUCT_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      sort: sortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  useEffect(() => {
    if (productListData) {
      if (!productListData.seeProductList.tokenExpired) {
        userLogout();
      }
      setProductList(productListData.seeProductList.productList);
      setTotalLength(productListData.seeProductList.totalLength);
    }
  }, [productListData]);

  const handleReset = () => {
    setInputs({
      searchTerm: "",
      sort: "클릭 많은 순",
    });
  };

  const handleMinus = async () => {
    if (clicked === null) {
      toast.error("삭제할 상품을 선택해주세요.");
      return;
    }
    if (window.confirm("상품을 삭제할까요?")) {
      try {
        const { data } = await deleteProduct({
          variables: {
            prd_id: productList[clicked].prd_id,
          },
        });
        console.log(data);
        if (data.deleteProduct.result) {
          setClicked(null);
          await refetch();
          toast.success("상품을 삭제하였습니다.");
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  const handleRadio = (index) => {
    if (clicked === index) setClicked(null);
    else setClicked(index);
  };

  const handleAddModal = () => {
    setProductAddModal(!productAddModal);
  };

  const handleEditModal = () => {
    if (clicked === null) {
      toast.error("수정할 상품을 선택해주세요.");
      return;
    }
    setProductEditModal(!productEditModal);
  };

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <ProductListContext.Provider
      value={{
        inputs,
        setClicked,
        setInputs,
        clicked,
        handleReset,
        handleMinus,
        handleRadio,
        productEditModal,
        productAddModal,
        handleEditModal,
        handleAddModal,
        productList,
        totalLength,
        onClickPage,
        page,
        refetch,
      }}
    >
      <ProductListPresenter />
    </ProductListContext.Provider>
  );
};

export default ProductListContainer;
