import { gql } from "@apollo/client";

export const CREATE_COUPON = gql`
  mutation createCoupon($cp_name: String, $cp_period: Int, $cp_number: Int) {
    createCoupon(
      cp_name: $cp_name
      cp_period: $cp_period
      cp_number: $cp_number
    ) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_COUPON = gql`
  mutation deleteCoupon($cp_id: Int) {
    deleteCoupon(cp_id: $cp_id) {
      tokenExpired
      result
    }
  }
`;
