import React, { useEffect, useState, useCallback, useContext } from "react";
import { toast } from "react-toastify";
import MailPresenter from "./MailPresenter";
import { useQuery } from "@apollo/client";
import { SEE_SEND_MESSAGE_LIST } from "../../../graphql/User/Mail/query";
import { sortChanger } from "../../../lib/userUtils";
import { AuthContext } from "../../../context/AuthProvider";

const MailContainer = () => {
  const { userLogout } = useContext(AuthContext);

  const [page, setPage] = useState(1);
  const [cursor, setCursor] = useState(0);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "내림차순",
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });

  const [messageList, setMessageList] = useState([]);
  const [totalLength, setTotalLength] = useState(0);

  const { data, loading, refetch } = useQuery(SEE_SEND_MESSAGE_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      signInDateSort: sortChanger(inputs.sort),
      startDate: inputs.startDate,
      endDate: inputs.endDate,
      take: 16,
      cursor: cursor,
    },
  });

  useEffect(() => {
    if (data) {
      if (!data.seeSendMessageList.tokenExpired) {
        userLogout();
      }
      setMessageList(data.seeSendMessageList.sendList);
      setTotalLength(data.seeSendMessageList.totalLength);
    }
  }, [data]);

  const handleReset = () => {
    setInputs({
      searchTerm: "",
      sort: "내림차순",
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    });
  };

  const onChangeDate = (type, date) => {
    if (type === "endDate") {
      if (inputs.startDate === "") {
        toast.error("시작일 먼저 지정해주세요.");
        return;
      }
      if (new Date(inputs.startDate) > date) {
        toast.error("시작일 이후로 지정해주세요");
        return;
      }
    }
    setInputs({
      ...inputs,
      [type]: String(date),
    });
  };

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <MailPresenter
      inputs={inputs}
      setInputs={setInputs}
      handleReset={handleReset}
      onChangeDate={onChangeDate}
      messageList={messageList}
      totalLength={totalLength}
      page={page}
      onClickPage={onClickPage}
    />
  );
};

export default MailContainer;
