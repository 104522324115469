export const sortChanger = (value) => {
  switch (value) {
    case "최신순":
      return "createdAt";
    case "클릭 수 많은 순":
      return "descending";
    case "클릭 수 적은 순":
      return "ascending";
  }
};
