export const sortChanger = (value) => {
  switch (value) {
    case "최신 순":
      return "desc";
    case "오래된 순":
      return "asc";
    case "미사용":
      return "unused";
    case "사용":
      return "used";
  }
};

export const createType = (value) => {
  switch (value) {
    case "관리자":
      return "admin";
    case "딜러":
      return "buyer";
  }
};
