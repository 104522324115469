import React, { useContext } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import StyledRadioButton from "../../../share/StyledRadioButton";
import { FaqContext } from "../../../../routes/Management/Faq/FaqContainer";
import { dateFormat } from "../../../../utils/commons";

const dummydata = [
  {
    작성일: "22.08.23",
    작성자: "관리자A",
    질문명: "회원 가입은 어떻게 하나요?",
    답변내용: "회원가입은 앱을 다운로드 한 후 초기 화면에서 가능합니다.",
  },
  {
    작성일: "22.08.23",
    작성자: "관리자A",
    질문명: "회원 가입은 어떻게 하나요?",
    답변내용: "회원가입은 앱을 다운로드 한 후 초기 화면에서 가능합니다.",
  },
  {
    작성일: "22.08.23",
    작성자: "관리자A",
    질문명: "회원 가입은 어떻게 하나요?",
    답변내용: "회원가입은 앱을 다운로드 한 후 초기 화면에서 가능합니다.",
  },
];

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
`;

const RowTable = () => {
  const { faqList, clicked, handleRadio } = useContext(FaqContext);

  return (
    <Container>
      {faqList.map((data, index) => (
        <RowBox key={index}>
          <StyledRadioButton
            clicked={clicked === index}
            onClick={() => handleRadio(index)}
          />
          <Text width={112}>
            {dateFormat(new Date(Number(data.faq_createdAt)), "yy.MM.dd")}
          </Text>
          <Text width={120}>{data.faq_admin_name}</Text>
          <Text width={230}>
            {data.faq_question.length > 15
              ? data.faq_question.substring(0, 15) + "..."
              : data.faq_question}
          </Text>
          <Text width={1100}>
            {data.faq_answer.length > 70
              ? data.faq_answer.substring(0, 70) + "..."
              : data.faq_answer}
          </Text>
        </RowBox>
      ))}
    </Container>
  );
};

export default RowTable;
