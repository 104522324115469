import React, { useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../styles/colors";
import checkIcon from "../../assets/checkIcon.png";

const Container = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 20px;
  border-radius: 6px;
  border: 1px solid ${colors.labelGrayFontColor};
  background-color: ${colors.whiteColor};
  position: relative;
  transition: 0.2s;
  cursor: pointer;
  ${({ clicked }) =>
    clicked &&
    css`
      background-color: ${colors.blackColor};
      border: none;
    `}
`;

const CheckIcon = styled.img`
  width: 22px;
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
  transition: 0.3s;
  display: ${({ clicked }) => (clicked ? "block" : "none")};
`;

const StyledCheckbox = ({ name = "", clicked = false, onClick = () => {} }) => {
  // const [check, setCheck] = useState(clicked);
  return (
    //TODO :: 뮤테이션 붙이고 onClick 넣어줘야함
    <Container clicked={clicked} onClick={onClick}>
      <CheckIcon clicked={clicked} src={checkIcon} />
    </Container>
  );
};

export default React.memo(StyledCheckbox);
