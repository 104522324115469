export const sortChanger = (value) => {
  switch (value) {
    case "전체":
      return "total";
    case "상품":
      return "product";
    case "프리미엄":
      return "premium";
  }
};
