import React from "react";
import GuideLine from "../../components/layout/SideBar/GuideLine";
import HeaderCount from "../../components/feature/Home/HeaderCount";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import BarChart from "../../components/share/BarChart";
import CircleChart from "../../components/share/CircleChart";
import Table from "../../components/share/Table";

const Whitebg = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 965px;
  padding: 14px 16px;
  background-color: ${colors.whiteColor};
`;

const GraphWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1692px;
  display: grid;
  row-gap: 10px;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(2, 394px);
`;

const HomePresenter = ({
  countList,
  qrList,
  signupList,
  subsList,
  paymentList,
  clickList,
  noticeList,
}) => {
  return (
    <>
      <GuideLine title={["홈"]} />
      <HeaderCount countList={countList} />
      <Whitebg>
        <GraphWrapper>
          <BarChart SIGNUP title="유저 가입 통계" data={signupList} />
          <CircleChart title="유저 분류" data={subsList} />
          <BarChart PAYMENT title="월간 매출 통계" data={paymentList} />
          <Table
            LONG
            title="QR 발행 현황"
            header={["분류", "수량", "생성일"]}
            rowData={qrList}
          />
          <Table
            title="공지"
            width={297}
            header={["내용", "날짜"]}
            rowData={noticeList}
          />
          <BarChart CLICK title="주차번호판 링크 클릭 통계" data={clickList} />
        </GraphWrapper>
      </Whitebg>
    </>
  );
};

export default HomePresenter;
