import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { colors } from "./colors";

export default createGlobalStyle`
    ${reset};
    * {
        box-sizing: border-box;
    }
    body {
      width: 100vw;
      font-size: 16px;
      font-family: "SF Pro Display", sans-serif;
      box-sizing: border-box;
      scrollbar-width: none;
      -ms-overflow-style: none;
      background-color: ${colors.defaultBackgroundColor};
      /* body::-webkit-scrollbar {display: none;} */
      ::-webkit-scrollbar{
        width:8px;
        height:8px;
      }
      ::-webkit-scrollbar-thumb{
        width: 8px;
        background-color: ${colors.lightGrayBorderColor};
        border-radius: 5px ;
      }

    }
    a {
      text-decoration: none;
      color: ${colors.blackColor}; 
    }
    textarea {
      border: none;
      resize: none;
    }
    input {
      height: 100%;
      border: none;
    }
    textarea:focus,
    input:focus{
      outline: none;
        border: 1px solid ${colors.blackColor};
    }
    input::placeholder,
    textarea::placeholder {
      font-size: 16px;
      color: ${colors.lightGrayFont};
    }
    button {
      border: none;
      outline: none;
      cursor: pointer;
    }
    select {
      outline: none;
      cursor: pointer;
      -webkit-appearance: none; /* 화살표 없애기 for chrome*/
      -moz-appearance: none; /* 화살표 없애기 for firefox*/
      appearance: none; /* 화살표 없애기 공통*/
    }
`;
