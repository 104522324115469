import React, {useContext, useState} from "react";
import styled from "styled-components";
import {css} from "styled-components";
import {colors} from "../../../../styles/colors";
import {autoHyphen, dateFormat, subString} from "../../../../utils/commons";
import {QnaContext} from "../../../../routes/Management/Qna/QnaContainer";
import AddModal from "./AddModal";

const dummydata = [
    {
        답변상태: true,
        작성일: "22. 08. 30",
        유저명: "홍길동",
        연락처: "01012340001",
        문의내용:
            "트고, 이성은 할지니, 사람은 타오르고 주는 그들의 우리는 것이다. 구하기 때까지 뛰노는 풍부하게 주며, 같지 석가는 것이다. 석가는 있으며, 창공에 이상, 어디 얼음에 부패뿐이다. 그들의 무엇을 만물은 많이 오아이스도 봄바람을 기관과 것이",
        답변자: "관리자_A",
        답변일: "22.09.01",
    },
    {
        답변상태: false,
        작성일: "22. 08. 30",
        유저명: "홍길동",
        연락처: "01012340001",
        문의내용:
            "트고, 이성은 할지니, 사람은 타오르고 주는 그들의 우리는 것이다. 구하기 때까지 뛰노는 풍부하게 주며, 같지 석가는 것이다. 석가는 있으며, 창공에 이상, 어디 얼음에 부패뿐이다. 그들의 무엇을 만물은 많이 오아이스도 봄바람을 기관과 것이",
        답변자: "관리자_A",
        답변일: "22.09.01",
    },
];

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
  cursor: pointer;

  &:hover {
    background-color: ${colors.defaultBackgroundColor};
  }
`;

const Text = styled.div`
  width: ${({width}) => width}px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: ${({ paddingRight }) => paddingRight ? paddingRight : 0}px;
  overflow: hidden;

  ${({complete, pointer}) =>
          complete
                  ? css`
                    color: ${colors.lightGrayBorderColor};
                  `
                  : pointer &&
                  css`
                    cursor: pointer;
                  `}
`;

const RowTable = () => {
    const {qnaList} = useContext(QnaContext);

    const [clicked, setClicked] = useState(null);
    const [selectedQna, setSelectedQna] = useState(null);
    const [answerModal, setAnswerModal] = useState(false);

    const handleModal = () => {
        setAnswerModal(!answerModal);
    };

    return (
        <>
            <Container>
                {qnaList.map((data, index) => (
                    <RowBox key={index} onClick={() => {
                      handleModal();
                      // !data.one_answer_status && handleModal();
                      setClicked(index);
                      setSelectedQna(qnaList.find(list => list.one_id === data.one_id));
                    }}>
                        <Text
                            width={116}
                            pointer
                            complete={data.one_answer_status}
                        >
                            {data.one_answer_status ? "완료" : "답변하기"}
                        </Text>
                        <Text width={130}>
                            {dateFormat(new Date(Number(data.one_createdAt)), "yy.MM.dd")}
                        </Text>
                        <Text width={102}>{data.one_user_name}</Text>
                        <Text width={177}>{autoHyphen(data.one_user_cellphone)}</Text>
                        <Text width={400} paddingRight={16}>{data.one_question}</Text>
                        <Text width={500} paddingRight={16}>
                            {data.one_answer}
                        </Text>
                        <Text width={120}>
                            {data.one_admin_name === "" ? "-" : data.one_admin_name}
                        </Text>
                        <Text width={96}>
                            {data.one_answer_date === "" ? "-" : data.one_answer_date}
                        </Text>
                    </RowBox>
                ))}
            </Container>
            {answerModal && (
                <AddModal
                    clicked={clicked}
                    selectedQna={selectedQna}
                    setSelectedQna={setSelectedQna}
                    handleModal={handleModal}
                    setClicked={setClicked}
                />
            )}
        </>
    );
};

export default RowTable;
