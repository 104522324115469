import React, { useEffect, useState, createContext, useContext } from "react";
import DetailPresenter from "./DetailPresenter";
import { AuthContext } from "../../../../context/AuthProvider";
import { useQuery } from "@apollo/client";
import {
  SEE_USER_DETAIL,
  SEE_USER_ADVICE_DAILY_LOG,
} from "../../../../graphql/User/State/Detail/query";

export const DetailContext = createContext();

const DetailContainer = () => {
  const { userLogout } = useContext(AuthContext);

  // const location = useLocation().state; 이거 사용하고싶은데 새로고침하면 없어짐
  const user_id = localStorage.getItem("user_id"); // 그래서 이거 사용
  const [tab, setTab] = useState("userInfo");
  const [userDetail, setUserDetail] = useState({
    user_id: 0,
    user_img: "",
    user_createdAt: "",
    user_name: "",
    user_birthday: "",
    user_gender: "",
    user_cellphone: "",
    user_card_count: 0,
    user_share_count: 0,
    user_subs_available: false,
    user_total_subsPayment: 0,
    user_sido: "",
    user_sigungu: "",
    user_address_detail: "",
    user_admin_memo: "",
  });
  const [adviceLogList, setAdviceLogList] = useState([]);

  const {
    data: userDetailData,
    loading: userDetailLoading,
    refetch: userDetailRefetch,
  } = useQuery(SEE_USER_DETAIL, {
    variables: {
      user_id: Number(user_id),
    },
  });

  const {
    data: adviceLogData,
    loading: adviceLogLoading,
    refetch: adviceLogRefetch,
  } = useQuery(SEE_USER_ADVICE_DAILY_LOG, {
    variables: {
      user_id: Number(user_id),
    },
  });

  useEffect(() => {
    if (userDetailData) {
      if (!userDetailData.seeUserDetail.tokenExpired) {
        userLogout();
      }
      setUserDetail(userDetailData.seeUserDetail.userDetail);
    }
    if (adviceLogData)
      setAdviceLogList(adviceLogData.seeUserAdviceDailyLog.dailyLogList);
  }, [userDetailData, adviceLogData]);

  return (
    <DetailContext.Provider
      value={{
        userDetail,
        setUserDetail,
        userDetailRefetch,
        adviceLogList,
        adviceLogRefetch,
      }}
    >
      <DetailPresenter tab={tab} setTab={setTab} />
    </DetailContext.Provider>
  );
};

export default DetailContainer;
