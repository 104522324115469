import React, { memo, useCallback } from "react";
import styled from "styled-components";
import StyledInput from "../share/StyledInput";
import StyledSelect from "../share/StyledSelect";
import StyledButton from "../share/StyledButton";
import StyledIconButton from "../share/StyledIconButton";
import StyledSelectDate from "../share/StyledSelectDate";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SortingWrapper = styled.div`
  display: flex;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  margin-top: auto;
`;

const Margin = styled.div`
  width: 18px;
`;

const SortingForm = ({
  SEARCH,
  SUBSCRIPTION,
  SORT,
  COUPON,
  NOTICE,
  TERM,
  QNA,
  STATS,
  RESET,
  EXCEL,
  COUPONTYPE,
  EVENT,
  MAIL,
  QR,
  PLUS,
  FAQ,
  MINUS,
  EDIT,
  SELECTDATE,
  PRODUCT,
  LATESTSORT,
  inputs = {},
  label = "정렬",
  placeholder = "유저명, 휴대폰번호로 검색",
  width = 280,
  onChangeDate = () => {},
  setInputs = () => {},
  handleReset = () => {},
  handlePlus = () => {},
  handleMinus = () => {},
  handleEdit = () => {},
  handleSendMail = () => {},
  handleExcel = () => {},
}) => {
  const { searchTerm, subscription, publishType, sort, startDate, endDate } =
    inputs;

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const onChangeSelect = useCallback((name, value) => {
    setInputs({ ...inputs, [name]: value });
  });

  return (
    <Container>
      <SortingWrapper>
        {SEARCH && (
          <>
            <StyledInput
              width={width}
              SEARCH
              label="검색"
              placeholder={placeholder}
              name="searchTerm"
              value={searchTerm}
              onChange={onChangeInputs}
            />
            <Margin />
          </>
        )}
        {SUBSCRIPTION && (
          <>
            <StyledSelect
              ARROW
              label="구독여/부"
              name="subscription"
              value={subscription}
              onChange={onChangeSelect}
              options={["전체", "프리미엄", "일반"]}
            />
            <Margin />
          </>
        )}
        {COUPONTYPE && (
          <>
            <StyledSelect
              ARROW
              label="발행 유형"
              name="publishType"
              value={publishType}
              onChange={onChangeSelect}
              options={["관리자", "딜러"]}
            />
            <Margin />
          </>
        )}
        {COUPON && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["최신 순", "오래된 순", "미사용", "사용"]}
            />
            <Margin />
          </>
        )}

        {STATS && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["전체", "상품", "프리미엄"]}
            />
            <Margin />
          </>
        )}
        {SORT && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["내림차순", "오름차순"]}
            />
            <Margin />
          </>
        )}
        {FAQ && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["최신순", "작성자"]}
            />
            <Margin />
          </>
        )}
        {EVENT && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["최신순", "미노출", "이벤트명"]}
            />
            <Margin />
          </>
        )}
        {NOTICE && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["최신순", "미노출", "공지명"]}
            />
            <Margin />
          </>
        )}
        {QNA && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["작성일", "답변"]}
            />
            <Margin />
          </>
        )}
        {TERM && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["등록일", "약관명"]}
            />
            <Margin />
          </>
        )}
        {QR && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["최신순", "분류"]}
            />
            <Margin />
          </>
        )}
        {LATESTSORT && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["최신순", "오래된순"]}
            />
            <Margin />
          </>
        )}
        {SELECTDATE && (
          <>
            <StyledSelectDate
              startDate={startDate}
              endDate={endDate}
              onChangeDate={onChangeDate}
            />
            <Margin />
          </>
        )}
        {PRODUCT && (
          <>
            <StyledSelect
              ARROW
              label={label}
              name="sort"
              value={sort}
              onChange={onChangeSelect}
              options={["최신순", "클릭 수 많은 순", "클릭 수 적은 순"]}
            />
            <Margin />
          </>
        )}
        {RESET && (
          <>
            <StyledButton RESET label="초기화" onClick={handleReset} />
            <Margin />
          </>
        )}
      </SortingWrapper>
      <IconButtonWrapper>
        {PLUS && <StyledIconButton PLUS onClick={handlePlus} />}
        {MINUS && <StyledIconButton MINUS onClick={handleMinus} />}
        {EDIT && <StyledIconButton EDIT onClick={handleEdit} />}
        {MAIL && <StyledIconButton MAIL onClick={handleSendMail} />}
        {EXCEL && <StyledIconButton EXCEL onClick={handleExcel} />}
      </IconButtonWrapper>
    </Container>
  );
};

export default memo(SortingForm);
