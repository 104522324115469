import { gql } from "@apollo/client";

export const ADMIN_LOGIN = gql`
  mutation adminLogin($id: String, $pw: String) {
    adminLogin(id: $id, pw: $pw) {
      token
      pw_init
    }
  }
`;

export const EDIT_ADMIN_PW = gql`
  mutation editAdminPW($admin_accountId: String, $pw: String) {
    editAdminPW(admin_accountId: $admin_accountId, pw: $pw) {
      result
    }
  }
`;
