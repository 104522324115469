import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { colors } from "../../../../styles/colors";

const Container = styled.div`
  width: 1644px;
  height: 726px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  border: 1px dotted ${colors.defaultBackgroundColor};
  position: relative;
`;

const TopTextContainer = styled.div`
  height: 62px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 17px;
`;

const GraphContainer = styled.div`
  width: 100%;
  height: 526px;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const BarWrapper = styled.div`
  width: 1535px;
  height: 526px;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
`;

const animation = (height) => keyframes`
  0%{
      height: 0%;
  }
  100% {
      height: ${height}%;
  }
`;

const Bar = styled.div`
  width: 40px;
  background-color: ${colors.graphBlueColor};
  height: ${({ height }) => height && height}%;
  animation: 2s ${({ height }) => height && animation(height)} ease;
  z-index: 1;
`;

const Line = styled.div`
  height: 1px;
  width: 1535px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
`;

const YLineWrapper = styled.div`
  width: 100%;
  height: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: ${({ top }) => top && top}%;
  color: ${colors.labelGrayFontColor}; ;
`;

const AvgContainer = styled.div`
  width: 1535px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  bottom: ${({ top }) => top}%;
  border-bottom: 1px dotted ${colors.redColor};
  color: ${colors.redColor};
  padding-bottom: 7px;
  z-index: 2;
`;

const XContainer = styled.div`
  width: 1535px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-top: 21px;
`;

const MonthPriceWrapper = styled.div`
  width: 127px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const Graph = ({ monthlyList, average }) => {
  // 데이터 값들만 따로 묶음
  const paymentList = monthlyList.map((data) => data.paymentAmount);

  // 단위 (10단위로 자릿수 제곱함) ex) 만원, 십만원, 백만원...
  const [unit, setUnit] = useState(
    Math.pow(10, Math.max(...paymentList).toString().length - 1)
  );

  // 가장 큰 값 (y축을 위해서 구함 그래서 + 1해줌) ex) 가장큰값-> 6.1만원 , max-> 7
  const [max, setMax] = useState(parseInt(Math.max(...paymentList) / unit) + 1);

  useEffect(() => {
    setMax(
      parseInt(
        Math.max(...paymentList) /
          Math.pow(10, Math.max(...paymentList).toString().length - 1)
      ) + 1
    );
    setUnit(Math.pow(10, Math.max(...paymentList).toString().length - 1));
  }, [paymentList]);

  return (
    <Container>
      <TopTextContainer>
        <Text>월별 매출 현황</Text>
        <Text>{`단위(${makeUnit(unit)})`}</Text>
      </TopTextContainer>
      <GraphContainer>
        {/* y축 단위 */}
        {[100, 75, 50, 25, 0].map((number, index) => (
          <YLineWrapper key={index} top={number}>
            {number * 0.01 * max}
            <Line />
          </YLineWrapper>
        ))}

        <BarWrapper>
          {/* 바  */}
          {monthlyList.map((data, index) => (
            <Bar key={index} height={(data.paymentAmount / unit / max) * 100} />
          ))}
        </BarWrapper>
        <AvgContainer top={(average / unit / max) * 100}>
          {/* 푱균 */}
          평균금액 : {average.toLocaleString()}원
        </AvgContainer>
      </GraphContainer>
      <XContainer>
        {/* x축 */}
        {monthlyList.map((data, index) => {
          return (
            <MonthPriceWrapper key={index}>
              <Text fontWeight={500}>{data.month}월</Text>
              <Text fontWeight={500}>
                {data.paymentAmount.toLocaleString()} 원
              </Text>
            </MonthPriceWrapper>
          );
        })}
      </XContainer>
    </Container>
  );
};

export default Graph;

function makeUnit(n) {
  let len = n.toString().length;
  switch (len) {
    case 1:
      return "원";
    case 2:
      return "십원";
    case 3:
      return "백원";
    case 4:
      return "천원";
    case 5:
      return "만원";
    case 6:
      return "십만원";
    case 7:
      return "백만원";
    case 8:
      return "천만원";
    case 9:
      return "억원";
    case 10:
      return "십억원";
    case 11:
      return "백억원";
    case 12:
      return "천억원";
    default:
      return;
  }
}
