import { gql } from "@apollo/client";

export const SEE_SEND_MESSAGE_LIST = gql`
  query seeSendMessageList(
    $searchTerm: String
    $signInDateSort: descAscsort
    $startDate: String
    $endDate: String
    $take: Int!
    $cursor: Int!
  ) {
    seeSendMessageList(
      searchTerm: $searchTerm
      signInDateSort: $signInDateSort
      startDate: $startDate
      endDate: $endDate
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      sendList {
        a2umh_id
        a2umh_sendDate
        a2umh_sendTime
        a2umh_sender
        a2umh_receiver
        a2umh_text
        a2umh_storagePeriod
      }
    }
  }
`;
