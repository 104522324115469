import { gql } from "@apollo/client";

export const SEE_PAYMENT_STATISTICS = gql`
  query seePaymentStatistics($statisticsFilter: statistics) {
    seePaymentStatistics(statisticsFilter: $statisticsFilter) {
      tokenExpired
      avgPaymentAmount
      monthlyInfoList {
        month
        paymentAmount
      }
    }
  }
`;
