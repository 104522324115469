import React, { memo, useContext } from "react";
import styled from "styled-components";
import { colors } from "../../../../../styles/colors";
import { autoHyphen } from "../../../../../utils/commons";
import StyledCheckbox from "../../../../share/StyledCheckbox";
import { useNavigate } from "react-router-dom";
import { gendarChanger, subsChanger } from "../../../../../lib/userUtils";
import { UserListContext } from "../../../../../routes/User/State/UserList/UserListContainer";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
  &:hover {
    background-color: ${colors.lightGrayBorderColorr};
  }
`;

const ClickArea = styled.div`
  display: flex;
  cursor: pointer;
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  color: ${colors.blackColor};
`;

const RowTable = () => {
  const { userList, clicked, handleCheckbox } = useContext(UserListContext);

  const navigate = useNavigate();

  return (
    <Container>
      {userList.length !== 0 &&
        userList.map((data, index) => (
          <RowBox key={index}>
            <StyledCheckbox
              clicked={clicked.includes(data.user_id)}
              onClick={() => handleCheckbox(data.user_id)}
            />
            <ClickArea
              onClick={() => {
                navigate("/user/state/detail", { state: data.user_id });
                localStorage.setItem("user_id", data.user_id);
              }}
            >
              <Text width={130}>{data.user_createdAt}</Text>
              <Text width={180}>{data.user_name}</Text>
              <Text width={120}>{data.user_birthday}</Text>
              <Text width={90}>{gendarChanger(data.user_gender)}</Text>
              <Text width={172}>{autoHyphen(data.user_cellphone)}</Text>
              <Text width={118}>{data.user_card_count} 개</Text>
              <Text width={118}>{data.user_share_count} 개</Text>
              <Text width={200}>{subsChanger(data.user_subs_available)}</Text>
            </ClickArea>
          </RowBox>
        ))}
    </Container>
  );
};

export default memo(RowTable);
