export const subsChanger = (value) => {
  switch (value) {
    case "전체":
      return "total";
    case "프리미엄":
      return "premium";
    case "일반":
      return "general";
    case "total":
      return "전체";
    case "premium":
      return "프리미엄";
    case "general":
      return "일반";
    case false:
      return "일반";
    case true:
      return "프리미엄";
  }
};

export const sortChanger = (value) => {
  switch (value) {
    case "내림차순":
      return "descending";
    case "오름차순":
      return "ascending";
  }
};

export const gendarChanger = (value) => {
  switch (value) {
    case "man":
      return "남";
    case "woman":
      return "여";
    case "남":
      return "man";
    case "여":
      return "woman";
    default:
      return "empty";
  }
};
