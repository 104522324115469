import React, { useState, useCallback, useEffect, useContext } from "react";
import QrPresenter from "./QrPresenter";
import { useQuery } from "@apollo/client";
import { SEE_QR_CODE_LIST } from "../../../graphql/System/Qr/query";
import { qrSortChanger } from "../../../lib/systemUtils";
import { AuthContext } from "../../../context/AuthProvider";

const QrContainer = () => {
  const { userLogout } = useContext(AuthContext);

  const [qrAddModal, setQrAddModal] = useState(false);
  const [inputs, setInputs] = useState({
    sort: "최신순",
  });

  const [page, setPage] = useState(1);
  const [cursor, setCursor] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [qrList, setQrList] = useState([]);

  const {
    data: qrData,
    refetch,
    loading,
  } = useQuery(SEE_QR_CODE_LIST, {
    variables: {
      QRSort: qrSortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  useEffect(() => {
    if (qrData) {
      if (!qrData.seeQRCodeList.tokenExpired) {
        userLogout();
      }
      setQrList(qrData.seeQRCodeList.QRList);
      setTotalLength(qrData.seeQRCodeList.totalLength);
    }
  }, [qrData]);

  const handleReset = () => {
    setInputs({ sort: "최신순" });
  };

  const handleAddModal = () => {
    setQrAddModal(!qrAddModal);
  };

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <QrPresenter
      qrList={qrList}
      inputs={inputs}
      setInputs={setInputs}
      handleReset={handleReset}
      page={page}
      totalLength={totalLength}
      onClickPage={onClickPage}
      qrAddModal={qrAddModal}
      handleAddModal={handleAddModal}
      refetch={refetch}
    />
  );
};

export default QrContainer;
