import React from "react";
import { Route, Router, Routes } from "react-router-dom";
import SideBar from "./components/layout/SideBar/SideBar";
import AuthProvider from "./context/AuthProvider";
import Home from "./routes/Home";
import Login from "./routes/Login";
import styled from "styled-components";
import UserList from "./routes/User/State/UserList";
import UnRegister from "./routes/User/UnRegister";
import Mail from "./routes/User/Mail";
import ProductList from "./routes/Product/ProductList";
import Stats from "./routes/Product/Stats";
import CouponList from "./routes/Coupon/CouponList";
import Notice from "./routes/Management/Notice";
import Event from "./routes/Management/Event";
import Faq from "./routes/Management/Faq";
import Qna from "./routes/Management/Qna";
import ServiceCenter from "./routes/Management/ServiceCenter";
import Term from "./routes/Management/Term";
import Analysis from "./routes/Sales/Analysis";
import SalesStats from "./routes/Sales/SalesStats";
import User from "./routes/System/User";
import Detail from "./routes/User/State/Detail";
import Qr from "./routes/System/Qr";

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 228px 1fr;
`;

const Contents = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 1080px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1920px) {
    height: calc(100vh - 8px);
  }
`;

const LoggedOutRoutes = ({ setIsLoggedIn }) => (
  <Routes>
    <Route path="*" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
    <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
  </Routes>
);

const LoggedInRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/home" element={<Home />} />
    <Route path="/user/state" element={<UserList />} />
    <Route path="/user/state/detail" element={<Detail />} />
    <Route path="/user/unregister" element={<UnRegister />} />
    <Route path="/user/sendmail" element={<Mail />} />
    <Route path="/product/list" element={<ProductList />} />
    <Route path="/product/stats" element={<Stats />} />
    <Route path="/coupon/list" element={<CouponList />} />
    <Route path="/sales/analysis" element={<Analysis />} />
    <Route path="/sales/stats" element={<SalesStats />} />
    <Route path="/management/notice" element={<Notice />} />
    <Route path="/management/evnet" element={<Event />} />
    <Route path="/management/faq" element={<Faq />} />
    <Route path="/management/qna" element={<Qna />} />
    <Route path="/management/service" element={<ServiceCenter />} />
    <Route path="/management/term" element={<Term />} />
    <Route path="/system/user" element={<User />} />
    <Route path="/system/qr" element={<Qr />} />
  </Routes>
);

const AppRouter = ({ isLoggedIn, setIsLoggedIn }) => {
  return (
    <AuthProvider>
      {isLoggedIn ? (
        <Container>
          <SideBar />
          <Contents>
            <LoggedInRoutes />
          </Contents>
        </Container>
      ) : (
        <LoggedOutRoutes setIsLoggedIn={setIsLoggedIn} />
      )}
    </AuthProvider>
  );
};

export default AppRouter;
