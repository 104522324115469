import React from "react";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import EmptyPage from "../../../components/share/EmptyPage";

const AnalysisPresenter = () => {
  return (
    <>
      <GuideLine title={["매출", "분석"]} />
      <EmptyPage />
    </>
  );
};

export default AnalysisPresenter;
