import React, { useState, useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledInput from "../../../share/StyledInput";
import StyledSelectDate from "../../../share/StyledSelectDate";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import {
  CREATE_EVENT,
  EDIT_EVENT,
} from "../../../../graphql/Management/Event/mutation";
import { EventContext } from "../../../../routes/Management/Event/EventContainer";
import InputFiles from "./InputFiles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddModal = ({ EDIT }) => {
  const { eventList, refetch, handleAddModal, handleEditModal, clicked } =
    useContext(EventContext);
  const [createEvent] = useMutation(CREATE_EVENT);
  const [editEvent] = useMutation(EDIT_EVENT);
  const [imageFile, setImageFile] = useState("");
  const [inputs, setInputs] = useState({
    name: "",
    startDate: "",
    endDate: "",
    imgFile: "",
  });

  const onChangeInput = (name, value) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onChangeDate = (type, date) => {
    if (type === "endDate") {
      if (inputs.startDate === "") {
        toast.error("시작일 먼저 지정해주세요.");
        return;
      }
      if (new Date(inputs.startDate) > date) {
        toast.error("시작일 이후로 지정해주세요");
        return;
      }
    }
    setInputs({
      ...inputs,
      [type]: String(date),
    });
  };

  const onChangeFile = async (e) => {
    const files = e.target.files;
    if (files.length < 1) return;
    console.log("뭐야", files[0]);
    setImageFile(files[0]);
  };

  const onClickSave = async () => {
    if (!EDIT) {
      //이벤트 생성
      try {
        const { data } = await createEvent({
          variables: {
            evnt_title: inputs.name,
            evnt_start: inputs.startDate,
            evnt_end: inputs.endDate,
            evnt_img: imageFile,
          },
        });
        if (data.createEvent.result) {
          toast.success("이벤트를 추가하였습니다.");
          await refetch();
          handleAddModal();
        }
      } catch (e) {
        toast.error(e.message);
      }
    } else {
      //이벤트 수정
      let variables;
      if (typeof imageFile === "string") {
        //이미지 변경 x
        variables = {
          evntId: eventList[clicked].evnt_id,
          evntTitle: inputs.name,
          evntStart: inputs.startDate,
          evntEnd: inputs.endDate,
          evntImgEdit: false,
        };
      } else {
        // 이미지 변경 o
        variables = {
          evntId: eventList[clicked].evnt_id,
          evntTitle: inputs.name,
          evntStart: inputs.startDate,
          evntEnd: inputs.endDate,
          evntImgEdit: true,
          evntImg: imageFile,
        };
      }
      try {
        const { data } = await editEvent({
          variables: variables,
        });
        console.log(data);
        if (data.editEvent.result) {
          toast.success("이벤트를 수정하였습니다.");
          await refetch();
          handleEditModal();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    if (EDIT) {
      setInputs({
        name: eventList[clicked].evnt_title,
        startDate: new Date(Number(eventList[clicked].evnt_start)),
        endDate: new Date(Number(eventList[clicked].evnt_end)),
        imgFile: eventList[clicked].evnt_img,
      });
      setImageFile(eventList[clicked].evnt_img);
    }
  }, []);

  return (
    <StyledModal
      BOTTOMBTN
      width={619}
      height={516}
      title={EDIT ? "이벤트 수정" : "이벤트 등록"}
      onClickClose={EDIT ? handleEditModal : handleAddModal}
      onClickSave={onClickSave}
    >
      <Container>
        <StyledInput
          label="이벤트명*"
          width={535}
          placeholder="이벤트명을 입력해주세요"
          maxLength={30}
          value={inputs.name}
          onChange={(e) => onChangeInput("name", e.target.value)}
        />
        <StyledSelectDate
          label="이벤트 기간"
          margin="25px 0"
          startDate={inputs.startDate}
          endDate={inputs.endDate}
          onChangeDate={onChangeDate}
        />
        <InputFiles
          filename={
            typeof imageFile === "string" && imageFile.length > 4
              ? imageFile.substring(0, 4) === "http" && imageFile.substring(43)
              : imageFile.name
          }
          onChange={onChangeFile}
        />
      </Container>
    </StyledModal>
  );
};

export default AddModal;
