import React, { useEffect, useState, useContext } from "react";
import HomePresenter from "./HomePresenter";
import { useQuery } from "@apollo/client";
import {
  SEE_SIGNUP_STATISTICS,
  SEE_USER_SUBS_TYPE,
  SEE_MONTHLY_PAYMENT_STATISTICS,
  SEE_QR_GEN_INFO,
  SEE_LINK_CLICK_STATISTICS,
  SEE_NOTICE_HOME,
  PLATFORM_USED_INFO,
} from "../../graphql/Home/query";
import { AuthContext } from "../../context/AuthProvider";

const HomeContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [qrList, setQrList] = useState([]);
  const [signupList, setSignupList] = useState([]);
  const [subsList, setSubsList] = useState([
    { name: "연간구독", value: 1 },
    { name: "일반", value: 1 },
    { name: "월간구독", value: 1 },
  ]);
  const [paymentList, setPaymentList] = useState([]);
  const [clickList, setClickList] = useState([]);
  const [noticeList, setNoticeList] = useState([
    { ntc_text: "A", ntc_createdAt: "22.08.25" },
  ]);
  const [countList, setCountList] = useState({
    totalUserCount: 0,
    premiumUser: 0,
    monthlySubs: 0,
    annualSubs: 0,
    monthlyIncome: 0,
    annualIncome: 0,
  });

  const {
    data: signupData,
    refetch: signupRefetch,
    loading: signupLoading,
  } = useQuery(SEE_SIGNUP_STATISTICS, { variables: {} });

  const {
    data: subsData,
    refetch: subsRefetch,
    loading: subsLoading,
  } = useQuery(SEE_USER_SUBS_TYPE, { variables: {} });

  const {
    data: paymentData,
    refetch: paymentRefetch,
    loading: paymentLoading,
  } = useQuery(SEE_MONTHLY_PAYMENT_STATISTICS, { variables: {} });

  const {
    data: qrData,
    refetch: qrRefetch,
    loading: qrLoading,
  } = useQuery(SEE_QR_GEN_INFO, { variables: {} });

  const {
    data: clickData,
    refetch: clickRefetch,
    loading: clickLoading,
  } = useQuery(SEE_LINK_CLICK_STATISTICS, { variables: {} });

  const {
    data: noticeData,
    refetch: noticeRefetch,
    loading: noticeLoading,
  } = useQuery(SEE_NOTICE_HOME, { variables: {} });

  const {
    data: countData,
    refetch: countRefetch,
    loading: countLoading,
  } = useQuery(PLATFORM_USED_INFO, { variables: {} });

  useEffect(() => {
    if (qrData) {
      if (!qrData.seeQRGenInfo.tokenExpired) {
        //토큰 만료시
        userLogout();
      }
      setQrList(qrData.seeQRGenInfo.QRGenInfo);
    }

    if (signupData) {
      console.log(signupData);
      setSignupList(signupData.seeSingUpStatistics.singInStatistics);
    }

    if (subsData) {
      setSubsList([
        { name: "연간구독", value: subsData.seeUserSubsType.annual },
        { name: "일반", value: subsData.seeUserSubsType.general },
        { name: "월간구독", value: subsData.seeUserSubsType.monthly },
      ]);
    }
    if (paymentData) {
      setPaymentList(paymentData.seeMonthlyPaymentStatistics.monthlyStatistics);
    }
    if (clickData) {
      setClickList(clickData.seeLinkClickStatistics.clickStatistics);
    }
    if (noticeData) {
      setNoticeList(noticeData.seeNoticeHome.homeNoticeInfo);
    }

    if (countData) {
      setCountList(countData.platformUsedInfo);
    }
  }, [qrData, signupData, subsData, paymentData, clickData, noticeData]);

  return (
    <HomePresenter
      countList={countList}
      qrList={qrList}
      signupList={signupList}
      subsList={subsList}
      paymentList={paymentList}
      clickList={clickList}
      noticeList={noticeList}
    />
  );
};

export default HomeContainer;
