import React, { useContext, useEffect, useState } from "react";
import SalesStatsPresenter from "./SalesStatsPresenter";
import { useMutation, useQuery } from "@apollo/client";
import { SEE_PAYMENT_STATISTICS } from "../../../graphql/Sales/SalesStats/query";
import { CREATE_PAYMENT_STATISTICS_EXCEL } from "../../../graphql/Sales/SalesStats/mutation";
import { toast } from "react-toastify";
import { sortChanger } from "../../../lib/salesUtils";
import { AuthContext } from "../../../context/AuthProvider";

const SalesStatsContainer = () => {
  const { userLogout } = useContext(AuthContext);

  const [inputs, setInputs] = useState({
    sort: "전체",
  });

  const [monthlyList, setMonthlyList] = useState([
    { month: 1, paymentAmount: 0 },
    { month: 2, paymentAmount: 0 },
    { month: 3, paymentAmount: 0 },
    { month: 4, paymentAmount: 0 },
    { month: 5, paymentAmount: 0 },
    { month: 6, paymentAmount: 0 },
    { month: 7, paymentAmount: 0 },
    { month: 8, paymentAmount: 0 },
    { month: 9, paymentAmount: 0 },
    { month: 10, paymentAmount: 0 },
    { month: 11, paymentAmount: 0 },
    { month: 12, paymentAmount: 0 },
  ]);
  const [average, setAverage] = useState(0);

  const [createPaymentStatisticsExcel] = useMutation(
    CREATE_PAYMENT_STATISTICS_EXCEL
  );

  const {
    data: statData,
    loading,
    refetch,
  } = useQuery(SEE_PAYMENT_STATISTICS, {
    variables: {
      statisticsFilter: sortChanger(inputs.sort),
    },
  });

  useEffect(() => {
    if (statData) {
      if (!statData.seePaymentStatistics.tokenExpired) {
        userLogout();
      }
      setAverage(statData.seePaymentStatistics.avgPaymentAmount);
      setMonthlyList(statData.seePaymentStatistics.monthlyInfoList);
    }
  }, [statData]);

  const handleReset = () => {
    setInputs({
      sort: "전체",
    });
  };

  const handleExcelButton = async () => {
    try {
      const { data } = await createPaymentStatisticsExcel({
        variables: {
          statisticsFilter: sortChanger(inputs.sort),
        },
      });
      if (data.createPaymentStatisticsExcel.excel) {
        window.open(data.createPaymentStatisticsExcel.excel);
        toast.success("엑셀 파일을 다운로드 하였습니다.");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <SalesStatsPresenter
      inputs={inputs}
      setInputs={setInputs}
      handleReset={handleReset}
      handleExcelButton={handleExcelButton}
      monthlyList={monthlyList}
      average={average}
    />
  );
};

export default SalesStatsContainer;
