import React, {
  useEffect,
  useState,
  createContext,
  useCallback,
  useContext,
} from "react";
import QnaPresenter from "./QnaPresenter";
import { useQuery } from "@apollo/client";
import { SEE_ONE_ON_ONE_LIST } from "../../../graphql/Management/Qna/query";
import { qnaSortChanger } from "../../../lib/managementUtils";
import { AuthContext } from "../../../context/AuthProvider";

export const QnaContext = createContext();

const QnaContainer = () => {
  const { userLogout } = useContext(AuthContext);

  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "작성일",
  });
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [qnaList, setQnaList] = useState([]);

  const { data, refetch, loading } = useQuery(SEE_ONE_ON_ONE_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      oneOnOneSort: qnaSortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  useEffect(() => {
    if (data) {
      if (!data.seeOneOnOneList.tokenExpired) {
        userLogout();
      }
      setQnaList(data.seeOneOnOneList.oneOneOneList);
      setTotalLength(data.seeOneOnOneList.totalLength);
    }
  }, [data]);

  const handleReset = () => {
    setInputs({
      searchTerm: "",
      sort: "최신순",
    });
  };

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <QnaContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        refetch,
        qnaList,
        totalLength,
        page,
        onClickPage,
      }}
    >
      <QnaPresenter />
    </QnaContext.Provider>
  );
};

export default QnaContainer;
