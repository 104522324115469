import React, { useCallback } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import { useQuery } from "@apollo/client";
import { SEE_TOTAL_CALL_TIME } from "../../../graphql/Home/query";

const Container = styled.div`
  width: 100%;
  min-width: 1692px;
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 16px;
  background-color: ${colors.whiteColor};
`;
const LabelText = styled.div`
  font-size: 14px;
  color: ${colors.lightGrayFontColor};
`;

const CountText = styled.div`
  font-size: 16px;
  margin: 0 42px 0 11px;
  color: ${colors.darkGrayFontColor};
`;

const HeaderCount = ({ countList }) => {
  const { data: totalCallTimeData } = useQuery(SEE_TOTAL_CALL_TIME, {
    variables: {},
  });

  const changeTime = useCallback((time) => {
    let hour = Math.floor(time / 3600);
    let min = Math.floor((time % 3600) / 60);
    let sec = Math.floor(time % 60);
    return `${hour}시간 ${min}분 ${sec}초`;
  }, []);

  return (
    <Container>
      <LabelText>총 통화량 :</LabelText>
      <CountText>
        {changeTime(totalCallTimeData?.seeTotalCallTime?.totalCallTime)}
      </CountText>
      <LabelText>전체 유저 수 :</LabelText>
      <CountText>{countList.totalUserCount.toLocaleString()}명</CountText>
      <LabelText>프리미엄 회원 :</LabelText>
      <CountText>{countList.premiumUser.toLocaleString()}명</CountText>
      <LabelText>월간 구독자 :</LabelText>
      <CountText>{countList.monthlySubs.toLocaleString()}명</CountText>
      <LabelText>연간 구독자 :</LabelText>
      <CountText>{countList.annualSubs.toLocaleString()}명</CountText>
      <LabelText>월간 구독 수입 :</LabelText>
      <CountText>{countList.monthlyIncome.toLocaleString()}원</CountText>
      <LabelText>연간 구독 수입 :</LabelText>
      <CountText>{countList.annualIncome.toLocaleString()}원</CountText>
    </Container>
  );
};

export default React.memo(HeaderCount);
