export const permisstionToString = (list) => {
  return list.join(" / ");
};

export const qrTypeChanger = (value) => {
  switch (value) {
    case "차량용":
      return "car";
    case "사무실용":
      return "office";
    case "car":
      return "차량용 QR";
    case "office":
      return "사무실용 QR";
  }
};

export const qrSortChanger = (value) => {
  switch (value) {
    case "최신순":
      return "createdAt";
    case "분류":
      return "qrType";
  }
};

export const paymentTypeChanger = (value) => {
  switch (value) {
    case "프리미엄":
      return "premium";
    case "베이직":
      return "basic";
  }
};
