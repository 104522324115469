import React, { memo } from "react";
import styled from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledInput from "../../../share/StyledInput";
import { colors } from "../../../../styles/colors";
import { dateFormat } from "../../../../utils/commons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Text = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const Image = styled.img`
  width: 535px;
  height: 684px;
  border-radius: 8px;
`;

const Label = styled.div`
  margin: 25px 0 12px 0;
  color: ${colors.labelGrayFontColor};
`;

const DetailModal = ({ handleDetailModal, data }) => {
  return (
    <StyledModal
      width={615}
      height={944}
      title="이벤트 내용"
      onClickClose={handleDetailModal}
    >
      <Container>
        <Text>
          날짜 : {dateFormat(new Date(Number(data.evnt_start)), "yy.MM.dd")} ~{" "}
          {dateFormat(new Date(Number(data.evnt_end)), "yy.MM.dd")}
        </Text>
        <StyledInput
          READONLY
          label="이벤트명*"
          width={535}
          value={data.evnt_title}
        />
        <Label>이벤트 이미지 파일</Label>
        <Image src={data.evnt_img} />
      </Container>
    </StyledModal>
  );
};

export default memo(DetailModal);
