import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import { colors } from "../../../styles/colors";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import RowTable from "../../../components/feature/Product/ProductList/RowTable";
import PageNation from "../../../components/layout/PageNation";
import AddModal from "../../../components/feature/Product/ProductList/AddModal";
import { ProductListContext } from "./ProductListContainer";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const ProductListPresenter = () => {
  const {
    inputs,
    setInputs,
    handleAddModal,
    handleEditModal,
    handleReset,
    setClicked,
    onClickPage,
    page,
    totalLength,
    handleMinus,
    productEditModal,
    productAddModal,
  } = useContext(ProductListContext);
  return (
    <>
      <GuideLine title={["상품", "상품목록"]} />
      <Container>
        <SortingForm
          SEARCH
          RESET
          PRODUCT
          PLUS
          MINUS
          EDIT
          placeholder="제목, 링크로 검색"
          inputs={inputs}
          setInputs={setInputs}
          handlePlus={handleAddModal}
          handleEdit={handleEditModal}
          handleMinus={handleMinus}
          handleReset={handleReset}
        />
        <StyledRowTable
          RADIO
          handleRadio={() => setClicked(null)}
          label={[
            { text: "상품등록일", width: 130 },
            { text: "사진", width: 88 },
            { text: "제목", width: 202 },
            { text: "지난달 클릭수", width: 146 },
            { text: "누적 클릭수", width: 142 },
            { text: "링크", width: 580 },
            { text: "연동 금액", width: 148 },
            { text: "연동 리뷰", width: 118 },
          ]}
        />
        <RowTable />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </Container>
      {productEditModal && <AddModal EDIT />}
      {productAddModal && <AddModal />}
    </>
  );
};

export default ProductListPresenter;
