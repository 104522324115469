import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import { colors } from "../../../styles/colors";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/Management/Term/RowTable";
import AddModal from "../../../components/feature/Management/Term/AddModal";
import { TermContext } from "./TermContainer";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const TermPresenter = () => {
  const {
    inputs,
    setInputs,
    handleReset,
    page,
    onClickPage,
    totalLength,
    termAddModal,
    handleAddModal,
    handleMinus,
    setClicked,
  } = useContext(TermContext);

  return (
    <>
      <GuideLine title={["운영", "이용방침"]} />
      <Container>
        <SortingForm
          SEARCH
          RESET
          TERM
          PLUS
          MINUS
          placeholder="이용방침 명으로 검색"
          inputs={inputs}
          setInputs={setInputs}
          handleMinus={handleMinus}
          handlePlus={handleAddModal}
          handleReset={handleReset}
        />
        <StyledRowTable
          RADIO
          handleRadio={() => setClicked(null)}
          label={[
            { text: "등록일", width: 130 },
            { text: "이용방침 명", width: 324 },
            { text: "이용방침 내용", width: 1018 },
            { text: "등록자", width: 100 },
          ]}
        />
        <RowTable />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </Container>
      {termAddModal && <AddModal handleAddModal={handleAddModal} />}
    </>
  );
};
export default TermPresenter;
