import React, { memo } from "react";
import styled from "styled-components";
import { css } from "styled-components";
import { gendarChanger } from "../../../../lib/userUtils";
import { colors } from "../../../../styles/colors";
import { autoHyphen } from "../../../../utils/commons";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  color: ${colors.blackColor};
  ${({ DEL }) =>
    DEL &&
    css`
      color: ${colors.grayDelBtnColor};
      margin-left: auto;
      margin-right: 50px;
      cursor: pointer;
    `}
`;

const RowTable = ({ withdrawalList, handleDeleteUser }) => {
  return (
    <Container>
      {withdrawalList.map((data, index) => (
        <RowBox key={index}>
          <Text width={130}>{data.user_withdrawal_Date}</Text>
          <Text width={158}>{data.user_name}</Text>
          <Text width={120}>{data.user_birthday}</Text>
          <Text width={90}>{gendarChanger(data.user_gender)}</Text>
          <Text width={176}>{autoHyphen(data.user_cellphone)}</Text>
          <Text width={176}>
            {data.user_info_endDate} (D-{data.user_info_endDate_dateNumber}
            일전)
          </Text>
          <Text DEL onClick={() => handleDeleteUser(data.user_id)}>
            삭제
          </Text>
        </RowBox>
      ))}
    </Container>
  );
};

export default memo(RowTable);
