import React, {memo} from "react";
import styled, {css} from "styled-components";
import {colors} from "../../styles/colors";

const Container = styled.div`
  width: ${({width}) => width}px;
  display: flex;
  flex-direction: column;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: ${({height}) => height}px;
  padding: 20px;
  line-height: 18px;
  border-radius: 8px;
  font-size: 16px;
  font-family: "SF Pro Display", sans-serif;
  border: 1px solid ${colors.lightGrayBorderColorr};

  &::placeholder {
    color: ${colors.lightGrayBorderColor};
  }

  &:focus {
    border: 1px solid ${colors.grayBorderColor};
  }

  ${({readOnly}) => readOnly && css`
    &:focus {
      border: 1px solid ${colors.lightGrayBorderColorr};
    }
  `};
`;

const Label = styled.div`
  color: ${colors.labelGrayFontColor};
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const StyledTextarea = ({
                            READONLY,
                            width,
                            height,
                            label,
                            name,
                            value,
                            onChange,
                            placeholder,
                            maxLength,
                        }) => {
    return (
        <Container width={width}>
            <RowBox>
                <Label>{label}</Label>
                {maxLength && (
                    <Label>
                        ( {value.length} / {maxLength})
                    </Label>
                )}
            </RowBox>
            <TextArea
                height={height}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                maxLength={maxLength}
                readOnly={READONLY && true}
            />
        </Container>
    );
};

export default memo(StyledTextarea);
