import React, { useState, useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import StyledCheckbox from "../../../share/StyledCheckbox";
import { colors } from "../../../../styles/colors";
import closeIcon from "../../../../assets/closeIcon.svg";
import StyledButton from "../../../share/StyledButton";
import { useMutation } from "@apollo/client";
import { EDIT_PERMISSION } from "../../../../graphql/System/User/mutation";
import { SystemContext } from "../../../../routes/System/User/UserContainer";
import { toast } from "react-toastify";

const DarkBackground = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 10;
`;

const ModalContainer = styled.div`
  width: 309px;
  height: 625px;
  display: flex;
  flex-direction: column;
  padding: 44px 40px 44px 40px;
  margin-left: 1290px;
  margin-top: ${({ index }) => `calc(208px + 46px * ${index})`};
  border-radius: 19px;
  background-color: ${colors.whiteColor};
  position: relative;
  z-index: 20;
`;

const Title = styled.div`
  font-weight: 400;
`;

const CloseIcon = styled.img`
  width: 18px;
  cursor: pointer;
`;

const BottomButtons = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  margin: ${({ margin }) => margin};
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
const Text = styled.div`
  margin: ${({ margin }) => margin};
  color: ${({ gray }) =>
    gray ? `${colors.labelGrayFontColor}` : `${colors.whiteColor}`};
`;

const RowBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const BlackSection = styled.div`
  width: 310px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  margin-left: -41px;
  color: ${colors.whiteColor};
  background-color: ${colors.blackColor};
`;

const PermissionModal = ({ handleModal, index }) => {
  const { refetch, adminList } = useContext(SystemContext);

  const [editPermission] = useMutation(EDIT_PERMISSION);

  const [permission, setPermission] = useState({
    home: adminList[index].permission.per_home,
    user: adminList[index].permission.per_user,
    product: adminList[index].permission.per_product,
    coupon: adminList[index].permission.per_coupon,
    sales: adminList[index].permission.per_salesInfo,
    management: adminList[index].permission.per_management,
  });

  const onChangePermission = useCallback(
    (name, value) => {
      setPermission({
        ...permission,
        [name]: value,
      });
    },
    [permission]
  );

  const onClickSave = useCallback(async () => {
    try {
      const { data } = await editPermission({
        variables: {
          admin_id: adminList[index].admin_id,
          per_home: permission.home,
          per_user: permission.user,
          per_product: permission.product,
          per_coupon: permission.coupon,
          per_salesInfo: permission.sales,
          per_management: permission.management,
          per_system: adminList[index].admin_master,
        },
      });
      if (data.editPermission.result) {
        await refetch();
        handleModal();
        toast.success("권한을 수정하였습니다.");
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [permission]);

  return (
    <DarkBackground>
      <ModalContainer index={index <= 5 ? index : 5}>
        <BottomButtons justifyContent="space-between" margin="0 0 25px 0">
          <Title>권한수정</Title>
          <CloseIcon src={closeIcon} onClick={handleModal} />
        </BottomButtons>
        <Container>
          <BlackSection>
            {adminList[index].admin_name} {adminList[index].admin_rank}
          </BlackSection>
          <Text gray margin="20px 0 12px 0">
            권한선택
          </Text>
          <RowBox onClick={() => onChangePermission("home", !permission.home)}>
            <StyledCheckbox clicked={permission.home} />
            <Text gray>홈</Text>
          </RowBox>
          <RowBox onClick={() => onChangePermission("user", !permission.user)}>
            <StyledCheckbox clicked={permission.user} />
            <Text gray>유저</Text>
          </RowBox>
          <RowBox
            onClick={() => onChangePermission("product", !permission.product)}
          >
            <StyledCheckbox clicked={permission.product} />
            <Text gray>상품</Text>
          </RowBox>
          <RowBox
            onClick={() => onChangePermission("coupon", !permission.coupon)}
          >
            <StyledCheckbox clicked={permission.coupon} />
            <Text gray>쿠폰</Text>
          </RowBox>
          <RowBox
            onClick={() => onChangePermission("sales", !permission.sales)}
          >
            <StyledCheckbox clicked={permission.sales} />
            <Text gray>매출</Text>
          </RowBox>
          <RowBox
            onClick={() =>
              onChangePermission("management", !permission.management)
            }
          >
            <StyledCheckbox clicked={permission.management} />
            <Text gray>운영</Text>
          </RowBox>
        </Container>
        <BottomButtons justifyContent="flex-end" margin="auto 0 0 0">
          <StyledButton
            label="취소"
            margin="0 12px 0 0"
            onClick={handleModal}
          />
          <StyledButton BLACK label="저장" onClick={onClickSave} />
        </BottomButtons>
      </ModalContainer>
    </DarkBackground>
  );
};

export default PermissionModal;
