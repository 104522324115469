import React, { memo, useContext } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../styles/colors";
import rightArrow from "../../../assets/rightArrow.png";
import { AuthContext } from "../../../context/AuthProvider";

const Container = styled.div`
  width: 100%;
  min-width: 1692px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 8px;
  background-color: ${colors.whiteColor};
`;

const RowBox = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  color: ${colors.grayFontColor};
  ${({ logout }) =>
    logout &&
    css`
      margin-left: auto;
      cursor: pointer;
    `}
`;

const ArrowImage = styled.img`
  height: 10px;
  margin: 0 12px;
`;

const GuideLine = ({ title = [] }) => {
  const { userLogout } = useContext(AuthContext);

  return (
    <Container>
      {title.map((title, index) => (
        <RowBox key={index}>
          {index !== 0 && <ArrowImage src={rightArrow} />}
          <Text>{title}</Text>
        </RowBox>
      ))}

      <Text logout onClick={userLogout}>
        로그아웃
      </Text>
    </Container>
  );
};

export default memo(GuideLine);
