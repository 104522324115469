import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const AuthContext = createContext();

const AuthProvider = ({ isLoggedIn: isLoggedInProp, children }) => {
  const navigate = useNavigate();

  const userLogin = async (token) => {
    try {
      localStorage.setItem("qr_admin_token", token);
      navigate("/");
      setTimeout(() => {
        window.location.reload();
      }, 600);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const userLogout = async () => {
    try {
      localStorage.removeItem("qr_admin_token");
      navigate("/");
      setTimeout(() => {
        window.location.reload();
      }, 600);
    } catch (e) {
    } finally {
      localStorage.clear();
    }
  };

  return (
    <AuthContext.Provider value={{ userLogin, userLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
