import { gql } from "@apollo/client";

export const CREATE_PRODUCT = gql`
  mutation CreateProduct(
    $prdName: String!
    $prdLink: String!
    $imgSelect: Boolean
    $images: [Upload]
  ) {
    createProduct(
      prd_name: $prdName
      prd_link: $prdLink
      imgSelect: $imgSelect
      images: $images
    ) {
      result
      tokenExpired
    }
  }
`;

export const EDIT_PRODUCT = gql`
  mutation EditProduct(
    $prdId: Int!
    $prdName: String
    $prdLink: String
    $imgSelect: Boolean
    $images: [Upload]
  ) {
    editProduct(
      prd_id: $prdId
      prd_name: $prdName
      prd_link: $prdLink
      imgSelect: $imgSelect
      images: $images
    ) {
      result
      tokenExpired
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($prd_id: Int!) {
    deleteProduct(prd_id: $prd_id) {
      tokenExpired
      result
    }
  }
`;
