import React, { memo } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import RowTable from "../../../components/feature/User/UnRegister/RowTable";
import PageNation from "../../../components/layout/PageNation";
import { colors } from "../../../styles/colors";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const UnRegisterPresenter = ({
  inputs,
  setInputs,
  handleReset,
  handleDeleteUser,
  withdrawalList,
  totalLength,
  onClickPage,
  page,
}) => {
  return (
    <>
      <GuideLine title={["유지관리", "탈퇴한 회원"]} />
      <Container>
        <SortingForm
          SEARCH
          RESET
          SORT
          label="탈퇴일자 정렬"
          inputs={inputs}
          setInputs={setInputs}
          handleReset={handleReset}
        />
        <StyledRowTable
          label={[
            { text: "탈퇴일", width: 130 },
            { text: "이름", width: 158 },
            { text: "생년월일", width: 120 },
            { text: "성별", width: 90 },
            { text: "휴대폰번호", width: 176 },
            { text: "정보저장만료일", width: 176 },
          ]}
        />
        <RowTable
          handleDeleteUser={handleDeleteUser}
          withdrawalList={withdrawalList}
        />
        <PageNation
          totalLength={totalLength}
          onClickPage={onClickPage}
          page={page}
        />
      </Container>
    </>
  );
};

export default memo(UnRegisterPresenter);
