import React, {useState, useContext, useCallback, useEffect} from "react";
import styled from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledInput from "../../../share/StyledInput";
import StyledTextarea from "../../../share/StyledTextarea";
import {autoHyphen} from "../../../../utils/commons";
import {colors} from "../../../../styles/colors";
import {useMutation} from "@apollo/client";
import {CREATE_ONE_ON_ONE_ANSWER} from "../../../../graphql/Management/Qna/mutation";
import {QnaContext} from "../../../../routes/Management/Qna/QnaContainer";
import {toast} from "react-toastify";
import StyledButton from "../../../share/StyledButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  color: ${({gray}) => gray && `${colors.labelGrayFontColor}`};
  margin: ${({margin}) => margin};
`;

const RowBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const ButtonBox = styled.div`
  text-align: right;
  margin-top: 25px;
`;

const AddModal = ({clicked, selectedQna, handleModal, setClicked, setSelectedQna}) => {
    const {qnaList, refetch} = useContext(QnaContext);

    const [answer, setAnswer] = useState("");

    const [createOneOnOneAnswer] = useMutation(CREATE_ONE_ON_ONE_ANSWER);

    const onClickSave = async () => {
        try {
            const {data} = await createOneOnOneAnswer({
                variables: {
                    one_id: qnaList[clicked].one_id,
                    one_answer: answer,
                },
            });
            if (data.createOneOnOneAnswer.result) {
                handleModal();
                toast.success("답변을 저장했습니다.");
                await refetch();
                setClicked(null);
            }
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        if (selectedQna !== null) {
            setAnswer(selectedQna.one_answer)
        }

        return () => setSelectedQna(null);
    }, [selectedQna, setSelectedQna]);

    return (
        <StyledModal
            BOTTOMBTN={!selectedQna.one_answer_status}
            width={824}
            height={742}
            title="일대일 답변하기"
            onClickClose={handleModal}
            onClickSave={onClickSave}
        >
            <Container>
                <Text gray margin="0 0 15px 0">
                    작성자 정보
                </Text>
                <Text margin="0 0 25px 0">
                    {qnaList[clicked].one_user_name} ({" "}
                    {autoHyphen(qnaList[clicked].one_user_cellphone)} )
                </Text>
                <RowBox>
                    <StyledTextarea
                        READONLY
                        label="질문 내용"
                        width={345}
                        height={396}
                        value={qnaList[clicked].one_question}
                    />
                    <StyledTextarea
                        READONLY={selectedQna.one_answer_status}
                        label="답변"
                        width={345}
                        height={396}
                        placeholder="답변을 입력해주세요"
                        maxLength={500}
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                    />
                </RowBox>
                {selectedQna.one_answer_status && (
                    <ButtonBox>
                        <StyledButton
                            BLACK
                            label="닫기"
                            onClick={handleModal}
                        />
                    </ButtonBox>
                )}
            </Container>
        </StyledModal>
    );
};

export default AddModal;
