import React, { useContext } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import StyledRadioButton from "../../../share/StyledRadioButton";
import { dateFormat } from "../../../../utils/commons";
import { TermContext } from "../../../../routes/Management/Term/TermContainer";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;
const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};

  &:hover {
    background-color: ${colors.defaultBackgroundColor};
  }
`;
const Contents = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
`;

const RowTable = () => {
  const { termList, clicked, handleRadio, onClickDetail } = useContext(TermContext);

  return (
    <Container>
      {termList.map((data, index) => (
        <RowBox key={index}>
          <StyledRadioButton
            clicked={clicked === index}
            onClick={() => handleRadio(index)}
          />
          <Contents onClick={() => onClickDetail(data.tos_id)}>
            <Text width={130}>
              {dateFormat(new Date(Number(data.tos_createdAt)), "yy.MM.dd")}
            </Text>
            <Text width={324}>{data.tos_title}</Text>
            <Text width={1018}>
              {data.tos_text.length > 70
                ? data.tos_text.substring(0, 70) + "..."
                : data.tos_text}
            </Text>
            <Text width={100}>{data.tos_admin_name}</Text>
          </Contents>
        </RowBox>
      ))}
    </Container>
  );
};

export default RowTable;
