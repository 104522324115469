import { gql } from "@apollo/client";

export const SEE_NOTICE_LIST = gql`
  query seeNoticeList(
    $searchTerm: String
    $sort: noticeEventSort
    $take: Int
    $cursor: Int
  ) {
    seeNoticeList(
      searchTerm: $searchTerm
      sort: $sort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      noticeList {
        ntc_id
        ntc_createdAt
        ntc_title
        ntc_text
        ntc_status
      }
    }
  }
`;
