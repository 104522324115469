import React, { useState, useContext, useCallback } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import StyledRadioButton from "../../../share/StyledRadioButton";
import PermissionModal from "./PermissionModal";
import { SystemContext } from "../../../../routes/System/User/UserContainer";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  cursor: ${({ POINTER }) => POINTER && "pointer"};
`;

const Button = styled.button`
  width: 134px;
  height: 39px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  white-space: nowrap;
  border: 1px solid ${colors.blackColor};
  background-color: ${colors.whiteColor};
`;

const RowTable = () => {
  const { adminList, clicked, handleRadio, resetPasswd } =
    useContext(SystemContext);

  const [permissionModal, setPermissionModal] = useState(false);
  const [clickId, setClickId] = useState(null);

  const handleModal = useCallback(() => {
    setPermissionModal(!permissionModal);
  }, [permissionModal]);

  const permissionChange = useCallback((permissionList) => {
    let list = [];
    if (permissionList.per_home) list.push("홈");
    if (permissionList.per_user) list.push("유저");
    if (permissionList.per_product) list.push("상품");
    if (permissionList.per_coupon) list.push("쿠폰");
    if (permissionList.per_salesInfo) list.push("매출");
    if (permissionList.per_management) list.push("운영");
    // if (permissionList.per_system) list.push("시스템관리");

    return list.join(" / ");
  }, []);

  return (
    <>
      <Container>
        {adminList.map((data, index) => (
          <RowBox key={index}>
            <StyledRadioButton
              clicked={clicked === index}
              onClick={() => handleRadio(index)}
            />
            <Text width={100}>
              {data.admin_team.length > 7
                ? data.admin_team.substring(0, 6) + "..."
                : data.admin_team}
            </Text>
            <Text width={122}>
              {data.admin_name.length > 7
                ? data.admin_name.substring(0, 7) + "..."
                : data.admin_name}
            </Text>
            <Text width={148}>
              {data.admin_rank.length > 9
                ? data.admin_rank.substring(0, 8) + "..."
                : data.admin_rank}
            </Text>
            <Text width={168}>{data.admin_accountId}</Text>
            <Text width={802}>{permissionChange(data.permission)}</Text>
            <Text
              width={116}
              POINTER
              onClick={() => {
                setClickId(index);
                handleModal();
              }}
            >
              수정
            </Text>
            <Button onClick={() => resetPasswd(data.admin_id)}>
              비밀번호 초기화
            </Button>
          </RowBox>
        ))}
      </Container>
      {permissionModal && (
        <PermissionModal index={clickId} handleModal={handleModal} />
      )}
    </>
  );
};

export default RowTable;
