import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../styles/colors";

const InputWrapper = styled.div`
  width: 100%;
  height: 48px;
  position: relative;
  margin: 15px 0;
`;
const InputTitle = styled.span`
  position: absolute;
  top: 16px;
  left: 20px;
  font-size: 17px;
  transition: 0.3s;
  color: ${colors.grayBorderColor};
  background-color: ${colors.whiteColor};
  padding: 0 5px;
`;
const InputBox = styled.input`
  all: unset;
  width: 100%;
  height: 100%;
  font-size: 17px;
  padding-left: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 0.5px solid ${colors.blackColor};
  &:focus {
    border: 1px solid ${colors.grayBorderColor};
  }
  &:focus ~ span,
  &:valid ~ span {
    top: -5px;
    font-size: 14px;
  }
`;

function TextField({
  name,
  title,
  value,
  onChange = () => {},
  onKeyPress = () => {},
}) {
  return (
    <>
      <InputWrapper>
        <InputBox
          name={name}
          value={value}
          type={name === "pw" || name === "pwCheck" ? "password" : ""}
          onChange={(e) => onChange(e)}
          onKeyPress={(e) => onKeyPress(e)}
          required
        />
        <InputTitle>{title}</InputTitle>
      </InputWrapper>
    </>
  );
}

export default TextField;
