export const noticeSortChanger = (value) => {
  switch (value) {
    case "최신순":
      return "createdAt";
    case "미노출":
      return "status";
    case "공지명":
      return "title";
  }
};

export const faqSortChanger = (value) => {
  switch (value) {
    case "최신순":
      return "createdAt";
    case "작성자":
      return "admin_name";
  }
};

export const qnaSortChanger = (value) => {
  switch (value) {
    case "작성일":
      return "createdAt";
    case "답변":
      return "answer";
  }
};

export const termSortChanger = (value) => {
  switch (value) {
    case "약관명":
      return "title";
    case "등록일":
      return "createdAt";
  }
};
