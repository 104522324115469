import React, { useState, useCallback, useEffect, useContext } from "react";
import styled from "styled-components";
import StyledModal from "../../../../share/StyledModal";
import StyledInput from "../../../../share/StyledInput";
import StyledTextarea from "../../../../share/StyledTextarea";
import { colors } from "../../../../../styles/colors";
import { useMutation } from "@apollo/client";
import { CREATE_USER_ADVICE_DAILY_LOG } from "../../../../../graphql/User/State/Detail/mutation";
import { toast } from "react-toastify";
import { DetailContext } from "../../../../../routes/User/State/Detail/DetailContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
`;
const Text = styled.div`
  margin-top: 10px;
  color: ${colors.redColor};
`;

const AddModal = ({ handleModal }) => {
  const { userDetail, adviceLogRefetch } = useContext(DetailContext);

  const [createUserAdviceDailyLog] = useMutation(CREATE_USER_ADVICE_DAILY_LOG);

  const [inputs, setInputs] = useState({
    title: "",
    text: "",
  });
  const onChangeInput = useCallback(
    (name, value) => {
      setInputs({
        ...inputs,
        [name]: value,
      });
    },
    [inputs]
  );

  const onClickSave = async () => {
    try {
      const { data } = await createUserAdviceDailyLog({
        variables: {
          user_id: userDetail.user_id,
          title: inputs.title,
          text: inputs.text,
        },
      });
      console.log(data);
      if (data.createUserAdviceDailyLog.result) {
        toast.success("일지를 추가하였습니다.");
        handleModal();
        await adviceLogRefetch();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <StyledModal
      BOTTOMBTN
      width={619}
      height={752}
      title="일지 등록"
      onClickClose={handleModal}
      onClickSave={onClickSave}
    >
      <Container>
        <StyledInput
          label="제목"
          width={535}
          placeholder="일지명을 입력해주세요"
          maxLength={30}
          value={inputs.title}
          onChange={(e) => onChangeInput("title", e.target.value)}
        />
        <Margin />
        <StyledTextarea
          label="내용"
          width={535}
          height={396}
          maxLength={100}
          placeholder="내용을 입력해주세요"
          value={inputs.text}
          onChange={(e) => onChangeInput("text", e.target.value)}
        />
      </Container>
    </StyledModal>
  );
};

export default AddModal;
