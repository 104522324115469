import React, { memo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../styles/colors";
import arrowIcon from "../../assets/pagenation/arrowIcon.svg";
import doubleArrow from "../../assets/pagenation/doubleArrow.svg";
import downIcon from "../../assets/pagenation/downIcon.svg";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Text = styled.div`
  font-weight: 600;
  color: ${colors.labelGrayFontColor};
  margin-right: ${({ marginRight }) => marginRight && 12}px;
  margin: ${({ marginSide }) => marginSide && "0 10px"};
  ${({ optionText }) =>
    optionText &&
    css`
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 8px;
      background-color: ${colors.whiteColor};
      flex-shrink: 0;
    `}
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowBtn = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  border-radius: 6px;
  border: 1px solid ${colors.lightGrayBorderColor};
  cursor: pointer;
  :last-child {
    margin-right: 0;
  }
`;

const ArrowIcon = styled.img`
  height: 11px;
  transform: ${({ right }) => right && "rotate(180deg)"};
  ${({ down }) =>
    down &&
    css`
      width: 8px;
      height: auto;
    `}
`;

const SelectPage = styled.div`
  width: 62px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 5px 0 8px;
  margin-right: 5px;
  border-radius: 6px;
  border: 1px solid ${colors.lightGrayBorderColor};
  cursor: pointer;
`;

const OptionBox = styled.div`
  width: 62px;
  height: auto;
  max-height: 130px;
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow-y: auto;
  bottom: 24px;
  left: -1px;
  padding: 4px 0;
  border-radius: 6px;
  border: 1px solid ${colors.lightGrayBorderColor};
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    border-radius: 5px;
    background-color: ${colors.blackColor};
  }
`;

const PageNationtest = ({
  page = 1,
  take = 16,
  totalLength = 0,
  onClickPage = () => {},
}) => {
  const [options, setOptions] = useState([]);
  const [optionClick, setOptionClick] = useState(false);
  const lastPage = Math.ceil(totalLength / take);

  useEffect(() => {
    let optionsList = [];
    for (let i = 1; i <= lastPage; i++) {
      optionsList.push(i);
    }
    setOptions(optionsList);
  }, [totalLength]);

  return (
    <Container>
      <Wrapper>
        <Text marginRight>번호</Text>
        <Text>
          {page === 1 ? 1 : (page - 1) * take + 1} -{" "}
          {take * page > totalLength ? totalLength : take * page} of{" "}
          {totalLength}
        </Text>
      </Wrapper>
      <Wrapper>
        <ArrowBtn
          onClick={() => {
            onClickPage(1);
            setOptionClick(false);
          }}
        >
          <ArrowIcon src={doubleArrow} />
        </ArrowBtn>
        <ArrowBtn
          onClick={() =>
            page > 1
              ? (onClickPage(page - 1), setOptionClick(false))
              : setOptionClick(false)
          }
        >
          <ArrowIcon src={arrowIcon} />
        </ArrowBtn>
        <Text marginSide>페이지</Text>
        <SelectPage onClick={() => setOptionClick(!optionClick)}>
          <Text>{page}</Text>
          <ArrowIcon down src={downIcon} />
          {optionClick && (
            <OptionBox>
              {options.map((page, index) => (
                <Text optionText key={index} onClick={() => onClickPage(page)}>
                  {page}
                </Text>
              ))}
            </OptionBox>
          )}
        </SelectPage>
        <ArrowBtn
          onClick={() =>
            page < lastPage && lastPage > 1
              ? (onClickPage(page + 1), setOptionClick(false))
              : setOptionClick(false)
          }
        >
          <ArrowIcon right src={arrowIcon} />
        </ArrowBtn>
        <ArrowBtn
          onClick={() => {
            onClickPage(lastPage);
            setOptionClick(false);
          }}
        >
          <ArrowIcon right src={doubleArrow} />
        </ArrowBtn>
      </Wrapper>
    </Container>
  );
};

export default memo(PageNationtest);
