import React, {
  useEffect,
  useState,
  createContext,
  useCallback,
  useContext,
} from "react";
import FaqPresenter from "./FaqPresenter";
import { useQuery, useMutation } from "@apollo/client";
import { SEE_FAQ_LIST } from "../../../graphql/Management/Faq/query";
import { DELETE_FAQ } from "../../../graphql/Management/Faq/mutation";
import { faqSortChanger } from "../../../lib/managementUtils";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

export const FaqContext = createContext();

const FaqContainer = () => {
  const { userLogout } = useContext(AuthContext);

  const [addFaqModal, setAddFaqModal] = useState(false);
  const [editFaqModal, setEditFaqModal] = useState(false);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "최신순",
  });
  const [clicked, setClicked] = useState(null);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [faqList, setFaqList] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [deleteFAQ] = useMutation(DELETE_FAQ);

  const {
    data: faqData,
    refetch,
    loading,
  } = useQuery(SEE_FAQ_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      sort: faqSortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  useEffect(() => {
    if (faqData) {
      if (!faqData.seeFAQList.tokenExpired) {
        userLogout();
      }
      setFaqList(faqData.seeFAQList.faqList);
      setTotalLength(faqData.seeFAQList.totalLength);
    }
  }, [faqData]);

  const handleReset = () => {
    setInputs({
      searchTerm: "",
      sort: "최신순",
    });
  };

  const handleRadio = (index) => {
    if (clicked === index) setClicked(null);
    else setClicked(index);
  };

  const handleAddModal = () => {
    setAddFaqModal(!addFaqModal);
  };

  const handleEditModal = () => {
    if (clicked === null) {
      toast.error("수정할 질문을 선택해주세요.");
      return;
    }
    setEditFaqModal(!editFaqModal);
  };

  const handleMinus = async () => {
    if (clicked === null) {
      toast.error("삭제할 질문을 선택해주세요");
      return;
    }
    if (window.confirm("질문을 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteFAQ({
          variables: {
            faq_id: faqList[clicked].faq_id,
          },
        });
        if (data.deleteFAQ.result) {
          setClicked(null);
          toast.success("질문을 삭제하였습니다.");
          await refetch();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <FaqContext.Provider
      value={{
        inputs,
        setInputs,
        clicked,
        handleReset,
        handleRadio,
        addFaqModal,
        editFaqModal,
        handleAddModal,
        handleEditModal,
        faqList,
        totalLength,
        refetch,
        handleMinus,
        setClicked,
        page,
        onClickPage,
      }}
    >
      <FaqPresenter />
    </FaqContext.Provider>
  );
};

export default FaqContainer;
