import { gql } from "@apollo/client";

export const SEE_ONE_ON_ONE_LIST = gql`
  query seeOneOnOneList(
    $searchTerm: String
    $oneOnOneSort: oneOnOneSort
    $take: Int
    $cursor: Int
  ) {
    seeOneOnOneList(
      searchTerm: $searchTerm
      oneOnOneSort: $oneOnOneSort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      oneOneOneList {
        one_id
        one_createdAt
        one_user_name
        one_user_cellphone
        one_question
        one_answer
        one_admin_name
        one_answer_date
        one_answer_status
      }
    }
  }
`;
