import React, {
  useState,
  createContext,
  useEffect,
  useCallback,
  useContext,
} from "react";
import NoticePresenter from "./NoticePresenter";
import { useQuery, useMutation } from "@apollo/client";
import { SEE_NOTICE_LIST } from "../../../graphql/Management/Notice/query";
import { DELETE_NOTICE } from "../../../graphql/Management/Notice/mutation";
import { noticeSortChanger } from "../../../lib/managementUtils";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

export const NoticeContext = createContext();

const NoticeContainer = () => {
  const { userLogout } = useContext(AuthContext);

  const [addNoticeModal, setAddNoticeModal] = useState(false);
  const [editNoticeModal, setEditNoticeModal] = useState(false);
  const [page, setPage] = useState(1);
  const [cursor, setCursor] = useState(0);
  const [noticeList, setNoticeList] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "최신순",
  });

  const [clicked, setClicked] = useState(null);
  const [deleteNotice] = useMutation(DELETE_NOTICE);
  const {
    data: noticeData,
    refetch,
    loading,
  } = useQuery(SEE_NOTICE_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      sort: noticeSortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  useEffect(() => {
    if (noticeData) {
      if (!noticeData.seeNoticeList.tokenExpired) {
        userLogout();
      }
      setNoticeList(noticeData.seeNoticeList.noticeList);
      setTotalLength(noticeData.seeNoticeList.totalLength);
    }
  }, [noticeData]);

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      sort: "최신순",
    });
  }, []);

  const handleRadio = useCallback(
    (index) => {
      if (clicked === index) setClicked(null);
      else setClicked(index);
    },
    [clicked]
  );

  const handleAddModal = useCallback(() => {
    setAddNoticeModal(!addNoticeModal);
  }, [addNoticeModal]);

  const handleEditModal = useCallback(() => {
    if (clicked === null) {
      toast.error("수정할 공지를 선택해주세요.");
      return;
    }
    setEditNoticeModal(!editNoticeModal);
  }, [editNoticeModal, clicked]);

  const handleMinus = useCallback(async () => {
    if (clicked === null) {
      toast.error("삭제할 공지를 선택해주세요.");
      return;
    }
    if (window.confirm("공지를 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteNotice({
          variables: {
            ntc_id: noticeList[clicked].ntc_id,
          },
        });
        if (data.deleteNotice.result) {
          toast.success("공지를 삭제햐였습니다.");
          setClicked(null);
          await refetch();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, [clicked]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <NoticeContext.Provider
      value={{
        inputs,
        setInputs,
        setClicked,
        clicked,
        handleReset,
        handleRadio,
        editNoticeModal,
        addNoticeModal,
        handleEditModal,
        handleAddModal,
        noticeList,
        totalLength,
        refetch,
        handleMinus,
        onClickPage,
        page,
      }}
    >
      <NoticePresenter />
    </NoticeContext.Provider>
  );
};

export default NoticeContainer;
