import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import Logo from "../../../assets/Logo.svg";
import TabMenu from "./TabMenu";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const Container = styled.div`
  width: 100%;
  padding: 32px;
  background-color: ${colors.blackColor};
`;

const Text = styled.div`
  color: ${colors.whiteColor};
  margin: 9px 0 90px 0;
`;

const LogoImage = styled.img`
  width: 95px;
`;

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("/home");

  // 권한
  const [permission, setPermission] = useState({
    home: true,
    user: true,
    product: true,
    coupon: true,
    sales: true,
    management: true,
    system: true,
  });

  const onClickTabMenu = useCallback((permisstion, url) => {
    if (permission) navigate(url);
    else toast.error("탭 접근 권한이 없습니다.");
  }, []);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <Container>
      <LogoImage src={Logo} />
      <Text>관리자</Text>

      {/* 탭버튼 */}
      <TabMenu
        TAB
        label="홈"
        active={currentPath === "/home" || currentPath === "/"}
        onClick={() => onClickTabMenu(permission.home, "/home")}
      />
      <TabMenu
        TAB
        label="유저관리"
        active={currentPath.split("/")[1] === "user"}
        onClick={() => onClickTabMenu(permission.user, "/user/state")}
      />
      {currentPath.split("/")[1] === "user" && (
        <>
          <TabMenu
            label="회원별 현황"
            active={
              currentPath === "/user/state" ||
              currentPath === "/user/state/detail"
            }
            onClick={() => onClickTabMenu(permission.user, "/user/state")}
          />
          <TabMenu
            label="탈퇴한 회원"
            active={currentPath === "/user/unregister"}
            onClick={() => onClickTabMenu(permission.user, "/user/unregister")}
          />
          <TabMenu
            label="보낸 문자함"
            active={currentPath === "/user/sendmail"}
            onClick={() => onClickTabMenu(permission.user, "/user/sendmail")}
          />
        </>
      )}

      <TabMenu
        TAB
        label="상품"
        active={currentPath.split("/")[1] === "product"}
        onClick={() => onClickTabMenu(permission.product, "/product/list")}
      />
      {currentPath.split("/")[1] === "product" && (
        <>
          <TabMenu
            label="상품 목록"
            active={currentPath === "/product/list"}
            onClick={() => onClickTabMenu(permission.product, "/product/list")}
          />
          <TabMenu
            label="통계"
            active={currentPath === "/product/stats"}
            onClick={() => onClickTabMenu(permission.product, "/product/stats")}
          />
        </>
      )}
      <TabMenu
        TAB
        label="쿠폰"
        active={currentPath.split("/")[1] === "coupon"}
        onClick={() => onClickTabMenu(permission.coupon, "/coupon/list")}
      />
      {currentPath.split("/")[1] === "coupon" && (
        <>
          <TabMenu
            label="쿠폰 목록"
            active={currentPath === "/coupon/list"}
            onClick={() => onClickTabMenu(permission.coupon, "/coupon/list")}
          />
        </>
      )}
      <TabMenu
        TAB
        label="매출"
        active={currentPath.split("/")[1] === "sales"}
        onClick={() => onClickTabMenu(permission.sales, "/sales/stats")}
      />
      {currentPath.split("/")[1] === "sales" && (
        <>
          <TabMenu
            label="통계"
            active={currentPath === "/sales/stats"}
            onClick={() => onClickTabMenu(permission.coupon, "/sales/stats")}
          />
          <TabMenu
            label="분석"
            active={currentPath === "/sales/analysis"}
            onClick={() => onClickTabMenu(permission.coupon, "/sales/analysis")}
          />
        </>
      )}
      <TabMenu
        TAB
        label="운영"
        active={currentPath.split("/")[1] === "management"}
        onClick={() =>
          onClickTabMenu(permission.management, "/management/notice")
        }
      />
      {currentPath.split("/")[1] === "management" && (
        <>
          <TabMenu
            label="공지사항"
            active={currentPath === "/management/notice"}
            onClick={() =>
              onClickTabMenu(permission.management, "/management/notice")
            }
          />
          <TabMenu
            label="이벤트"
            active={currentPath === "/management/evnet"}
            onClick={() =>
              onClickTabMenu(permission.management, "/management/evnet")
            }
          />
          <TabMenu
            label="자주묻는질문"
            active={currentPath === "/management/faq"}
            onClick={() =>
              onClickTabMenu(permission.management, "/management/faq")
            }
          />
          <TabMenu
            label="일대일문의"
            active={currentPath === "/management/qna"}
            onClick={() =>
              onClickTabMenu(permission.management, "/management/qna")
            }
          />
          <TabMenu
            label="고객센터 정보 등록"
            active={currentPath === "/management/service"}
            onClick={() =>
              onClickTabMenu(permission.management, "/management/service")
            }
          />
          <TabMenu
            label="이용방침"
            active={currentPath === "/management/term"}
            onClick={() =>
              onClickTabMenu(permission.management, "/management/term")
            }
          />
        </>
      )}
      <TabMenu
        TAB
        label="시스템관리"
        active={currentPath.split("/")[1] === "system"}
        onClick={() => onClickTabMenu(permission.system, "/system/user")}
      />
      {currentPath.split("/")[1] === "system" && (
        <>
          <TabMenu
            label="사용자관리"
            active={currentPath === "/system/user"}
            onClick={() => onClickTabMenu(permission.system, "/system/user")}
          />
          <TabMenu
            label="QR생성 및 관리"
            active={currentPath === "/system/qr"}
            onClick={() => onClickTabMenu(permission.system, "/system/qr")}
          />
        </>
      )}
    </Container>
  );
};

export default SideBar;
