import React, { useState, useCallback, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledInput from "../../../share/StyledInput";
import { colors } from "../../../../styles/colors";
import { useMutation } from "@apollo/client";
import {
  CREATE_PRODUCT,
  EDIT_PRODUCT,
} from "../../../../graphql/Product/ProductList/mutation";
import { ProductListContext } from "../../../../routes/Product/ProductList/ProductListContainer";
import { toast } from "react-toastify";
import InputFiles from "./InputFiles";

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const Margin = styled.div`
  height: 25px;
`;

const Text = styled.div`
  color: ${colors.labelGrayFontColor};
  margin-bottom: 12px;
`;

const RowBox = styled.div`
  display: flex;

  ${({ scroll }) =>
    scroll &&
    css`
      overflow-x: scroll;
      ::-webkit-scrollbar {
        height: 5px;
      }
      ::-webkit-scrollbar-thumb {
        height: 5px;
        background-color: black;
        border-radius: 5px;
      }
    `}
`;

const Thumbnail = styled.img`
  width: 122px;
  height: 122px;
  flex-shrink: 0;
  margin-right: 10px;
  border: 1px solid ${colors.lightGrayBorderColor};
  border-radius: 10px;
  :last-child {
    margin-right: 0;
  }
`;

const AddModal = ({ EDIT }) => {
  const { handleAddModal, handleEditModal, refetch, clicked, productList } =
    useContext(ProductListContext);
  const [imageFile, setImageFile] = useState([]);

  const [thumbnailImg, setThumbnailImg] = useState([]);

  const [inputs, setInputs] = useState({
    title: "",
    link: "",
  });

  const onChangeInput = (name, value) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const [createProduct] = useMutation(CREATE_PRODUCT);
  const [editProduct] = useMutation(EDIT_PRODUCT);

  const onChangeFile = useCallback(
    (e) => {
      const files = e.target.files;
      if (files.length < 1) return;

      setImageFile(files); // 뮤테이션 보낼 데이터

      //여기부턴 썸네일
      let thumbnailImgList = [];
      for (let i = 0; i < files.length; i++) {
        thumbnailImgList.push(URL.createObjectURL(files[i]));
      }
      setThumbnailImg(thumbnailImgList);
    },
    [thumbnailImg, imageFile]
  );

  const onClickSave = useCallback(async () => {
    if (!EDIT) {
      // 새로 생성
      try {
        const { data } = await createProduct({
          variables: {
            prdName: inputs.title,
            prdLink: inputs.link,
            imgSelect: imageFile.length !== 0,
            images: imageFile,
          },
        });
        if (data.createProduct.result) {
          toast.success("상품을 생성하였습니다.");
          handleAddModal();
          await refetch();
        }
      } catch (e) {
        toast.error(e.message);
      }
    } else {
      // 수정
      try {
        let variables;
        if (imageFile[0]?.prd_img_url) {
          variables = {
            prdId: productList[clicked].prd_id,
            prdName: inputs.title,
            prdLink: inputs.link,
            imgSelect: false,
          };
        } else {
          variables = {
            prdId: productList[clicked].prd_id,
            prdName: inputs.title,
            prdLink: inputs.link,
            imgSelect: true,
            images: imageFile,
          };
        }
        const { data } = await editProduct({
          variables: variables,
        });
        if (data.editProduct.result) {
          toast.success("상품을 수정하였습니다.");
          handleEditModal();
          await refetch();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, [imageFile, inputs]);

  useEffect(() => {
    if (EDIT) {
      setInputs({
        title: productList[clicked].prd_name,
        link: productList[clicked].prd_link,
      });
      if (productList[clicked].product_image.length !== 0) {
        setImageFile(productList[clicked].product_image);
        setThumbnailImg(
          productList[clicked].product_image.map((img) => img.prd_img_url)
        );
      }
    }
  }, []);

  useEffect(() => {
    console.log(typeof imageFile, imageFile);
  }, [imageFile]);

  return (
    <StyledModal
      BOTTOMBTN
      width={720}
      height={535}
      title={EDIT ? "상품 수정하기" : "상품 생성하기"}
      onClickClose={EDIT ? handleEditModal : handleAddModal}
      onClickSave={onClickSave}
    >
      <Container>
        <StyledInput
          label="제목"
          width={312}
          maxLength={20}
          value={inputs.title}
          onChange={(e) => onChangeInput("title", e.target.value)}
        />
        <Margin />
        <StyledInput
          label="스토어 링크"
          width={639}
          value={inputs.link}
          onChange={(e) => onChangeInput("link", e.target.value)}
        />
        <Margin />
        <Text>사진 파일</Text>
        <RowBox>
          {/* 이미지 업로드 클릭하는 곳 */}
          <InputFiles onChange={onChangeFile} />

          {/* 썸네일 */}
          <RowBox scroll>
            {thumbnailImg.length > 0 &&
              thumbnailImg.map((imgUrl, index) => (
                <Thumbnail key={index} src={imgUrl} />
              ))}
          </RowBox>
        </RowBox>
      </Container>
    </StyledModal>
  );
};

export default AddModal;
