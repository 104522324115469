import React, { useState } from "react";
import styled, { css } from "styled-components";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { colors } from "../../styles/colors";

const CalendarContainer = styled.div`
  background-color: white;
  position: absolute;
  z-index: 10;
  border: 1px solid ${colors.grayBorderColor};
  border-radius: 10px;
  padding: 10px;
  width: 260px;
  height: 260px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 4px 0;
`;

const HeaderArrow = styled.div`
  cursor: pointer;
  :hover {
    color: darkgray;
  }
`;

const DateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;
const DateBox = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${colors.blackColor};
  border-radius: 50%;
  cursor: pointer;
  ${({ current }) =>
    !current
      ? css`
          color: gray;
        `
      : css`
          :hover {
            border: 1px solid lightgray;
          }
        `}
  ${({ selcted }) =>
    selcted &&
    css`
      background-color: black;
      color: white;
    `}
`;
const WeekGrid = styled.div`
  width: 100%;
  margin: 10px 0 8px 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;
const WeekBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
`;

function Calendar({ name, today, selectDate, onChange, setModal }) {
  //달력에 표시되는 날짜 년월
  const [yearMonth, setYearMonth] = useState([today.getFullYear(), today.getMonth() + 1]);

  //달 리스트 구하는 함수
  const getFullMonth = (yearMonth) => {
    const curMonthStart = new Date(yearMonth[0], yearMonth[1] - 1); //Date 객체로 생성
    const preMonthEnd = new Date(yearMonth[0], yearMonth[1] - 1, 0).getDate(); //이전달 끝나는 날
    const curStartDate = curMonthStart.getDay(); //이번달 시작 요일 -> 일요일 0 ~ 토요일 6
    const curEndDate = new Date(yearMonth[0], yearMonth[1], 0).getDate();
    let list = [[]];

    for (let i = curStartDate - 1; i >= 0; i--) {
      const preDate = new Date(yearMonth[0], yearMonth[1] - 2, preMonthEnd - i);
      list[0].push({
        year: preDate.getFullYear(),
        month: preDate.getMonth() + 1,
        date: preDate.getDate(),
        dateObj: preDate,
        state: "pre",
      });
    }

    for (let i = 1, j = 0; i <= curEndDate; i++) {
      if (list[j].length === 7) {
        j++;
        list.push([]);
      }
      list[j].push({
        year: yearMonth[0],
        month: yearMonth[1],
        date: i,

        dateObj: new Date(yearMonth[0], yearMonth[1] - 1, i),
        state: "cur",
      });
    }
    let i = 0;
    while (list[list.length - 1].length < 7) {
      i++;
      const nextDate = new Date(yearMonth[0], yearMonth[1], i);
      list[list.length - 1].push({
        year: nextDate.getFullYear(),
        month: nextDate.getMonth() + 1,
        date: nextDate.getDate(),
        dateObj: nextDate,
        state: "next",
      });
    }
    return list;
  };

  //화살표 눌렀을 때 state 값 변경
  const calendarHandel = (arrow) => {
    let year = yearMonth[0];
    let month = yearMonth[1];
    switch (arrow) {
      case "pre":
        if (month === 1) {
          year--;
          month = 12;
        } else month--;
        break;
      case "next":
        if (month === 12) {
          year++;
          month = 1;
        } else month++;
        break;
    }
    setYearMonth([year, month]);
  };

  //날짜 클릭했을 때 값 내보내기
  const selectDateHandle = (day) => {
    onChange(name, day.dateObj);
    setModal({ start: false, end: false });
  };

  return (
    <>
      <CalendarContainer>
        <Header>
          <HeaderArrow onClick={() => calendarHandel("pre")}>
            <FaCaretLeft />
          </HeaderArrow>
          {yearMonth[0]}.{yearMonth[1] < 10 ? `0${yearMonth[1]}` : yearMonth[1]}
          <HeaderArrow onClick={() => calendarHandel("next")}>
            <FaCaretRight />
          </HeaderArrow>
        </Header>
        <WeekGrid>
          {["일", "월", "화", "수", "목", "금", "토"].map((el, index) => (
            <WeekBox key={index}>{el}</WeekBox>
          ))}
        </WeekGrid>
        <DateGrid>
          {getFullMonth(yearMonth).map((week, index) =>
            week.map((day, idx) => (
              <FlexBox key={idx}>
                <DateBox
                  current={day.state === "cur"}
                  selcted={String(selectDate) === String(day.dateObj)}
                  onClick={() => day.state === "cur" && selectDateHandle(day)}
                >
                  {day.date}
                </DateBox>
              </FlexBox>
            ))
          )}
        </DateGrid>
      </CalendarContainer>
    </>
  );
}

export default Calendar;
