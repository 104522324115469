import React, { useState, useContext, useCallback } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import StyledRadioButton from "../../../share/StyledRadioButton";
import StyledSelect from "../../../share/StyledSelect";
import { NoticeContext } from "../../../../routes/Management/Notice/NoticeContainer";
import { dateFormat } from "../../../../utils/commons";
import { useMutation } from "@apollo/client";
import { EDIT_NOTICE_STATUS } from "../../../../graphql/Management/Notice/mutation";
import { toast } from "react-toastify";
import DetailModal from "./DetailModal";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
  &:hover {
    background-color: ${colors.lightGrayBorderColorr};
  }
`;

const StatusClick = styled.div``;

const ClickArea = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
`;

const RowTable = () => {
  const { noticeList, clicked, handleRadio, refetch } =
    useContext(NoticeContext);
  const [detailModal, setDetailModal] = useState(false);
  const [editNoticeStatus] = useMutation(EDIT_NOTICE_STATUS);
  const [index, setIndex] = useState(null);

  const handleDetailModal = useCallback(() => {
    setDetailModal(!detailModal);
  }, [detailModal]);

  const handleStatus = async (name, option) => {
    try {
      const { data } = await editNoticeStatus({
        variables: {
          ntc_id: noticeList[index].ntc_id,
          ntc_status: option === "노출" ? true : false,
        },
      });
      if (data.editNoticeStatus.result) {
        toast.success("상태를 변경하였습니다.");
        await refetch();
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <>
      <Container>
        {noticeList.length !== 0 &&
          noticeList.map((data, index) => {
            return (
              <RowBox key={index}>
                <StyledRadioButton
                  clicked={clicked === index}
                  onClick={() => handleRadio(index)}
                />
                <ClickArea
                  onClick={() => {
                    handleDetailModal();
                    setIndex(index);
                  }}
                >
                  <Text width={124}>
                    {dateFormat(
                      new Date(Number(data.ntc_createdAt)),
                      "yy.MM.dd"
                    )}
                  </Text>
                  <Text width={224}>{data.ntc_title}</Text>
                  <Text width={1106}>
                    {data.ntc_text.length > 65
                      ? data.ntc_text.substring(0, 65) + "..."
                      : data.ntc_text}
                  </Text>
                </ClickArea>
                <Text width={122}>
                  <StatusClick onClick={() => setIndex(index)}>
                    <StyledSelect
                      ARROW
                      NOBORDER
                      width={90}
                      padding="0"
                      value={data.ntc_status ? "노출" : "미노출"}
                      onChange={handleStatus}
                      options={["노출", "미노출"]}
                    />
                  </StatusClick>
                </Text>
              </RowBox>
            );
          })}
      </Container>
      {detailModal && (
        <DetailModal handleDetailModal={handleDetailModal} index={index} />
      )}
    </>
  );
};

export default RowTable;
