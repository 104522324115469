import React, {useState, useContext, memo, useEffect} from "react";
import styled from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledInput from "../../../share/StyledInput";
import StyledTextarea from "../../../share/StyledTextarea";
import {TermContext} from "../../../../routes/Management/Term/TermContainer";
import {useMutation} from "@apollo/client";
import {CREATE_TOS} from "../../../../graphql/Management/Term/mutation";
import {toast} from "react-toastify";
import StyledButton from "../../../share/StyledButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  height: 25px;
`;
const ButtonBox = styled.div`
  text-align: right;
  margin-top: 25px;
`;

const AddModal = () => {
    const {handleAddModal, refetch, termDetail, setTermDetail} = useContext(TermContext);

    const [createTOS] = useMutation(CREATE_TOS);

    const [inputs, setInputs] = useState({
        name: "",
        contents: "",
    });

    const onChangeInput = (name, value) => {
        setInputs({
            ...inputs,
            [name]: value,
        });
    };

    const onClickSave = async () => {
        try {
            const {data} = await createTOS({
                variables: {
                    tos_title: inputs.name,
                    tos_text: inputs.contents,
                },
            });

            if (data.createTOS.result) {
                toast.success("약관을 등록하였습니다.");
                handleAddModal();
                await refetch();
            }
        } catch (e) {
            toast.error(e.message);
        }
    };

    useEffect(() => {
        if (!!termDetail) {
            setInputs({
                name: termDetail?.tos_title,
                contents: termDetail?.tos_text
            });
        }
    }, [termDetail]);

    useEffect(() => {
      return () => setTermDetail(null);
    }, []);

    return (
        <StyledModal
            BOTTOMBTN={!termDetail}
            width={619}
            height={752}
            title={`이용방침 ${!!termDetail ? '보기' : '등록'}`}
            onClickClose={handleAddModal}
            onClickSave={onClickSave}
        >
            <Container>
                <StyledInput
                    READONLY={!!termDetail}
                    label="이용방침 명*"
                    width={535}
                    placeholder="이용방침 명을 입력해주세요"
                    maxLength={60}
                    value={inputs.name}
                    onChange={(e) => onChangeInput("name", e.target.value)}
                />
                <Margin/>
                <StyledTextarea
                    READONLY={!!termDetail}
                    label="이용방침 내용"
                    width={535}
                    height={!!termDetail ? 426 : 396}
                    placeholder="이용방침 내용을 입력해주세요"
                    maxLength={1000}
                    value={inputs.contents}
                    onChange={(e) => onChangeInput("contents", e.target.value)}
                />
                {!!termDetail && (
                    <ButtonBox>
                        <StyledButton
                            BLACK
                            label="닫기"
                            onClick={handleAddModal}
                        />
                    </ButtonBox>
                )}
            </Container>
        </StyledModal>
    );
};

export default memo(AddModal);
