import { gql } from "@apollo/client";

export const SEE_EVENT_LIST = gql`
  query seeEventList(
    $searchTerm: String
    $sort: noticeEventSort
    $take: Int
    $cursor: Int
  ) {
    seeEventList(
      searchTerm: $searchTerm
      sort: $sort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      eventList {
        evnt_id
        evnt_createdAt
        evnt_start
        evnt_end
        evnt_status
        evnt_title
        evnt_img
      }
    }
  }
`;
