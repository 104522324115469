import React from "react";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import EmptyPage from "../../../components/share/EmptyPage";

const StatsPresenter = () => {
  return (
    <>
      <GuideLine title={["상품", "통계"]} />
      <EmptyPage />
    </>
  );
};

export default StatsPresenter;
