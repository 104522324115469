import React, {
    createContext,
    useEffect,
    useState,
    useCallback,
    useContext,
} from "react";
import CouponListPresenter from "./CouponListPresenter";
import {useQuery, useMutation} from "@apollo/client";
import {SEE_COUPON_LIST} from "../../../graphql/Coupon/CouponList/query";
import {DELETE_COUPON} from "../../../graphql/Coupon/CouponList/mutation";
import {createType, sortChanger} from "../../../lib/couponUtils";
import {toast} from "react-toastify";
import {AuthContext} from "../../../context/AuthProvider";

export const CouponContext = createContext();

const CouponListContainer = () => {
    const {userLogout} = useContext(AuthContext);

    const [couponAddModal, setCouponAddModal] = useState(false);
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);
    const [inputs, setInputs] = useState({
        searchTerm: "",
        publishType: "관리자",
        sort: "최신 순",
    });
    const [clicked, setClicked] = useState(null);
    const [couponList, setCouponList] = useState([]);
    const [totalLength, setTotalLength] = useState(0);

    const {data, loading, refetch} = useQuery(SEE_COUPON_LIST, {
        variables: {
            searchTerm: inputs.searchTerm,
            couponFilter: sortChanger(inputs.sort),
            cpCreateType: createType(inputs.publishType),
            take: 16,
            cursor: cursor,
        },
    });



    const [deleteCoupon] = useMutation(DELETE_COUPON);

    useEffect(() => {
        if (data) {
            if (!data.seeCouponList.tokenExpired) {
                userLogout();
            }
            setCouponList(data.seeCouponList.couponList);
            setTotalLength(data.seeCouponList.totalLength);
        }
    }, [data]);

    const handleReset = () => {
        setInputs({
            searchTerm: "",
            sort: "전체",
        });
    };

    const handleRadio = (index) => {
        if (clicked === index) setClicked(null);
        else setClicked(index);
    };

    const handleAddModal = () => {
        setCouponAddModal(!couponAddModal);
    };

    const handleMinus = async () => {
        if (clicked === null) {
            toast.error("삭제할 쿠폰을 선택해주세요.");
            return;
        }
        if (window.confirm("쿠폰을 삭제하시겠습니까?")) {
            try {
                const {data} = await deleteCoupon({
                    variables: {
                        cp_id: couponList[clicked].cp_id,
                    },
                });
                if (data.deleteCoupon.result) {
                    toast.success("쿠폰을 삭제했습니다.");
                    setClicked(null);
                    await refetch();
                }
            } catch (e) {
                toast.error(e.message);
            }
        }
    };

    const onClickPage = useCallback((page) => {
        setPage(page);
        if (page !== 1) {
            setCursor((page - 1) * 16);
        }
        if (page === 1) {
            setCursor(0);
        }
    }, []);

    return (
        <CouponContext.Provider
            value={{
                inputs,
                setInputs,
                clicked,
                handleReset,
                handleRadio,
                couponAddModal,
                handleAddModal,
                handleMinus,
                couponList,
                totalLength,
                refetch,
                setClicked,
                onClickPage,
                page,
            }}
        >
            <CouponListPresenter/>
        </CouponContext.Provider>
    );
};

export default CouponListContainer;
