import React from "react";
import styled from "styled-components";
import { qrTypeChanger } from "../../lib/systemUtils";
import { colors } from "../../styles/colors";
import { dateFormat } from "../../utils/commons";

const Container = styled.div`
  width: ${({ width }) => width}px;
  height: 100%;
  padding: 18px;
`;

const Header = styled.div`
  color: ${colors.darkGrayFontColor};
`;

const TableContainer = styled.table`
  width: 100%;
  margin-top: 9px;
  border-top: 2px solid ${colors.lightGrayBorderColor};
`;
const TableRow = styled.tr`
  height: ${({ HEADER }) => (HEADER ? 44 : 46)}px;
  display: flex;
  align-items: center;
  justify-content: ${({ LONG }) => !LONG && "space-around"};
  padding-left: ${({ LONG }) => LONG && 50}px;
  border-bottom: 1px solid ${colors.lightGrayBorderColor};
`;

const TableHeader = styled.th`
  width: ${({ width }) => width && width}px;
  display: flex;
`;

const TableData = styled.td`
  width: ${({ width }) => (width ? `${width}px` : "15%")};
  display: flex;
  white-space: nowrap;
`;

const Table = ({ LONG, width = 648, title, header = [], rowData = [] }) => {
  const widthList = [312, 106, 148];

  return (
    <Container width={width}>
      <Header>{title}</Header>
      <TableContainer>
        <thead>
          <TableRow HEADER LONG={LONG}>
            {header.map((title, index) => (
              <TableHeader key={index} width={LONG && widthList[index]}>
                {title}
              </TableHeader>
            ))}
          </TableRow>
        </thead>
        <tbody>
          {LONG
            ? rowData.map((data, index) => (
                <TableRow key={index} LONG={LONG}>
                  <TableData
                    key={`title-${index}`}
                    width={LONG && widthList[0]}
                  >
                    {rowData[index]
                      ? qrTypeChanger(rowData[index].qr_type)
                      : "-"}
                  </TableData>
                  <TableData
                    key={`count-${index}`}
                    width={LONG && widthList[1]}
                  >
                    {rowData[index] ? rowData[index].amount + " EA" : "-"}
                  </TableData>
                  <TableData key={`date-${index}`} width={LONG && widthList[2]}>
                    {rowData[index]
                      ? dateFormat(
                          new Date(Number(rowData[index].createdAt)),
                          "yy.MM.dd"
                        )
                      : "-"}
                  </TableData>
                </TableRow>
              ))
            : rowData.map((data, index) => (
                <TableRow key={index} LONG={LONG}>
                  <TableData
                    key={`contents-${index}`}
                    width={LONG && widthList[0]}
                  >
                    {rowData[index]
                      ? rowData[index].ntc_text.length > 5
                        ? rowData[index].ntc_text.substring(0, 5) + "..."
                        : rowData[index].ntc_text
                      : "-"}
                  </TableData>
                  <TableData key={`date-${index}`} width={LONG && widthList[1]}>
                    {rowData[index]
                      ? dateFormat(
                          new Date(Number(rowData[index].ntc_createdAt)),
                          "yy.MM.dd"
                        )
                      : "-"}
                  </TableData>
                </TableRow>
              ))}
        </tbody>
      </TableContainer>
    </Container>
  );
};

export default Table;
