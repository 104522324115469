import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import { colors } from "../../../styles/colors";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import RowTable from "../../../components/feature/Management/Notice/RowTable";
import PageNation from "../../../components/layout/PageNation";
import AddModal from "../../../components/feature/Management/Notice/AddModal";
import { NoticeContext } from "./NoticeContainer";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const NoticePresenter = () => {
  const {
    inputs,
    setInputs,
    handleReset,
    editNoticeModal,
    addNoticeModal,
    handleAddModal,
    handleEditModal,
    handleMinus,
    totalLength,
    setClicked,
    onClickPage,
    page,
  } = useContext(NoticeContext);

  return (
    <>
      <GuideLine title={["운영", "공지사항"]} />
      <Container>
        <SortingForm
          SEARCH
          RESET
          NOTICE
          PLUS
          MINUS
          EDIT
          placeholder="공지명으로 검색"
          inputs={inputs}
          setInputs={setInputs}
          handlePlus={handleAddModal}
          handleEdit={handleEditModal}
          handleReset={handleReset}
          handleMinus={handleMinus}
        />
        <StyledRowTable
          RADIO
          handleRadio={() => setClicked(null)}
          label={[
            { text: "등록일", width: 124 },
            { text: "공지명", width: 224 },
            { text: "공지 내용", width: 1106 },
            { text: "상태", width: 122 },
          ]}
        />
        <RowTable />

        <PageNation
          page={page}
          totalLength={totalLength}
          onClickPage={onClickPage}
        />
      </Container>
      {editNoticeModal && <AddModal EDIT />}
      {addNoticeModal && <AddModal />}
    </>
  );
};

export default NoticePresenter;
