import { ApolloClient, InMemoryCache } from "@apollo/client";
import ServerIP from "./ServerIP";
import { createUploadLink } from "apollo-upload-client";

const uploadLink = createUploadLink({
  uri: ServerIP,
  headers: {
    "Apollo-Require-Preflight": "true",
    Authorization: !localStorage.getItem("qr_admin_token")
      ? ""
      : `Bearer ${localStorage.getItem("qr_admin_token")}`,
  },
});

export default new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
});
