import { gql } from "@apollo/client";

export const EDIT_NOTICE_STATUS = gql`
  mutation editNoticeStatus($ntc_id: Int!, $ntc_status: Boolean) {
    editNoticeStatus(ntc_id: $ntc_id, ntc_status: $ntc_status) {
      tokenExpired
      result
    }
  }
`;

export const CREATE_NOTICE = gql`
  mutation createNotice($ntc_title: String!, $ntc_text: String!) {
    createNotice(ntc_title: $ntc_title, ntc_text: $ntc_text) {
      tokenExpired
      result
    }
  }
`;

export const EDIT_NOTICE = gql`
  mutation editNotice($ntc_id: Int!, $ntc_title: String, $ntc_text: String) {
    editNotice(ntc_id: $ntc_id, ntc_title: $ntc_title, ntc_text: $ntc_text) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_NOTICE = gql`
  mutation deleteNotice($ntc_id: Int!) {
    deleteNotice(ntc_id: $ntc_id) {
      tokenExpired
      result
    }
  }
`;
