import React, { useContext, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../../styles/colors";
import { autoHyphen } from "../../../../../utils/commons";
import messageIcon from "../../../../../assets/messageIcon.svg";
import { DetailContext } from "../../../../../routes/User/State/Detail/DetailContainer";
import { gendarChanger, subsChanger } from "../../../../../lib/userUtils";
import MessageModal from "./MessageModal";

const Container = styled.div`
  width: 1148px;
  height: 109px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin: 24px auto auto 16px;
  background-color: ${colors.whiteColor};
`;

const ColumnBox = styled.div`
  width: ${({ width }) => width}px;
  height: 44px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin-right: 65px; */
  /* border: 1px solid red; */
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  color: ${({ gray }) => gray && `${colors.labelGrayFontColor}`};
`;

const IconButton = styled.img`
  width: 22px;
  margin: 0 34px 0 auto;
  cursor: pointer;
`;

const UserSummary = () => {
  const { userDetail } = useContext(DetailContext);

  const [messageModal, setMessageModal] = useState(false);

  const dateFormat = (date) => {
    return (
      date.substring(2, 4) +
      "." +
      date.substring(4, 6) +
      "." +
      date.substring(6)
    );
  };

  return (
    <>
      <Container>
        <ColumnBox width={130}>
          <Text gray>가입일</Text>
          <Text>{dateFormat(userDetail.user_createdAt)}</Text>
        </ColumnBox>
        <ColumnBox width={108}>
          <Text gray>이름</Text>
          <Text>
            {userDetail.user_name.length > 5
              ? userDetail.user_name.substring(0, 5) + "..."
              : userDetail.user_name}
          </Text>
        </ColumnBox>
        <ColumnBox width={115}>
          <Text gray>생년월일</Text>
          <Text>{userDetail.user_birthday}</Text>
        </ColumnBox>
        <ColumnBox width={88}>
          <Text gray>성별</Text>
          <Text>{gendarChanger(userDetail.user_gender)}</Text>
        </ColumnBox>
        <ColumnBox width={176}>
          <Text gray>휴대폰번호</Text>
          <Text>{autoHyphen(userDetail.user_cellphone)}</Text>
        </ColumnBox>
        <ColumnBox width={115}>
          <Text gray>보유카드</Text>
          <Text>{userDetail.user_card_count} 개</Text>
        </ColumnBox>
        <ColumnBox width={115}>
          <Text gray>공유카드</Text>
          <Text>{userDetail.user_share_count} 개</Text>
        </ColumnBox>
        <ColumnBox width={78}>
          <Text gray>구독여부</Text>
          <Text>{subsChanger(userDetail.user_subs_available)}</Text>
        </ColumnBox>
        <IconButton src={messageIcon} onClick={() => setMessageModal(true)} />
      </Container>
      {messageModal && (
        <MessageModal
          id={userDetail.user_id}
          setMessageModal={setMessageModal}
        />
      )}
    </>
  );
};

export default UserSummary;
