import React, { useRef, useState, useEffect, memo, useCallback } from "react";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import selectArrow from "../../assets/selectArrow.png";

const Container = styled.div`
  width: ${({ width }) => width}px;
  position: relative;
  cursor: pointer;
  margin: ${({ margin }) => margin};
  margin-bottom: ${({ NOBORDER }) => (NOBORDER ? 12 : 0)}px;
`;

const SelectBox = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding};
  border-radius: 8px;
  border: ${({ NOBORDER }) =>
    NOBORDER ? "" : `1px solid ${colors.lightGrayBorderColorr}`};
  font-size: 16px;
`;

const Label = styled.div`
  margin-bottom: 12px;
  color: ${colors.labelGrayFontColor};
`;

const Icon = styled.img`
  width: 14px;
  position: absolute;
  right: 15px;
  bottom: calc(23px - 4px);
`;

const OptionBox = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  padding: 10px 0;
  border-radius: 8px;
  border: 1px solid ${colors.grayBorderColor};
  position: absolute;
  background-color: ${colors.whiteColor};
  z-index: 1;
`;

const Option = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  &:hover {
    background-color: ${colors.defaultBackgroundColor};
  }
`;

const StyledSelect = ({
  margin,
  ARROW,
  NOBORDER,
  width = 160,
  padding = "0 16px",
  name = "",
  label = "",
  value,
  onChange = () => {},
  options = [],
}) => {
  const [optionView, setOptionView] = useState(false);
  const boxRef = useRef(null);
  const optRef = useRef(null);

  const onClickOutSide = useCallback(
    ({ target }) => {
      let boxRefCurrent = boxRef.current.contains(target);
      let optRefCurrent = optionView && optRef.current.contains(target);

      if (!boxRefCurrent && !optRefCurrent) setOptionView(false);
    },
    [boxRef, optRef]
  );

  useEffect(() => {
    window.addEventListener("click", onClickOutSide);
    return () => window.removeEventListener("click", onClickOutSide);
  }, [onClickOutSide]);

  return (
    <Container margin={margin} width={width} NOBORDER={NOBORDER}>
      <Label>{label}</Label>
      <SelectBox
        NOBORDER={NOBORDER}
        padding={padding}
        ref={boxRef}
        onClick={() => setOptionView(!optionView)}
      >
        {value}
      </SelectBox>
      {ARROW && <Icon src={selectArrow} />}
      {optionView && (
        <OptionBox ref={optRef}>
          {options.map((option, index) => (
            <Option key={index} onClick={() => onChange(name, option)}>
              {option}
            </Option>
          ))}
        </OptionBox>
      )}
    </Container>
  );
};

export default memo(StyledSelect);
