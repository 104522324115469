import React from "react";
import styled from "styled-components";
import { colors } from "../../../../../styles/colors";

const Container = styled.div`
  /* width: 1104px; */
  width: 100%;
  height: 454px;
  margin-bottom: 56px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1104px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 22px;
  border-bottom: 1px solid ${colors.defaultBackgroundColor};
  background-color: ${colors.whiteColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  color: ${colors.blackColor};
`;

const RowTable = ({ paymentList }) => {
  return (
    <Container>
      {paymentList.map((data, index) => (
        <RowBox key={index}>
          {/* //TODO::: 여기 데이터랑 퍼블한거랑 안 맞음 물어봐야함 */}
          <Text width={130}>{data.upay_date}</Text>
          <Text width={210}>{data.upay_product_name}</Text>
          <Text width={108}>{data.upay_product_category}</Text>
          <Text width={148}>{data.upay_price.toLocaleString()} 원</Text>
          <Text width={198}>{data.upay_cardname}</Text>
          {/* <Text width={280}>{data.데이터}</Text> */}
        </RowBox>
      ))}
    </Container>
  );
};

export default RowTable;
