import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import SideTabCircle from "../../../assets/SideTabCircle.png";
import SideTabPlus from "../../../assets/SideTabPlus.png";

const Container = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Text = styled.div`
  color: ${colors.whiteColor};
  white-space: nowrap;
  margin: ${({ margin }) => margin && "0 8px"};
`;

const Icon = styled.img`
  width: 16px;
  margin-left: auto;
`;

const TabMenu = ({ TAB, active, label, onClick = () => {} }) => {
  return (
    <Container onClick={onClick}>
      {!TAB && <Text margin>-</Text>}
      <Text>{label}</Text>
      {active && <Icon src={TAB ? SideTabPlus : SideTabCircle} />}
    </Container>
  );
};

export default TabMenu;
