import React, { useContext, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../../styles/colors";
import plusIconGray from "../../../../../assets/plusIconGray.svg";
import { DetailContext } from "../../../../../routes/User/State/Detail/DetailContainer";
import AddModal from "./AddModal";

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 1041px;
  grid-row: 1/3;
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  padding: 30px 0 0 0;
  background-color: ${colors.whiteColor};
`;

const Header = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  padding: 0 32px;
  border-bottom: 1px solid ${colors.lightGrayBorderColor};
`;

const HeaderBlackLine = styled.div`
  height: 6px;
  width: 185px;
  position: absolute;
  bottom: -3px;
  left: 18px;
  background-color: ${colors.blackColor};
`;

const LogWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: ${colors.blackColor};
    border-radius: 5px;
  }
`;

const LogBox = styled.div`
  width: 100%;
  height: 122px;
  display: flex;
  flex-direction: column;
  margin-bottom: 6px;
  flex-shrink: 0;
  &:last-child {
    margin-bottom: 50px;
  }
`;

const IconButton = styled.img`
  width: 22px;
  cursor: pointer;
  &:hover {
    filter: invert(100%);
  }
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* border: 1px solid red; */
`;
const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  margin-top: 10px;
  /* border: 1px solid blue; */
`;

const Text = styled.div`
  line-height: 20px;
  margin: ${({ margin }) => margin};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  color: ${({ gray }) => gray && `${colors.labelGrayFontColor}`};
`;

const TextArea = styled.div`
  width: 289px;
  height: 42px;
  line-height: 21px;
  /* border: 1px solid red; */
  overflow-y: hidden;
  line-break: anywhere;
  color: ${colors.labelGrayFontColor};
`;

const Counsult = () => {
  const { adviceLogList } = useContext(DetailContext);
  const [addModal, setAddModal] = useState(false);

  return (
    <>
      <Container>
        <Header>
          <Text>상담 일지 내역</Text>
          <IconButton src={plusIconGray} onClick={() => setAddModal(true)} />
          <HeaderBlackLine />
        </Header>
        <LogWrapper>
          {adviceLogList.map((data, index) => (
            <LogBox key={index}>
              <Text margin="37px  33px 0 33px">
                {data.adl_title.length > 25
                  ? data.adl_title.substring(0, 25) + "..."
                  : data.adl_title}
              </Text>
              <RowBox>
                <TextArea>{data.adl_text}</TextArea>
                <ColumnBox>
                  <Text gray>{data.adl_createdAt}</Text>
                  <Text gray>{data.adl_admin_name}</Text>
                </ColumnBox>
              </RowBox>
            </LogBox>
          ))}
        </LogWrapper>
      </Container>
      {addModal && <AddModal handleModal={() => setAddModal(false)} />}
    </>
  );
};

export default Counsult;
