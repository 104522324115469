import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledInput from "../../../share/StyledInput";
import StyledTextarea from "../../../share/StyledTextarea";
import { NoticeContext } from "../../../../routes/Management/Notice/NoticeContainer";
import { dateFormat } from "../../../../utils/commons";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Text = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const Margin = styled.div`
  height: 25px;
`;

const DetailModal = ({ handleDetailModal, index }) => {
  const { noticeList } = useContext(NoticeContext);
  return (
    <StyledModal
      width={619}
      height={742}
      title="공지 내용"
      onClickClose={handleDetailModal}
    >
      <Container>
        <Text>
          날짜 :{" "}
          {dateFormat(
            new Date(Number(noticeList[index].ntc_createdAt)),
            "yy.MM.dd"
          )}{" "}
        </Text>
        <StyledInput
          READONLY
          label="공지명*"
          width={535}
          value={noticeList[index].ntc_title}
        />
        <Margin />
        <StyledTextarea
          READONLY
          label="공지 내용"
          width={535}
          height={482}
          value={noticeList[index].ntc_text}
        />
      </Container>
    </StyledModal>
  );
};

export default DetailModal;
