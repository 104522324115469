import React, {
  useEffect,
  useState,
  createContext,
  useCallback,
  useContext,
} from "react";
import UserPresenter from "./UserPresenter";
import { SEE_ADMIN_LIST } from "../../../graphql/System/User/query";
import {
  DELETE_ADMIN,
  INIT_ADMIN_PW,
} from "../../../graphql/System/User/mutation";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

export const SystemContext = createContext();

const UserContainer = () => {
  const { userLogout } = useContext(AuthContext);

  const [userAddModal, setUserAddModal] = useState(false);
  const [inputs, setInputs] = useState({
    searchTerm: "",
  });
  const [clicked, setClicked] = useState(null);

  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);

  const [adminList, setAdminList] = useState([]);

  const [deleteAdmin] = useMutation(DELETE_ADMIN);
  const [initAdminPW] = useMutation(INIT_ADMIN_PW);

  const {
    data: systemData,
    refetch,
    loading,
  } = useQuery(SEE_ADMIN_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      take: 16,
      cursor: cursor,
    },
  });

  useEffect(() => {
    if (systemData) {
      if (!systemData.seeAdminList.tokenExpired) {
        userLogout();
      }
      setAdminList(systemData.seeAdminList.admin);
      setTotalLength(systemData.seeAdminList.totalLength);
    }
  }, [systemData]);

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
    });
  }, []);

  const handleRadio = useCallback((index) => {
    if (clicked === index) setClicked(null);
    else setClicked(index);
  }, []);

  const resetPasswd = useCallback(async (id) => {
    if (window.confirm("비밀번호를 초기화 하시겠습니까?")) {
      try {
        const { data } = await initAdminPW({
          variables: {
            admin_id: id,
          },
        });
        if (data.initAdminPW.result) {
          await refetch();
          toast.success("비밀번호를 초기화 하였습니다.");
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, []);

  const handleAddModal = useCallback(() => {
    setUserAddModal(!userAddModal);
  }, [userAddModal]);

  const handleMinus = useCallback(async () => {
    if (clicked === null) {
      toast.error("삭제할 사용자를 선택해주세요.");
      return;
    }
    if (window.confirm("사용자를 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteAdmin({
          variables: {
            admin_id: adminList[clicked].admin_id,
          },
        });

        if (data.deleteAdmin.result) {
          setClicked(null);
          toast.success("사용자를 삭제하였습니다.");
          await refetch();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, [clicked]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <SystemContext.Provider
      value={{
        inputs,
        setInputs,
        clicked,
        handleReset,
        handleRadio,
        resetPasswd,
        userAddModal,
        handleMinus,
        handleAddModal,
        adminList,
        totalLength,
        refetch,
        setClicked,
        page,
        onClickPage,
      }}
    >
      <UserPresenter />
    </SystemContext.Provider>
  );
};

export default UserContainer;
