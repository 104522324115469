import React, {
  useEffect,
  useState,
  useCallback,
  createContext,
  useContext,
} from "react";
import UserListPresenter from "./UserListPresenter";
import { useQuery, useMutation } from "@apollo/client";
import { SEE_USER_LIST } from "../../../../graphql/User/State/UserList/query";
import { CREATE_USER_EXCEL } from "../../../../graphql/User/State/UserList/mutation";
import { sortChanger, subsChanger } from "../../../../lib/userUtils";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../context/AuthProvider";

export const UserListContext = createContext();

const UserListContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [clicked, setClicked] = useState([]);
  const [sendMessageModal, setSendMessageModal] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [userList, setUserList] = useState([]);
  const [userIdList, setUserIdList] = useState([]);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    subscription: "전체",
    sort: "내림차순",
  });

  const { data, loading, refetch } = useQuery(SEE_USER_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      user_subs_available: subsChanger(inputs.subscription),
      signInDateSort: sortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  const [craeteUserExcel] = useMutation(CREATE_USER_EXCEL);

  useEffect(() => {
    if (data) {
      if (!data.seeUserList.tokenExpired) {
        userLogout();
      }
      setUserList(data.seeUserList.userList);
      setTotalLength(data.seeUserList.totalLength);
    }
  }, [data]);

  useEffect(() => {
    setUserIdList(userList.map((user) => user.user_id));
  }, [userList]);

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      subscription: "전체",
      sort: "내림차순",
    });
  }, []);

  const handleCheckbox = (clickedId) => {
    if (!clickedId) {
      //맨위 체크박스 클릭시
      if (clicked.length === 0) {
        //체크되어 있는게 하나도 없으면 다 추가 ( 다 체크 되게 )
        setClicked(userList.map((user) => user.user_id));
        return;
      }
      //그게 아니면 다 비우기
      setClicked([]);
      return;
    }
    let list = [...clicked];
    if (clicked.includes(clickedId)) {
      //체크 되어 있는거 체크시 없앰
      list = list.filter((id) => id !== clickedId);
    } else {
      //아니면 추가
      list.push(clickedId);
    }
    setClicked(list);
  };

  const handleMessageModal = () => {
    setSendMessageModal(!sendMessageModal);
  };

  const handleExcel = useCallback(async () => {
    try {
      const { data } = await craeteUserExcel({
        variables: {
          searchTerm: inputs.searchTerm,
          user_subs_available: subsChanger(inputs.subscription),
          signInDateSort: sortChanger(inputs.sort),
        },
      });

      if (data.craeteUserExcel.tokenExpired) {
        window.open(data.craeteUserExcel.excel);
        toast.success("엑셀 파일이 다운로드 되었습니다.");
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  return (
    <UserListContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        handleExcel,
        handleCheckbox,
        clicked,
        setClicked,
        sendMessageModal,
        handleMessageModal,
        userList,
        page,
        totalLength,
        onClickPage,
        userIdList,
      }}
    >
      <UserListPresenter />
    </UserListContext.Provider>
  );
};

export default UserListContainer;
