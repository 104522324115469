import React, { useCallback, useEffect, useState, useContext } from "react";
import LoginPresenter from "./LoginPresenter";
import { useNavigate } from "react-router-dom";
// import { userLogin } from "../../context/AuthProvider";
import { useMutation } from "@apollo/client";
import { ADMIN_LOGIN, EDIT_ADMIN_PW } from "../../graphql/Login/mutation";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthProvider";

const LoginContainer = ({ setIsLoggedIn }) => {
  const { userLogin } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    id: "",
    pw: "",
    pwCheck: "",
  });

  const [editPw, setEditPw] = useState(false);
  const [pwCheck, setPwCheck] = useState(true);

  const [adminLogin] = useMutation(ADMIN_LOGIN);
  const [editAdminPW] = useMutation(EDIT_ADMIN_PW);

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      setInputs({
        ...inputs,
        [name]: value,
      });
    },

    [inputs]
  );

  const LoginHaldle = useCallback(async () => {
    setIsLoading(true);
    if (inputs.id === "" || inputs.pw === "") {
      toast.error("아이디와 비밀번호를 모두 입력해주세요.");
      return;
    }
    try {
      console.log(inputs);
      const { data } = await adminLogin({
        variables: {
          id: inputs.id,
          pw: inputs.pw,
        },
      });
      console.log(data);
      if (data?.adminLogin?.token) {
        userLogin(data.adminLogin.token);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
    // setIsLoggedIn(true);
  }, [inputs]);

  const handleEditPw = async () => {
    if (inputs.pw !== inputs.pwCheck) {
      toast.error("비밀번호를 확인해주세요.");
      return;
    }
    try {
      const { data } = await editAdminPW({
        variables: {
          admin_accountId: inputs.id,
          pw: inputs.pw,
        },
      });
      console.log(data);
      if (data.editAdminPW.result) {
        toast.success("비밀번호 변경을 완료하였습니다.");
        setEditPw(false);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    if (inputs.pw === inputs.pwCheck) setPwCheck(true);
    else setPwCheck(false);
  }, [inputs.pwCheck]);

  useEffect(() => {
    setInputs({
      id: "",
      pw: "",
      pwCheck: "",
    });
  }, [editPw]);

  return (
    <LoginPresenter
      inputs={inputs}
      onChange={onChange}
      LoginHaldle={LoginHaldle}
      isLoading={isLoading}
      setEditPw={setEditPw}
      editPw={editPw}
      pwCheck={pwCheck}
      handleEditPw={handleEditPw}
    />
  );
};

export default LoginContainer;
