import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/SideBar/GuideLine";
import { colors } from "../../../styles/colors";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import RowTable from "../../../components/feature/Coupon/CouponList/RowTable";
import PageNation from "../../../components/layout/PageNation";
import AddModal from "../../../components/feature/Coupon/CouponList/AddModal";
import { CouponContext } from "./CouponListContainer";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 1692px;
  min-height: 1041px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px 24px 16px;
  background-color: ${colors.whiteColor};
`;

const CouponListPresenter = () => {
  const {
    inputs,
    setInputs,
    handleAddModal,
    handleReset,
    totalLength,
    couponAddModal,
    handleMinus,
    setClicked,
    onClickPage,
    page,
  } = useContext(CouponContext);

  return (
    <>
      <GuideLine title={["쿠폰", "쿠폰목록"]} />
      <Container>
        <SortingForm
          SEARCH
          RESET
          COUPON
          COUPONTYPE
          PLUS
          MINUS
          placeholder="제목, 시리얼번호, 유저명 검색"
          inputs={inputs}
          setInputs={setInputs}
          handlePlus={handleAddModal}
          handleMinus={handleMinus}
          handleReset={handleReset}
        />
        {inputs.publishType === "관리자" ? (
          <>
            <StyledRowTable
              RADIO
              handleRadio={() => setClicked(null)}
              label={[
                { text: "발행일", width: 130 },
                { text: "제목", width: 250 },
                { text: "시리얼번호", width: 266 },
                { text: "기간", width: 140 },
                { text: "사용여/부", width: 140 },
                { text: "등록된회원정보", width: 334 },
                { text: "생성 유형", width: 203 },
                { text: "발행자 정보", width: 168 },
              ]}
            />
            <RowTable ADMIN />
          </>
        ) : (
          <>
            <StyledRowTable
              RADIO
              handleRadio={() => setClicked(null)}
              label={[
                { text: "발행일", width: 130 },
                { text: "제목", width: 250 },
                { text: "시리얼번호", width: 266 },
                { text: "기간", width: 140 },
                { text: "사용여/부", width: 239 },
                { text: "구매자 휴대폰번호", width: 380 },
              ]}
            />
            <RowTable />
          </>
        )}

        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </Container>
      {couponAddModal && <AddModal />}
    </>
  );
};

export default CouponListPresenter;
