import { gql } from "@apollo/client";

export const SEE_COUPON_LIST = gql`
  query SeeCouponList(
    $take: Int!
    $cursor: Int!
    $searchTerm: String
    $cpCreateType: CPcreateType
    $couponFilter: coupon_filter
  ) {
    seeCouponList(
      take: $take
      cursor: $cursor
      searchTerm: $searchTerm
      cp_createType: $cpCreateType
      coupon_filter: $couponFilter
    ) {
      tokenExpired
      totalLength
      couponList {
        cp_id
        cp_publicDate
        cp_createType
        cp_name
        cp_serial
        cp_period
        cp_used
        cp_user_name
        cp_user_cellphone
        cp_admin_name
      }
    }
  }
`;
