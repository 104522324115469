import { gql } from "@apollo/client";

export const CREATE_ONE_ON_ONE_ANSWER = gql`
  mutation createOneOnOneAnswer($one_id: Int, $one_answer: String) {
    createOneOnOneAnswer(one_id: $one_id, one_answer: $one_answer) {
      tokenExpired
      result
    }
  }
`;
