import React, { useState, useCallback, useContext, memo } from "react";
import styled from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledNumberInput from "../../../share/StyledNumberInput";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { CREATE_QR_CODE } from "../../../../graphql/System/Qr/mutation";
import StyledSelect from "../../../share/StyledSelect";
import { paymentTypeChanger, qrTypeChanger } from "../../../../lib/systemUtils";

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const Margin = styled.div`
  width: 18px;
  height: 25px;
`;

const RowBox = styled.div`
  display: flex;
  /* justify-content: space-between; */
`;

const AddModal = ({ handleAddModal, refetch }) => {
  const [createQRCode] = useMutation(CREATE_QR_CODE);

  const [inputs, setInputs] = useState({
    title: "",
    type: "차량용",
    paymentType: "프리미엄",
  });

  const [count, setCount] = useState(0);

  const onChangeInput = (name, value) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onClickSave = async () => {
    if (count === 0) {
      toast.error("발행수를 입력해주세요.");
      return;
    }
    try {
      const { data } = await createQRCode({
        variables: {
          qrPaymentType: paymentTypeChanger(inputs.paymentType),
          qrCreateNumber: Number(count),
          qrType: qrTypeChanger(inputs.type),
        },
      });
      if (data.createQRCode.result) {
        handleAddModal();
        await refetch();
        toast.success("QR이 생성되었습니다.");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <StyledModal
      BOTTOMBTN
      width={489}
      height={401}
      title="QR 생성하기"
      onClickClose={handleAddModal}
      onClickSave={onClickSave}
    >
      <Container>
        <RowBox>
          <StyledSelect
            ARROW
            label="QR코드 분류"
            name="type"
            value={inputs.type}
            onChange={onChangeInput}
            options={["차량용", "사무실용"]}
          />
          <StyledSelect
            ARROW
            margin="0 0 0 84px"
            label="서비스 유형"
            name="paymentType"
            value={inputs.paymentType}
            onChange={onChangeInput}
            options={["프리미엄", "베이직"]}
          />
        </RowBox>
        <Margin />
        <StyledNumberInput
          label="발행수"
          width={118}
          value={count}
          onChange={(e) => setCount(e.target.value)}
          setState={setCount}
        />
      </Container>
    </StyledModal>
  );
};

export default memo(AddModal);
