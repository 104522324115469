import React, { useState, useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledInput from "../../../share/StyledInput";
import StyledTextarea from "../../../share/StyledTextarea";
import { useMutation } from "@apollo/client";
import {
  CREATE_FAQ,
  EDIT_FAQ,
} from "../../../../graphql/Management/Faq/mutation";
import { toast } from "react-toastify";
import { FaqContext } from "../../../../routes/Management/Faq/FaqContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  height: 25px;
`;

const AddModal = ({ EDIT }) => {
  const { faqList, clicked, handleAddModal, handleEditModal, refetch } =
    useContext(FaqContext);

  const [inputs, setInputs] = useState({
    name: "",
    contents: "",
  });

  const [createFAQ] = useMutation(CREATE_FAQ);
  const [editFAQ] = useMutation(EDIT_FAQ);

  const onChangeInput = (name, value) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onClickSave = async () => {
    if (!EDIT) {
      //질문 생성이면
      try {
        const { data } = await createFAQ({
          variables: {
            faq_question: inputs.name,
            faq_answer: inputs.contents,
          },
        });
        if (data.createFAQ.result) {
          await refetch();
          toast.success("질문을 생성했습니다.");
          handleAddModal();
        }
      } catch (e) {
        toast.error(e.message);
      }
    } else {
      // 질문 수정이면
      try {
        const { data } = await editFAQ({
          variables: {
            faq_id: faqList[clicked].faq_id,
            faq_question: inputs.name,
            faq_answer: inputs.contents,
          },
        });
        if (data.editFAQ.result) {
          await refetch();
          toast.success("질문을 수정했습니다.");
          handleEditModal();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  };

  useEffect(() => {
    if (EDIT) {
      setInputs({
        name: faqList[clicked].faq_question,
        contents: faqList[clicked].faq_answer,
      });
    }
  }, []);

  return (
    <StyledModal
      BOTTOMBTN
      width={619}
      height={752}
      title={EDIT ? "질문 수정" : "질문 등록"}
      onClickClose={EDIT ? handleEditModal : handleAddModal}
      onClickSave={onClickSave}
    >
      <Container>
        <StyledInput
          label="질문명*"
          width={535}
          placeholder="질문 제목을 입력해주세요"
          maxLength={30}
          value={inputs.name}
          onChange={(e) => onChangeInput("name", e.target.value)}
        />
        <Margin />
        <StyledTextarea
          label="질문 내용"
          width={535}
          height={396}
          placeholder="표기 내용을 입력해주세요"
          maxLength={200}
          value={inputs.contents}
          onChange={(e) => onChangeInput("contents", e.target.value)}
        />
      </Container>
    </StyledModal>
  );
};

export default AddModal;
