import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import StyledModal from "../../../share/StyledModal";
import StyledInput from "../../../share/StyledInput";
import { colors } from "../../../../styles/colors";
import StyledNumberInput from "../../../share/StyledNumberInput";
import { CouponContext } from "../../../../routes/Coupon/CouponList/CouponListContainer";
import { useMutation } from "@apollo/client";
import { CREATE_COUPON } from "../../../../graphql/Coupon/CouponList/mutation";
import { toast } from "react-toastify";

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const RowBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Margin = styled.div`
  width: 18px;
  height: 25px;
`;

const AddModal = () => {
  const { handleAddModal, refetch } = useContext(CouponContext);

  const [inputs, setInputs] = useState({
    title: "",
  });

  const [month, setMonth] = useState(0);
  const [count, setCount] = useState(0);
  const [createCoupon] = useMutation(CREATE_COUPON);

  const onChangeInput = (name, value) => {
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onClickSave = async () => {
    if (inputs.title === "") {
      toast.error("제목을 입력해주세요.");
      return;
    }
    if (month === 0) {
      toast.error("기간을 입력해주세요.");
      return;
    }
    if (count === 0) {
      toast.error("발행수를 입력해주세요.");
      return;
    }
    try {
      const { data } = await createCoupon({
        variables: {
          cp_name: inputs.title,
          cp_period: Number(month),
          cp_number: Number(count),
        },
      });
      if (data.createCoupon.result) {
        handleAddModal();
        await refetch();
        toast.success("쿠폰이 생성되었습니다.");
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <StyledModal
      BOTTOMBTN
      width={570}
      height={401}
      title="쿠폰 생성하기"
      onClickClose={handleAddModal}
      onClickSave={onClickSave}
    >
      <Container>
        <RowBox>
          <StyledInput
            label="제목"
            width={312}
            maxLength={20}
            value={inputs.title}
            onChange={(e) => onChangeInput("title", e.target.value)}
          />
          <Margin />
          <StyledNumberInput
            MONTH
            label="기간"
            width={118}
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            setState={setMonth}
          />
        </RowBox>
        <Margin />
        <StyledNumberInput
          label="발행수"
          width={118}
          value={count}
          onChange={(e) => setCount(e.target.value)}
          setState={setCount}
        />
      </Container>
    </StyledModal>
  );
};

export default AddModal;
