import React, { memo } from "react";
import styled from "styled-components";
import GuideLine from "../../../../components/layout/SideBar/GuideLine";
import UserSummary from "../../../../components/feature/User/State/Detail/UserSummary";
import Counsult from "../../../../components/feature/User/State/Detail/Counsult";
import UserInfoSection from "../../../../components/feature/User/State/Detail/UserInfoSection";
import { colors } from "../../../../styles/colors";
import PayInfoSection from "../../../../components/feature/User/State/Detail/PayInfoSection";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1180px 512px;
  grid-template-rows: 162px 879px;
`;

const TabContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TabWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 2px solid ${colors.lightGrayBorderColor};
`;

const TabButton = styled.div`
  width: 147px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;
  background-color: ${({ current }) => current && ` ${colors.blackColor}`};
  color: ${({ current }) =>
    current ? `${colors.whiteColor}` : `${colors.blackColor}`};
  cursor: pointer;
  :nth-child(1) {
    margin-left: 16px;
  }
`;

const DetailPresenter = ({ tab, setTab }) => {
  return (
    <>
      <GuideLine title={["유지관리", "회원별 현황", "개인 정보"]} />
      <Container>
        <UserSummary />
        <Counsult />

        <TabContainer>
          <TabWrapper>
            <TabButton
              current={tab === "userInfo"}
              onClick={() => setTab("userInfo")}
            >
              개인정보
            </TabButton>
            <TabButton
              current={tab === "payInfo"}
              onClick={() => setTab("payInfo")}
            >
              결제정보
            </TabButton>
          </TabWrapper>
          {tab === "userInfo" ? <UserInfoSection /> : <PayInfoSection />}
        </TabContainer>
      </Container>
    </>
  );
};

export default memo(DetailPresenter);
