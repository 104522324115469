import React, { memo, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../styles/colors";
import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
} from "recharts";

const Wrapper = styled.div`
  width: 650px;
  height: 100%;
  padding: 18px 18px 0 18px;
  position: relative;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 9px;
`;
const Text = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 14)}px;
  color: ${({ fontColor }) =>
    fontColor ? fontColor : colors.lightGrayFontColor};
`;
const CharBox = styled.div`
  width: 100%;
  padding: 10px;
  margin-bottom: 43px;
  border-top: 2px solid ${colors.lightGrayBorderColor};
  border-bottom: 2px solid ${colors.lightGrayBorderColor};
`;
const CustomizeChart = styled(Chart)`
  .recharts-cartesian-axis-line,
  .recharts-cartesian-axis-tick-line {
    display: none;
  }
`;

const BarChart = ({ title, data, SIGNUP, PAYMENT, CLICK }) => {
  const [barData, setBarData] = useState([]);
  useEffect(() => {
    if (SIGNUP && data.length !== 0) {
      setBarData(
        data.map((el) => ({ month: el.month, uv: el.signInUserCount }))
      );
    }
    if (PAYMENT && data.length !== 0) {
      setBarData(data.map((el) => ({ month: el.month, uv: el.payment })));
    }
    if (CLICK && data.length !== 0) {
      setBarData(data.map((el) => ({ month: el.month, uv: el.clickCount })));
    }
  }, [data]);

  return (
    <Wrapper>
      <Header>
        <Text fontSize={16} fontColor={colors.darkGrayFontColor}>
          {title}
        </Text>
        <Text>최근 6개월</Text>
      </Header>
      <CharBox>
        <CustomizeChart
          width={612}
          height={300}
          data={barData}
          margin={{
            top: 16,
            right: 30,
            left: 30,
            bottom: 10,
          }}
        >
          <CartesianGrid
            vertical={false}
            strokeDasharray="2"
            stroke={colors.lightGrayBorderColor}
          />
          <XAxis dataKey="month" />
          <YAxis width={10} tickCount={7} tick={{ fontSize: 10 }} />
          <Bar dataKey="uv" fill={colors.blackColor} barSize={8} radius={10} />
          {/* <Bar
            dataKey="pv"
            fill={colors.grayBarColor}
            barSize={8}
            radius={10}
          /> */}
        </CustomizeChart>
      </CharBox>
    </Wrapper>
  );
};

export default memo(BarChart);
