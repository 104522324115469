import { gql } from "@apollo/client";

export const SEE_ADMIN_LIST = gql`
  query seeAdminList($searchTerm: String, $take: Int, $cursor: Int) {
    seeAdminList(searchTerm: $searchTerm, take: $take, cursor: $cursor) {
      tokenExpired
      totalLength
      admin {
        admin_id
        admin_createdAt
        admin_updatedAt
        admin_master
        admin_team
        admin_name
        admin_rank
        admin_pw_init
        admin_accountId
        permission {
          per_home
          per_user
          per_product
          per_coupon
          per_salesInfo
          per_management
          per_system
        }
      }
    }
  }
`;
